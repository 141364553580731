<div class="assignment" *ngIf="assignment" [@fade]>
  <div class="assignment__header">
    <h4 class="assignment__header__title">Dettagli</h4>
    <div class="assignment__header__actions">
      <button mat-button
              class="assignment__header__button sebastian-button sebastian-button--primary"
              (click)="onEdit()">
        Modifica
      </button>
      <button mat-button
              class="assignment__header__button sebastian-button sebastian-button--warning"
              (click)="onDelete()">
        Elimina
      </button>
    </div>
  </div>
  <div class="assignment__card assignment__card--white">
    <div class="assignment__card__row">
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.practice_number.name}}</div>
        <div class="assignment__card__row__entry__value">{{assignment.practiceNumber}}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.insured.name}}</div>
        <div class="assignment__card__row__entry__value">{{assignment.insured}}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.location.name}}</div>
        <div class="assignment__card__row__entry__value">{{assignment.location}}</div>
      </div>
    </div>
    <div class="assignment__card__row">
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.accident_number.name}}</div>
        <div class="assignment__card__row__entry__value">{{ assignment.accidentNumber }}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.policy_number.name}}</div>
        <div class="assignment__card__row__entry__value">{{ assignment.policyNumber }}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.principal.name}}</div>
        <div class="assignment__card__row__entry__value">{{ assignment.principal }}</div>
      </div>
    </div>
    <div class="assignment__card__row">
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.agency.name}}</div>
        <div class="assignment__card__row__entry__value">{{ assignment.agency }}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.agency_code.name}}</div>
        <div class="assignment__card__row__entry__value">{{ assignment.agencyCode }}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">&#8203;{{assignmentFields?.surveyor.name}}</div>
        <div class="assignment__card__row__entry__value">{{assignment.surveyor}}</div>
      </div>
    </div>
  </div>
  <div class="assignment__card">
    <div class="assignment__card__row">
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">Tipo di stabilimento</div>
        <div class="assignment__card__row__entry__value">{{ assignment.factoryType }}</div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">Compilatore</div>
        <div class="assignment__card__row__entry__value">
          <div>{{assignment.compiler ? assignment.compiler.name : '-'}}</div>
          <button mat-icon-button
                  class="assignment__card__row__entry__value__edit-button"
                  (click)="onUserEdit('compiler')"
                  *ngIf="((assignment.status === 'inspection' && netStatus.isOnline()) || (assignment.status !== 'inspection' && assignment.status !== 'completed')) && (userService.user?.role === 'manager' || userService.user?.role === 'clerk' || (userService.user?.role === 'compiler' && assignment.compiler.id === userService.user?.id))">
            <mat-icon svgIcon="edit"></mat-icon>
          </button>
        </div>
      </div>
      <div class="assignment__card__row__entry" *ngIf="assignment.status !== 'inspection'">
        <div class="assignment__card__row__entry__label">Revisore</div>
        <div class="assignment__card__row__entry__value">
          <div>{{assignment.reviser ? assignment.reviser.name : '-'}}</div>
          <button mat-icon-button
                  class="assignment__card__row__entry__value__edit-button"
                  (click)="onUserEdit('reviser')"
                  *ngIf="assignment.status !== 'completed'">
            <mat-icon svgIcon="edit"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="assignment__card" *ngIf="assignment.status !== 'inspection'">
    <div class="assignment__card__row">
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">Relazione Word</div>
        <div class="assignment__card__row__entry__value">
          <div>
            <button mat-button
                    class="assignment__card__row__entry__value__download-button icon-button"
                    *ngIf="!downloadingReport"
                    (click)="onReportDownload()">
              <mat-icon svgIcon="save_alt"></mat-icon> Genera relazione
            </button>
            <div class="spinner" *ngIf="downloadingReport"></div>
          </div>
        </div>
      </div>
      <div class="assignment__card__row__entry">
        <div class="assignment__card__row__entry__label">Word fotogrammi</div>
        <div class="assignment__card__row__entry__value">
          <div>
            <button mat-button
                    class="assignment__card__row__entry__value__download-button icon-button"
                    *ngIf="!downloadingPicturesReport"
                    (click)="onPictureReportDownload()">
              <mat-icon svgIcon="save_alt"></mat-icon> Genera doc. fotogrammi
            </button>
            <div class="spinner" *ngIf="downloadingPicturesReport"></div>
          </div>
        </div>
      </div>
      <div class="assignment__card__row__entry">
        <ng-container *ngIf="assignment.status === 'under_revision'">
          <div class="assignment__card__row__entry__label">Chiusura incarico</div>
          <div class="assignment__card__row__entry__value">
            <div>
              <button mat-button
                      class="assignment__card__row__entry__value__download-button icon-button"
                      *ngIf="!uploadingPdf"
                      (click)="file.click()">
                <mat-icon svgIcon="cloud_upload"></mat-icon> Carica PDF
              </button>
              <input
                #file
                type="file"
                name="file"
                class="inputfile"
                accept="application/pdf"
                (change)="onReportUpload($event.target.files[0])">
              <div class="spinner" *ngIf="uploadingPdf"></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="assignment.status === 'completed'">
          <div class="assignment__card__row__entry__label">Relazione finale</div>
          <div class="assignment__card__row__entry__value">
            <div>
              <button mat-button
                      class="assignment__card__row__entry__value__download-button icon-button"
                      *ngIf="!downloadingPdf"
                      (click)="onPdfDownload()">
                <mat-icon svgIcon="save_alt"></mat-icon> Scarica PDF
              </button>
              <div class="spinner" *ngIf="downloadingPdf"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
