import { Component, OnDestroy, OnInit }            from '@angular/core';
import { DialogService }                           from '../../core/dialog/dialog.service';
import { SideMenuService }                         from '../../core/side-bar/side-menu/side-menu.service';
import { AssignmentsService }                      from '../../core/models/assignment/assignments.service';
import { ActivatedRoute }                          from '@angular/router';
import { switchMap, tap }                          from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Picture }                                 from '../../core/models/picture/picture';
import { DomSanitizer }                            from '@angular/platform-browser';
import { PicturesService }                         from '../../core/models/picture/pictures.service';
import { Assignment }                              from '../../core/models/assignment/assignment';
import { IdbWrapperService }            from '../../core/idb-wrapper/idb-wrapper.service';
import { Question }                     from '../../core/models/question/question';
import { collapse }                     from '../../shared/animations/collapse.animation';
import { fade }                         from '../../shared/animations/fade.animation';
import { zoomLeave }                    from '../../shared/animations/zoom-leave.animation';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserService }                  from '../../core/user/user.service';

@Component({
  selector: 'pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss'],
  animations: [
    collapse,
    fade,
    zoomLeave
  ]
})
export class PicturesComponent implements OnInit, OnDestroy {

  fullscreenImage: { url, caption: string };
  pictures: Picture[];
  loading: boolean;
  animate = false;

  private activatedRouteSubscription: Subscription;

  constructor(private dialog: DialogService,
              private activatedRoute: ActivatedRoute,
              private assignmentsService: AssignmentsService,
              private sideMenuService: SideMenuService,
              private picturesService: PicturesService,
              private sanitizer: DomSanitizer,
              private idbWrapper: IdbWrapperService,
              public userService: UserService) {
  }

  async onPictureEdit(picture: Picture) {
    const changes = await this.dialog.pictureEditDialog({picture: picture}).toPromise();

    if (changes) {
      picture.updating = true;
      this.picturesService.updatePicture({...picture, caption: changes.caption} as Picture).subscribe(() => {
        picture.caption = changes.caption;
        picture.updating = false;
      });
    }
  }

  async onPictureDelete(picture: Picture) {
    const confirm = await this.dialog.confirmDialog(`Confermi l\'eliminazione del fotogramma?`).toPromise();
    if (confirm) {
      this.picturesService.deletePicture(picture.id).subscribe(() => {
        this.pictures.splice(this.pictures.findIndex((pic: Picture) => picture.id === pic.id), 1);
      });
    }
  }

  onPictureOpen(picture: Picture) {
    this.fullscreenImage = {
      url: null,
      caption: picture.caption
    };
    this.picturesService.getPicture(picture.id).subscribe((blob: Blob) => {
      // The modal might've been closed meanwhile
      if (this.fullscreenImage) {
        const url = URL.createObjectURL(blob);
        this.fullscreenImage.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
  }

  onPictureClose() {
    if (this.fullscreenImage) {
      URL.revokeObjectURL(this.fullscreenImage.url);
    }
    this.fullscreenImage = null;
  }

  onPictureReorder(event: CdkDragDrop<any>) {
    if (event.previousIndex === event.currentIndex) return;
    const min = Math.min(event.previousIndex, event.currentIndex),
      max = Math.max(event.previousIndex, event.currentIndex);
    moveItemInArray(this.pictures, event.previousIndex, event.currentIndex);
    const obs: Observable<any>[] = [];
    for (let i = min; i <= max; i++) {
      obs.push(this.picturesService.updatePicture({...this.pictures[i], sort: i + 1} as Picture));
    }
    combineLatest(obs).subscribe();
  }

  ngOnInit() {
    let id;
    this.loading = true;
    this.activatedRouteSubscription = this.activatedRoute.params.pipe(
      switchMap(() => this.activatedRoute.params),
      tap(res => id = +res.id),
      switchMap(() => combineLatest(
        this.assignmentsService.getAssignment(true, id),
        this.picturesService.getPictures(id),
        this.idbWrapper.all('questions')
      ))
    ).subscribe(([assignment, pictures, questions]: [Assignment, Picture[], Question[]]) => {
      this.sideMenuService.sideMenuState = {state: 'attachments', assignment, currentSubRoute: 'pictures'};
      pictures.forEach((picture: Picture) => {
        const question = questions.find((q) => q.id === picture.questionId);
        if (question) {
          picture.questionText = (
            question.questionTexts.find(text => text.factoryTypeId === assignment.factoryTypeId) ||
            question.questionTexts.find(text => text.factoryTypeId === null)
          ).text;
        }
      });
      this.pictures = pictures;
      this.loading = false;
      setTimeout(() => this.animate = true);
    });
  }

  ngOnDestroy() {
    if (this.activatedRouteSubscription) this.activatedRouteSubscription.unsubscribe();
  }

}
