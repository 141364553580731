import { NgModule }           from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { SharedModule }       from '../shared/shared.module';
import { MatButtonModule }    from '@angular/material';

@NgModule({
  imports: [
    SharedModule,
    MatButtonModule
  ],
  declarations: [DashboardComponent],
  exports: [DashboardComponent]
})
export class DashboardModule { }
