<div class="attachments">
  <div class="attachments__header">
    <h4 class="attachments__header__title">Altri documenti</h4>
  </div>
  <div class="attachments__loading" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <div class="attachments__table" *ngIf="!loading && attachments?.length > 0">
    <div class="attachments__table__header">
      <div>Domanda</div>
      <div>Nome file</div>
    </div>
    <div class="attachments__table__body">
      <div class="attachments__table__body__row" *ngFor="let attachment of attachments" [@zoomLeave]>
        <div>{{attachment.questionText}}</div>
        <div class="attachments__table__body__row__document">
          <!-- Attachment download and removal -->
          <attachment-chip *ngIf="!attachment.busy"
                           [attachment]="attachment"
                           [canDelete]="assignment.status !== 'completed' && userService.user.role !== 'clerk'"
                           (download)="onAttachmentDownload(attachment)"
                           (delete)="onAttachmentDelete(attachment)">
          </attachment-chip>
          <!-- Spinner -->
          <div class="spinner" *ngIf="attachment.busy"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="attachments__table" *ngIf="!loading && attachments?.length === 0">
    Nessun documento
  </div>
</div>
