import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Target }               from './target';

@Injectable({
  providedIn: 'root'
})
export class TargetsService {

  constructor(private http: ApiHttpClientService) { }

  getTargets(): Observable<Target[]> {
    return this.http.get('targets').pipe(
      map((res: any) => res.targets.map(target => new Target(target)))
    );
  }
}
