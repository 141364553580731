import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../core/api-http-client/api-http-client.service';

@Injectable()
export class UserAutocompleteFormFieldService {

  constructor(private httpService: ApiHttpClientService) { }

  getUsers() {
    return this.httpService.get('users');
  }
}
