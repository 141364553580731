/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../question-card/question-card.component.ngfactory";
import * as i8 from "../question-card/question-card.component";
import * as i9 from "../../../core/dialog/dialog.service";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i12 from "@angular/material/tabs";
import * as i13 from "./group-question.component";
var styles_GroupQuestionComponent = [i0.styles];
var RenderType_GroupQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupQuestionComponent, data: { "animation": [{ type: 7, name: "collapse", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0", overflow: "hidden", height: "0", margin: "0", padding: "0" }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "*", padding: "*", margin: "*", "opacity": 1 }, offset: null }, timings: "150ms ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { height: "*", padding: "*", margin: "*", "opacity": 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "0", overflow: "hidden", height: "0", margin: "0", padding: "0" }, offset: null }, timings: "150ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_GroupQuestionComponent as RenderType_GroupQuestionComponent };
function View_GroupQuestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "sebastian-button sebastian-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addParentAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Aggiungi gruppo\n"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GroupQuestionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "sebastian-button sebastian-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteParentAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Rimuovi gruppo\n"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GroupQuestionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[24, "@collapse", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "question-card", [], null, [[null, "answerChange"], [null, "pictureAdd"], [null, "pictureOpen"], [null, "pictureDelete"], [null, "pictureEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("answerChange" === en)) {
        var pd_0 = (_co.onAnswerChange($event, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("pictureAdd" === en)) {
        var pd_1 = (_co.onPictureAdd($event, _v.parent.context.$implicit, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("pictureOpen" === en)) {
        var pd_2 = (_co.onPictureOpen($event) !== false);
        ad = (pd_2 && ad);
    } if (("pictureDelete" === en)) {
        var pd_3 = (_co.onPictureDelete($event) !== false);
        ad = (pd_3 && ad);
    } if (("pictureEdit" === en)) {
        var pd_4 = (_co.onPictureEdit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i7.View_QuestionCardComponent_0, i7.RenderType_QuestionCardComponent)), i1.ɵdid(2, 4964352, null, 0, i8.QuestionCardComponent, [i9.DialogService], { question: [0, "question"], answer: [1, "answer"], assignment: [2, "assignment"], viewMode: [3, "viewMode"], shouldFocus: [4, "shouldFocus"], user: [5, "user"] }, { answerChange: "answerChange", pictureAdd: "pictureAdd", pictureOpen: "pictureOpen", pictureEdit: "pictureEdit", pictureDelete: "pictureDelete" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.getChildAnswer(_v.parent.context.$implicit, _v.parent.parent.parent.context.$implicit); var currVal_3 = _co.assignment; var currVal_4 = _co.viewMode; var currVal_5 = (_v.parent.context.index === 0); var currVal_6 = _co.user; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_GroupQuestionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_6)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!((_co.assignment.status === "inspection") && _v.context.$implicit.processingOnly) && (!_v.context.$implicit.unlockedByParentAnswerIds || (_v.context.$implicit.unlockedByParentAnswerIds && _v.context.$implicit.unlockedByParentAnswerIds.includes(_v.parent.parent.context.$implicit.id)))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GroupQuestionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "child-questions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_5)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.childQuestions; var currVal_1 = _co.questionTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_GroupQuestionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [], null, null, null, i11.View_MatTab_0, i11.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i12.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[3, 2]], 0, 1, null, View_GroupQuestionComponent_4)), i1.ɵdid(5, 16384, null, 0, i12.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Gruppo n.", (_v.context.index + 1), ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_GroupQuestionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "sebastian-button sebastian-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addParentAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Aggiungi gruppo "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GroupQuestionComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "sebastian-button sebastian-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteParentAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Rimuovi gruppo "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GroupQuestionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_8)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_9)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.viewMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.viewMode && _co.question.groupAnswers.length); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GroupQuestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = (_co.selectedTab.setValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatTabGroup_0, i11.RenderType_MatTabGroup)), i1.ɵdid(5, 3325952, null, 1, i12.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i12.MAT_TABS_CONFIG]], { selectedIndex: [0, "selectedIndex"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_3)), i1.ɵdid(8, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupQuestionComponent_7)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.viewMode; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.viewMode && _co.question.groupAnswers.length); _ck(_v, 3, 0, currVal_1); var currVal_4 = _co.selectedTab.value; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.question.groupAnswers; _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.question.groupAnswers.length > 0); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 5).dynamicHeight; var currVal_3 = (i1.ɵnov(_v, 5).headerPosition === "below"); _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_GroupQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "group-question", [], null, null, null, View_GroupQuestionComponent_0, RenderType_GroupQuestionComponent)), i1.ɵdid(1, 114688, null, 0, i13.GroupQuestionComponent, [i9.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupQuestionComponentNgFactory = i1.ɵccf("group-question", i13.GroupQuestionComponent, View_GroupQuestionComponent_Host_0, { question: "question", assignment: "assignment", viewMode: "viewMode", user: "user" }, { parentAnswerCreate: "parentAnswerCreate", parentAnswerDelete: "parentAnswerDelete", childAnswerChange: "childAnswerChange", pictureAdd: "pictureAdd", pictureOpen: "pictureOpen", pictureDelete: "pictureDelete", pictureEdit: "pictureEdit", focus: "focus" }, []);
export { GroupQuestionComponentNgFactory as GroupQuestionComponentNgFactory };
