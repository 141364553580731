import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetStatusService {
  private _isOnline;
  private _onlineStatusSource: BehaviorSubject<boolean>;
  onlineStatus: Observable<boolean>;

  constructor() {
    this._isOnline = navigator.onLine;
    this._onlineStatusSource = new BehaviorSubject(this._isOnline);
    this.onlineStatus = this._onlineStatusSource.asObservable();
  }

  isOnline() {
    return this._isOnline;
  }

  setOnline() {
    this._isOnline = true;
    this._onlineStatusSource.next(true);
  }

  setOffline() {
    this._isOnline = false;
    this._onlineStatusSource.next(false);
  }
}
