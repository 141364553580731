
<div class="custom-table">
  <mat-table matSort [dataSource]="dataSource">

    <ng-container *ngFor="let key of displayedColumns">
      <ng-container [matColumnDef]="key">
        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="key ==='download'"> {{ columnHeaders? columnHeaders[key] : '' }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div *ngIf="key !== 'updatedAt' && key !== 'status' && key !== 'download'"> {{ element[key] }} </div>
          <div *ngIf="key === 'status'" class="sebastian-status sebastian-status--{{element[key]}}" [ngClass]="{ 'sebastian-status--not-downloaded': element.status === 'inspection' && !element.offline }"> {{ element['translatedStatus'] }} </div>
          <div *ngIf="key === 'updatedAt'">{{element[key] | date: 'short'}}</div>
          <div *ngIf="key === 'download' && element.status === 'inspection'">
            <button mat-button
                    *ngIf="!element.offline && userService.user?.role !== 'clerk'"
                    [disabled]="offline"
                    (click)="onDownload(element); $event.stopPropagation()">
              <mat-icon svgIcon="save_alt"></mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>
  </mat-table>
</div>
