import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router }                     from '@angular/router';
import { AssignmentsService }                         from '../../core/models/assignment/assignments.service';
import { Assignment }                                 from '../../core/models/assignment/assignment';
import { IdbWrapperService }                          from '../../core/idb-wrapper/idb-wrapper.service';
import { FactoryType }                                from '../../core/models/factory-type/factory-type';
import { mapTo, switchMap }                           from 'rxjs/operators';
import { NetStatusService }                           from '../../core/net-status/net-status.service';
import { UserService }                                from '../../core/user/user.service';
import { ToasterService }                             from '../../core/toaster/toaster.service';
import { of }                                         from 'rxjs';

@Component({
  selector: 'new-assignment',
  templateUrl: './new-assignment.component.html',
  styleUrls: ['./new-assignment.component.scss']
})
export class NewAssignmentComponent implements OnInit, OnDestroy {

  assignment: any = {};
  factoryTypes: FactoryType[];
  assignmentFields;
  backPage;

  id: number;
  busy = false;
  private subscription;

  @HostListener('document:keyup', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.onDiscard();
    }
  }

  constructor(private router: Router,
              private assignmentsService: AssignmentsService,
              private idbWrapper: IdbWrapperService,
              private activatedRoute: ActivatedRoute,
              private netStatus: NetStatusService,
              public userService: UserService,
              private toaster: ToasterService) {
  }

  onSubmit() {
    this.busy = true;
    if (this.id) {
      this.assignmentsService.updateAssignment(this.assignment).subscribe(
        (assignment: Assignment) => this.router.navigate(['/assignments/', assignment.id])
      );
    } else {
      this.assignmentsService.createAssignment(this.assignment).pipe(
        switchMap((assignment: Assignment) => {
          return assignment.compiler.id === this.userService.user.id ?
            this.assignmentsService.downloadAssignment(assignment).pipe(mapTo(assignment)) :
            of(assignment);
        }),
      ).subscribe(
        (assignment: Assignment) => {
          this.toaster.displaySuccess('Incarico creato con successo');
          this.router.navigate(assignment.compiler.id === this.userService.user.id ?
            ['/assignments/', assignment.id] :
            ['assignments']
          );
        }
      );
    }
  }

  onDiscard() {
    if (this.id) {
      this.router.navigate([`assignments/${this.id}`]);
    } else {
      if (this.backPage) {
        this.router.navigate([`/${this.backPage}`]);
      } else {
        this.router.navigate(['/assignments']);
      }
    }
  }

  onUserChanged(event) {
    this.assignment.compiler_id = event.id;
  }

  ngOnInit() {
    this.idbWrapper.all('assignmentFields').subscribe(assignmentFields => {
      this.assignmentFields = assignmentFields.reduce((obj, item) => ({...obj, [item['mnemonicLabel']]: item}), {});
    });
    this.idbWrapper.all('factoryTypes').subscribe(
      (factoryTypes: FactoryType[]) => this.factoryTypes = [...factoryTypes]
    );

    this.id = +this.activatedRoute.snapshot.params.id;
    this.backPage = this.activatedRoute.snapshot.queryParams.back_page;
    if (this.id) {
      this.subscription = this.netStatus.onlineStatus.pipe(
        switchMap((isOnline: boolean) => this.assignmentsService.getAssignment(isOnline, this.id))
      ).subscribe(assignment => {
        this.assignment = assignment;
        this.busy = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
