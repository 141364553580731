import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { map } from 'rxjs/operators';
import { Attachment } from './attachment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var AttachmentsService = /** @class */ (function () {
    function AttachmentsService(http) {
        this.http = http;
    }
    AttachmentsService.prototype.createAttachment = function (attachmentData) {
        var formData = new FormData();
        formData.append('attachment[answer_id]', '' + attachmentData.answerId);
        if (attachmentData.requiredAttachmentId) {
            formData.append('attachment[required_attachment_id]', '' + attachmentData.requiredAttachmentId);
        }
        formData.append('attachment[file]', attachmentData.file);
        return this.http.post('attachments', formData, { headers: new HttpHeaders() }).pipe(map(function (res) { return new Attachment(res); }));
    };
    AttachmentsService.prototype.getAttachments = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/attachments").pipe(map(function (res) { return res && res.attachments.map(function (attachment) { return new Attachment(attachment); }); }));
    };
    AttachmentsService.prototype.getAttachment = function (attachmentId) {
        return this.http.get("attachments/" + attachmentId, {
            responseType: 'blob',
            observe: 'response'
        });
    };
    AttachmentsService.prototype.deleteAttachment = function (attachmentId) {
        return this.http.delete("attachments/" + attachmentId);
    };
    AttachmentsService.ngInjectableDef = i0.defineInjectable({ factory: function AttachmentsService_Factory() { return new AttachmentsService(i0.inject(i1.ApiHttpClientService)); }, token: AttachmentsService, providedIn: "root" });
    return AttachmentsService;
}());
export { AttachmentsService };
