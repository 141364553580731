var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignmentsService } from '../../core/models/assignment/assignments.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { mapTo, switchMap } from 'rxjs/operators';
import { NetStatusService } from '../../core/net-status/net-status.service';
import { UserService } from '../../core/user/user.service';
import { ToasterService } from '../../core/toaster/toaster.service';
import { of } from 'rxjs';
var NewAssignmentComponent = /** @class */ (function () {
    function NewAssignmentComponent(router, assignmentsService, idbWrapper, activatedRoute, netStatus, userService, toaster) {
        this.router = router;
        this.assignmentsService = assignmentsService;
        this.idbWrapper = idbWrapper;
        this.activatedRoute = activatedRoute;
        this.netStatus = netStatus;
        this.userService = userService;
        this.toaster = toaster;
        this.assignment = {};
        this.busy = false;
    }
    NewAssignmentComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.onDiscard();
        }
    };
    NewAssignmentComponent.prototype.onSubmit = function () {
        var _this = this;
        this.busy = true;
        if (this.id) {
            this.assignmentsService.updateAssignment(this.assignment).subscribe(function (assignment) { return _this.router.navigate(['/assignments/', assignment.id]); });
        }
        else {
            this.assignmentsService.createAssignment(this.assignment).pipe(switchMap(function (assignment) {
                return assignment.compiler.id === _this.userService.user.id ?
                    _this.assignmentsService.downloadAssignment(assignment).pipe(mapTo(assignment)) :
                    of(assignment);
            })).subscribe(function (assignment) {
                _this.toaster.displaySuccess('Incarico creato con successo');
                _this.router.navigate(assignment.compiler.id === _this.userService.user.id ?
                    ['/assignments/', assignment.id] :
                    ['assignments']);
            });
        }
    };
    NewAssignmentComponent.prototype.onDiscard = function () {
        if (this.id) {
            this.router.navigate(["assignments/" + this.id]);
        }
        else {
            if (this.backPage) {
                this.router.navigate(["/" + this.backPage]);
            }
            else {
                this.router.navigate(['/assignments']);
            }
        }
    };
    NewAssignmentComponent.prototype.onUserChanged = function (event) {
        this.assignment.compiler_id = event.id;
    };
    NewAssignmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.idbWrapper.all('assignmentFields').subscribe(function (assignmentFields) {
            _this.assignmentFields = assignmentFields.reduce(function (obj, item) {
                var _a;
                return (__assign({}, obj, (_a = {}, _a[item['mnemonicLabel']] = item, _a)));
            }, {});
        });
        this.idbWrapper.all('factoryTypes').subscribe(function (factoryTypes) { return _this.factoryTypes = factoryTypes.slice(); });
        this.id = +this.activatedRoute.snapshot.params.id;
        this.backPage = this.activatedRoute.snapshot.queryParams.back_page;
        if (this.id) {
            this.subscription = this.netStatus.onlineStatus.pipe(switchMap(function (isOnline) { return _this.assignmentsService.getAssignment(isOnline, _this.id); })).subscribe(function (assignment) {
                _this.assignment = assignment;
                _this.busy = false;
            });
        }
    };
    NewAssignmentComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return NewAssignmentComponent;
}());
export { NewAssignmentComponent };
