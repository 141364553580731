export class Attachment {
  id: number;
  answerId: number;
  questionId: number;
  assignmentId: number;
  filename: string;
  requiredAttachmentId: number;

  busy: boolean;
  questionText: string;

  constructor(obj: any) {
    this.id = obj && obj.id;
    this.answerId = obj && obj.answer_id;
    this.questionId = obj && obj.question_id;
    this.assignmentId = obj && obj.assignment_id;
    this.filename = obj && obj.filename;
    this.requiredAttachmentId = obj && obj.required_attachment_id;
  }
}
