import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { Picture }              from './picture';
import { HttpHeaders }          from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PicturesService {

  constructor(private http: ApiHttpClientService) {
  }

  createPicture(pictureData: { answerId: number, caption: string, file: any }): Observable<Picture> {
    const formData: FormData = new FormData();
    formData.append('picture[answer_id]', '' + pictureData.answerId);
    if (pictureData.caption) {
      formData.append('picture[caption]', pictureData.caption);
    }
    formData.append('picture[file]', pictureData.file);
    return this.http.post('pictures', formData, {headers: new HttpHeaders()}).pipe(
      map((res: any) => new Picture(res))
    );
  }

  getPicture(pictureId: number): Observable<Object> {
    return this.http.get(`pictures/${pictureId}`, {
      responseType: 'blob'
    });
  }

  getPictures(assignmentId: number): Observable<Picture[]> {
    return this.http.get(`assignments/${assignmentId}/pictures`).pipe(
      map((res: any) => res && res.pictures.map(picture => new Picture(picture)))
    );
  }

  deletePicture(pictureId: number): Observable<any> {
    return this.http.delete(`pictures/${pictureId}`);
  }

  updatePicture(picture: Picture): Observable<Picture> {
    const bodyString = {
      caption: picture.caption,
      sort: picture.sort
    };
    return this.http.put(`pictures/${picture.id}`, bodyString).pipe(
      map((newPicture: any) => new Picture(newPicture))
    );
  }
}
