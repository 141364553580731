import { Component, Inject, OnInit }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fade }                          from '../../../shared/animations/fade.animation';
import { Picture }                       from '../../models/picture/picture';

@Component({
  selector: 'picture-edit-dialog',
  templateUrl: './picture-edit-dialog.html',
  styleUrls: ['./picture-edit-dialog.scss'],
  animations: [
    fade
  ]
})
export class PictureEditDialogComponent implements OnInit {

  picture: Picture;
  caption: string;

  constructor(
    public dialogRef: MatDialogRef<PictureEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { picture: Picture }) {}

  onSubmit() {
    this.dialogRef.close({
      caption: this.caption
    });
  }

  ngOnInit() {
    this.caption = this.data.picture.caption;
  }

}
