import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { Question }             from './question';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  constructor(private http: ApiHttpClientService) { }

  getQuestions(): Observable<Question[]> {
    return this.http.get('questions').pipe(
      map((res: any) => res.questions.map(question => new Question(question)))
    );
  }
}
