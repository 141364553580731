
<div class="required-attachments">
  <div class="required-attachments__header">
    <h4 class="required-attachments__header__title">Allegati</h4>
  </div>
  <div class="required-attachments__loading" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <div class="required-attachments__table" *ngIf="!loading">
    <div class="required-attachments__table__header">
      <div>Nome</div>
      <div>Descrizione</div>
      <div>Domanda</div>
      <div *ngIf="assignment.status !== 'inspection'">Nome file</div>
    </div>
    <div class="required-attachments__table__body">
      <div class="required-attachments__table__body__row" *ngFor="let requiredAttachment of requiredAttachments">
        <div>{{requiredAttachment.label}}</div>
        <div>{{requiredAttachment.description}}</div>
        <div>{{requiredAttachment.questionText}}</div>
        <div class="required-attachments__table__body__row__document" *ngIf="assignment.status !== 'inspection'">
          <!-- Attachment download and removal -->
          <attachment-chip *ngIf="requiredAttachment.attachment && !requiredAttachment.busy && !requiredAttachment.attachment?.busy"
                           [attachment]="requiredAttachment.attachment"
                           [canDelete]="assignment.status !== 'completed'"
                           (download)="onAttachmentDownload(requiredAttachment.attachment)"
                           (delete)="onAttachmentDelete(requiredAttachment)">
          </attachment-chip>
          <ng-container *ngIf="!requiredAttachment.attachment && !requiredAttachment.busy && !requiredAttachment.attachment?.busy">
            <!-- Attachment upload -->
            <button mat-button
                    class="icon-button"
                    (click)="onAttachmentAdd(requiredAttachment)">
              <mat-icon svgIcon="attach_file"></mat-icon> Aggiungi allegato
            </button>
          </ng-container>
          <!-- Spinner -->
          <div class="spinner" *ngIf="requiredAttachment.busy || requiredAttachment.attachment?.busy"></div>
        </div>
      </div>
    </div>
  </div>
</div>
