import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NetStatusService }                        from './core/net-status/net-status.service';
import { MatIconRegistry }                         from '@angular/material';
import { DomSanitizer }                            from '@angular/platform-browser';
import { SpinnerService }                          from './core/spinner/spinner.service';
import { SpinnerData }                             from './core/spinner/spinner-data';
import { DialogService }                           from './core/dialog/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  onlineListener;
  offlineListener;
  showSpinner: boolean;
  spinnerMessage: string;
  konamis = 0;

  constructor(private renderer: Renderer2,
              private netStatus: NetStatusService,
              private matIconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private dialog: DialogService,
              private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.onlineListener = this.renderer.listen('window', 'online', () => this.netStatus.setOnline());
    this.offlineListener = this.renderer.listen('window', 'offline', () => this.netStatus.setOffline());

    this.matIconRegistry
      .addSvgIcon('offline_bolt',       this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/offline_bolt.svg'))
      .addSvgIcon('close',              this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/close.svg'))
      .addSvgIcon('more_vert',          this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/more_vert.svg'))
      .addSvgIcon('search',             this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/search.svg'))
      .addSvgIcon('arrow_forward',      this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow_forward.svg'))
      .addSvgIcon('arrow_back',         this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow_back.svg'))
      .addSvgIcon('lens',               this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/lens.svg'))
      .addSvgIcon('keyboard_backspace', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/keyboard_backspace.svg'))
      .addSvgIcon('save_alt',           this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/save_alt.svg'))
      .addSvgIcon('done',               this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/done.svg'))
      .addSvgIcon('notes',              this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/notes.svg'))
      .addSvgIcon('cloud_upload',       this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/cloud_upload.svg'))
      .addSvgIcon('library_books',      this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/library_books.svg'))
      .addSvgIcon('undo',               this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/undo.svg'))
      .addSvgIcon('edit',               this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/edit.svg'))
      .addSvgIcon('attach_file',        this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/attach_file.svg'))
      .addSvgIcon('note_add',           this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/note_add.svg'))
      .addSvgIcon('picture_as_pdf',     this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/picture_as_pdf.svg'))
      .addSvgIcon('delete',             this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/delete.svg'))
      .addSvgIcon('open_with',          this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/open_with.svg'))
      .addSvgIcon('expand_less',        this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/expand_less.svg'))
      .addSvgIcon('memory',             this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/memory.svg'))
      .addSvgIcon('contact_support',    this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/contact_support.svg'));

    this.spinnerService.spinnerStatus.subscribe((val: SpinnerData) => {
      setTimeout(() => {
        this.showSpinner = val.status;
        this.spinnerMessage = val.message;
      }, 0);
    });
  }

  async onKonami() {
    this.konamis += 1;

    if (this.konamis >= 3) {
      const confirm = await this.dialog.confirmDialog('U SURE MATE').toPromise();
      if (!!confirm) {
        document.body.classList.add('bollocks');
        console.error('AYYYYY');
      }
    }
  }

  ngOnDestroy() {
    this.onlineListener();
    this.offlineListener();
  }
}
