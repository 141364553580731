import { NgModule }               from '@angular/core';
import { AnswerModule }           from './answer/answer.module';
import { AssignmentStatusModule } from './assignment-status/assignment-status.module';
import { FactoryTypeModule }      from './factory-type/factory-type.module';
import { QuestionModule }         from './question/question.module';
import { SectionModule }          from './section/section.module';
import { SubsectionModule }       from './subsection/subsection.module';
import { SourceModule }           from './source/source.module';
import { TargetModule }           from './target/target.module';
import { AssignmentModule }       from './assignment/assignment.module';
import { ScoreMappingModule }     from './score-mapping/score-mapping.module';

@NgModule({
  exports: [
    AnswerModule,
    AssignmentModule,
    AssignmentStatusModule,
    FactoryTypeModule,
    QuestionModule,
    ScoreMappingModule,
    SectionModule,
    SourceModule,
    SubsectionModule,
    TargetModule
  ]
})
export class ModelsModule { }
