var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IdbWrapperService } from '../idb-wrapper/idb-wrapper.service';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { FactoryTypesService } from '../models/factory-type/factory-types.service';
import { AssignmentStatusesService } from '../models/assignment-status/assignment-statuses.service';
import { catchError, flatMap, map, mapTo, scan, startWith, switchMap, take, tap } from 'rxjs/operators';
import { SectionsService } from '../models/section/sections.service';
import { SubsectionsService } from '../models/subsection/subsections.service';
import { NetStatusService } from '../net-status/net-status.service';
import { ToasterService } from '../toaster/toaster.service';
import { QuestionsService } from '../models/question/questions.service';
import { AnswersService } from '../models/answer/answers.service';
import { SourcesService } from '../models/source/sources.service';
import { TargetsService } from '../models/target/targets.service';
import { ScoreMappingsService } from '../models/score-mapping/score-mappings.service';
import { AssignmentFieldsService } from '../models/assignment-field/assignment-fields.service';
import { RequiredAttachmentService } from '../models/required-attachment/required-attachment.service';
import { AssignmentsService } from '../models/assignment/assignments.service';
import * as i0 from "@angular/core";
import * as i1 from "../net-status/net-status.service";
import * as i2 from "../idb-wrapper/idb-wrapper.service";
import * as i3 from "../models/factory-type/factory-types.service";
import * as i4 from "../models/assignment-status/assignment-statuses.service";
import * as i5 from "../models/assignment-field/assignment-fields.service";
import * as i6 from "../models/section/sections.service";
import * as i7 from "../models/subsection/subsections.service";
import * as i8 from "../models/question/questions.service";
import * as i9 from "../models/source/sources.service";
import * as i10 from "../models/target/targets.service";
import * as i11 from "../models/score-mapping/score-mappings.service";
import * as i12 from "../toaster/toaster.service";
import * as i13 from "../models/answer/answers.service";
import * as i14 from "../models/required-attachment/required-attachment.service";
import * as i15 from "../models/assignment/assignments.service";
var SyncService = /** @class */ (function () {
    function SyncService(netStatus, idbWrapper, factoryTypesService, assignmentStatusesService, assignmentFieldsService, sectionsService, subsectionsService, questionsService, sourcesService, targetsService, scoreMappingsService, toaster, answersService, requiredAttachmentsService, assignmentsService) {
        this.netStatus = netStatus;
        this.idbWrapper = idbWrapper;
        this.factoryTypesService = factoryTypesService;
        this.assignmentStatusesService = assignmentStatusesService;
        this.assignmentFieldsService = assignmentFieldsService;
        this.sectionsService = sectionsService;
        this.subsectionsService = subsectionsService;
        this.questionsService = questionsService;
        this.sourcesService = sourcesService;
        this.targetsService = targetsService;
        this.scoreMappingsService = scoreMappingsService;
        this.toaster = toaster;
        this.answersService = answersService;
        this.requiredAttachmentsService = requiredAttachmentsService;
        this.assignmentsService = assignmentsService;
        this.syncDone = false;
    }
    SyncService.prototype.syncResources = function () {
        var _this = this;
        return this.netStatus.onlineStatus.pipe(take(1), flatMap(function (isOnline) {
            if (isOnline) {
                _this.toaster.displayNotice('Sincronizzazione in corso', 999999);
                // Perform the resource request to the server
                return combineLatest([
                    _this.factoryTypesService.getFactoryTypes(),
                    _this.assignmentStatusesService.getAssignmentStatuses(),
                    _this.assignmentFieldsService.getAssignmentFields(),
                    _this.sectionsService.getSections(),
                    _this.subsectionsService.getSubsections(),
                    _this.questionsService.getQuestions(),
                    _this.sourcesService.getSources(),
                    _this.targetsService.getTargets(),
                    _this.scoreMappingsService.getScoreMappings(),
                    _this.requiredAttachmentsService.getRequiredAttachments()
                ]).pipe(
                // If it succeeds, clear the resources stores
                flatMap(function (resources) { return combineLatest([
                    _this.idbWrapper.clear('factoryTypes'),
                    _this.idbWrapper.clear('assignmentStatuses'),
                    _this.idbWrapper.clear('assignmentFields'),
                    _this.idbWrapper.clear('sections'),
                    _this.idbWrapper.clear('subsections'),
                    _this.idbWrapper.clear('questions'),
                    _this.idbWrapper.clear('sources'),
                    _this.idbWrapper.clear('targets'),
                    _this.idbWrapper.clear('scoreMappings'),
                    _this.idbWrapper.clear('requiredAttachments')
                    // Propagate forward the retrieved resource after clearing the stores
                ]).pipe(mapTo(resources)); }), 
                // Perform the insertion of the resources into the stores
                flatMap(function (_a) {
                    var factoryTypes = _a[0], assignmentStatuses = _a[1], assignmentFields = _a[2], sections = _a[3], subsections = _a[4], questions = _a[5], sources = _a[6], targets = _a[7], scoreMappings = _a[8], requiredAttachments = _a[9];
                    return combineLatest([
                        _this.idbWrapper.bulkAdd('factoryTypes', factoryTypes),
                        _this.idbWrapper.bulkAdd('assignmentStatuses', assignmentStatuses),
                        _this.idbWrapper.bulkAdd('assignmentFields', assignmentFields),
                        _this.idbWrapper.bulkAdd('sections', sections),
                        _this.idbWrapper.bulkAdd('subsections', subsections),
                        _this.idbWrapper.bulkAdd('questions', questions),
                        _this.idbWrapper.bulkAdd('sources', sources),
                        _this.idbWrapper.bulkAdd('targets', targets),
                        _this.idbWrapper.bulkAdd('scoreMappings', scoreMappings),
                        _this.idbWrapper.bulkAdd('requiredAttachments', requiredAttachments)
                    ]);
                }), 
                // cleanup indexedDB from assignments that don't belong there
                switchMap(function () { return combineLatest([
                    _this.assignmentsService.getAssignments(true),
                    _this.idbWrapper.all('assignments')
                ]); }), switchMap(function (_a) {
                    var remoteAssignments = _a[0], localAssignments = _a[1];
                    return localAssignments.length ? combineLatest(localAssignments.map(function (localAssignment) { return _this.checkLocalAssignment(localAssignment, remoteAssignments); })) : of(null);
                }), 
                // Display a toaster and mark the sync as done
                tap(function () {
                    _this.toaster.displaySuccess('Sincronizzazione completata', 1500);
                    _this.syncDone = true;
                }), 
                // Return true to the canActivate method
                mapTo(true), 
                // In case of error, return false to the canActivate method and display an error toaster
                catchError(function (error) {
                    console.error(error);
                    _this.toaster.displayError('Errore nella sincronizzazione');
                    return of(false);
                }));
                // If we're offline, we skip the sync, while not marking it as done
            }
            else {
                return of(true);
            }
        }));
    };
    // This method is pure observable extravaganza, but I'll try to explain it as clearly as I can
    SyncService.prototype.syncAssignment = function (assignment) {
        var _this = this;
        // Retrieve all the stored answers
        return this.idbWrapper.all('answers').pipe(
        // Filter the answers related to the current assignment
        map(function (answers) { return answers.filter(function (answer) { return answer.assignmentId === assignment.id; }); }), switchMap(function (answers) {
            /*
             Merge the emissions (one per each father/children group) into a single stream. Most answers won't have any children.
             Goes from this:
             [ [Q1, C1Q1, C2Q1], [Q2], [Q3], [Q4, C1Q4]]
             to this:
             [ Q1, C1Q1, C2Q1, Q2, Q3, Q4, C1Q4]
              */
            return merge.apply(void 0, answers
                // Filter the answers without a parent
                .filter(function (answer) { return !answer.parentAnswerId; })
                // For each answer, produce the correspondent PUT request observable
                .map(function (answer) { return _this.answersService.createAnswer(assignment.id, answer).pipe(
            // Once the PUT terminates, submit its child answers, if there are any
            switchMap(function (createdAnswer) {
                /*
                 Merge the emissions (one per observable) into a single stream
                 Goes from this:
                 [ [Father], [Child1], [Child2], [Child3]]
                 to this:
                 [Father, Child1, Child2, Child3]
                  */
                return merge.apply(void 0, [
                    // Include the parent answer, which we've just submitted, in the stream
                    of(createdAnswer)].concat(answers
                    // Filter the child answers for the current answer
                    .filter(function (ans) { return ans.parentAnswerId === answer.id; })
                    /*
                     Perform a PUT request for each child answer, replacing the parentAnswerId with the
                     one we've just obtained from the remote DB
                      */
                    .map(function (childAnswer) { return _this.answersService.createAnswer(assignment.id, __assign({}, childAnswer, { parentAnswerId: createdAnswer.id })); })));
            })); })
            // Here I convert a stream of observable emissions, one per successful PUT request, into a counter
            ).pipe(
            // Start with 0
            startWith(0), 
            // Add 1 to the accumulator for each terminated request
            scan(function (total) { return total + 1; }), 
            // Convert the number into a percentage
            map(function (total) { return (total / answers.length) * 100; }));
        }));
    };
    SyncService.prototype.checkLocalAssignment = function (localAssignment, remoteAssignments) {
        var index = remoteAssignments.findIndex(function (remoteAssignment) { return remoteAssignment.id === localAssignment.id; });
        if (index !== -1 && remoteAssignments[index].status === 'inspection') {
            return of(true);
        }
        else {
            return this.idbWrapper.delete('assignments', localAssignment.id);
        }
    };
    SyncService.prototype.canActivate = function () {
        if (this.syncDone) {
            return of(true);
        }
        else {
            return this.syncResources();
        }
    };
    SyncService.ngInjectableDef = i0.defineInjectable({ factory: function SyncService_Factory() { return new SyncService(i0.inject(i1.NetStatusService), i0.inject(i2.IdbWrapperService), i0.inject(i3.FactoryTypesService), i0.inject(i4.AssignmentStatusesService), i0.inject(i5.AssignmentFieldsService), i0.inject(i6.SectionsService), i0.inject(i7.SubsectionsService), i0.inject(i8.QuestionsService), i0.inject(i9.SourcesService), i0.inject(i10.TargetsService), i0.inject(i11.ScoreMappingsService), i0.inject(i12.ToasterService), i0.inject(i13.AnswersService), i0.inject(i14.RequiredAttachmentService), i0.inject(i15.AssignmentsService)); }, token: SyncService, providedIn: "root" });
    return SyncService;
}());
export { SyncService };
