import { NgModule }                from '@angular/core';
import { BrowserModule }           from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule }       from '@angular/common/http';
import { AngularTokenService }    from 'angular-token';
import { SideBarModule }       from './side-bar/side-bar.module';
import { ToasterModule }       from './toaster/toaster.module';
import { IdbWrapperModule }    from './idb-wrapper/idb-wrapper.module';
import { UserModule }          from './user/user.module';
import { NetStatusModule }     from './net-status/net-status.module';
import { SyncModule }          from './sync/sync.module';
import { ApiHttpClientModule } from './api-http-client/api-http-client.module';
import { DialogModule }        from './dialog/dialog.module';
import { ModelsModule }        from './models/models.module';
import { SpinnerModule }       from './spinner/spinner.module';
import { DateAdapterModule }   from './date-adapter/date-adapter.module';

@NgModule({
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SideBarModule,
    ToasterModule,
    UserModule,
    IdbWrapperModule,
    NetStatusModule,
    SyncModule,
    ApiHttpClientModule,
    DialogModule,
    ModelsModule,
    SpinnerModule,
    DateAdapterModule
  ],
  providers: [
    AngularTokenService
  ]
})
export class CoreModule {
}
