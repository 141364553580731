import { Injectable }                                from '@angular/core';
import { catchError, map, mapTo, switchMap }         from 'rxjs/operators';
import { Assignment }                                from './assignment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { ApiHttpClientService }                      from '../../api-http-client/api-http-client.service';
import { HttpParams }                                from '@angular/common/http';
import { IdbWrapperService }                         from '../../idb-wrapper/idb-wrapper.service';
import { AnswersService }                            from '../answer/answers.service';
import { Answer }                                    from '../answer/answer';
import { RequiredAttachment }                        from '../required-attachment/required-attachment';
import { RequiredAttachmentService }                 from '../required-attachment/required-attachment.service';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {

  constructor(private http: ApiHttpClientService,
              private idbWrapper: IdbWrapperService,
              private answersService: AnswersService,
              private requiredAttachmentsService: RequiredAttachmentService) {
  }

  createAssignment(assignmentData: any): Observable<Assignment> {
    const bodyString = this.getBodyString(assignmentData);
    return this.http.post('assignments', bodyString).pipe(
      map((res: any) => new Assignment(res))
    );
  }

  updateAssignment(assignmentData: any): Observable<Assignment> {
    const bodyString = this.getBodyString(assignmentData);
    return this.http.put(`assignments/${assignmentData.id}`, bodyString).pipe(
      map((res: any) => new Assignment(res))
    );
  }

  deleteAssignment(assignmentId: number) {
    const bodyString = {deleted: true};
    return combineLatest([
      this.idbWrapper.delete('assignments', assignmentId),
      this.http.put(`assignments/${assignmentId}`, bodyString).pipe(
        map((res: any) => new Assignment(res))
      )
    ]);
  }

  getAssignment(isOnline: boolean, id: number): Observable<Assignment> {
    if (isOnline) {
      const httpRequest = this.http.get(`assignments/${id}`).pipe(
        map((res: any) => new Assignment(res))
      );
      return combineLatest([
        httpRequest,
        this.idbWrapper.get('assignments', id)
      ]).pipe(
        map(([assignment, offlineAssignment]: [Assignment, Assignment]) => ({
          ...assignment,
          requiredAttachments: assignment.status === 'inspection' && offlineAssignment ? offlineAssignment.requiredAttachments : [],
          offline: !!offlineAssignment,
          assignmentNotes: assignment.status === 'inspection' ? (offlineAssignment ? offlineAssignment.assignmentNotes : assignment.assignmentNotes) : assignment.assignmentNotes
        }))
      );
    } else {
      return this.idbWrapper.get('assignments', id).pipe(
        map(assignment => ({
          ...assignment,
          offline: true
        })),
        switchMap((assignment: Assignment) => {
          if (assignment.status !== 'inspection' && !isOnline) {
            return this.idbWrapper.delete('assignments', assignment.id).pipe(() => throwError(''));
          } else {
            return of(assignment);
          }
        })
      );
    }
  }

  downloadAssignment(assignment: Assignment): Observable<any> {
    return this.idbWrapper.add('assignments', assignment).pipe(
      switchMap(() => this.answersService.getAnswers(assignment.id)),
      switchMap((answers: Answer[]) => {
        if (answers && answers.length > 0) {
          const newAnswers = answers.map(answer => {
            const {id: removed, ...newAnswer} = answer;
            return newAnswer;
          });
          return this.idbWrapper.bulkAdd('answers', newAnswers);
        } else {
          return of(true);
        }
      }),
      mapTo(true)
    );
  }

  getAssignments(isOnline: boolean, query?: any): Observable<Assignment[]> {
    if (isOnline) {
      let params: HttpParams = new HttpParams();
      for (const key in query) {
        if (query.hasOwnProperty(key) && query[key] !== null && query[key] !== undefined) {
          let paramKey = `q[${key}]`;

          if (Array.isArray(query[key])) {
            paramKey += '[]';
            query[key].forEach(item => params = params.append(paramKey, item));
          } else {
            params = params.set(paramKey, query[key]);
          }
        }
      }

      const httpRequest = this.http.get('assignments', {params: params}).pipe(
        map((res: any) => res.assignments.map(assignment => new Assignment(assignment)))
      );

      return combineLatest([
        httpRequest,
        this.idbWrapper.all('assignments')
      ]).pipe(
        map(([assignments, offlineAssignments]) => assignments.map(assignment => ({
            ...assignment,
            offline: !!((offlineAssignments as any[]).find(item => item.id === assignment.id))
          }))
        )
      );
    } else {
      return this.idbWrapper.all('assignments').pipe(
        map(assignments => assignments.map(assignment => ({
          ...assignment,
          offline: true
        })))
      );
    }

  }

  getAssignmentReport(assignmentId: number) {
    return this.http.get(`assignments/${assignmentId}/download_report`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  getPicturesReport(assignmentId: number) {
    return this.http.get(`assignments/${assignmentId}/pictures_report`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  closeInspection(assignmentId: number, reviserId?: number): Observable<Assignment> {
    return this.idbWrapper.get('assignments', assignmentId).pipe(switchMap((offlineAssignment) => {
      const bodyString = {assignment: {status: 'processing', assignment_notes: offlineAssignment.assignmentNotes}};
      if (reviserId) bodyString.assignment['reviser_id'] = reviserId;
      return this.http.put(`assignments/${assignmentId}`, bodyString).pipe(
        map(assignment => new Assignment(assignment)),
        switchMap((assignment: Assignment) => {
          if (offlineAssignment.requiredAttachments.length > 0) {
            return combineLatest([
              ...offlineAssignment.requiredAttachments.map((requiredAttachment: RequiredAttachment) => {
                return this.requiredAttachmentsService.createRequiredAttachment(requiredAttachment);
              })
            ]).pipe(mapTo(assignment));
          }
          return of(assignment);
        }),
        switchMap((assignment: Assignment) => this.idbWrapper.delete('assignments', assignmentId).pipe(
          mapTo(assignment),
          catchError(() => of(assignment))
        ))
      );
    }));

  }

  setToUnderRevision(assignmentId: number): Observable<Assignment> {
    const bodyString = {assignment: {status: 'under_revision'}};
    return this.http.put(`assignments/${assignmentId}`, bodyString).pipe(
      map(assignment => new Assignment(assignment)),
      switchMap((assignment: Assignment) => this.idbWrapper.delete('assignments', assignmentId).pipe(
        mapTo(assignment),
        catchError(() => of(assignment))
      ))
    );
  }

  setAssignment(value) {
    // const data = this.assignment$.value;
    // // assignments$.offline = true;
    // this.assignment$.next(data);
  }

  finalizeAssignment(assignmentId, body, options) {
    return this.http.post(`assignments/${assignmentId}/finalize`, body, options);
  }

  getFinalReport(assignmentId) {
    return this.http.get(`assignments/${assignmentId}/final_report`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  updateUser(assignmentId, userId, resource) {
    return this.http.put(`assignments/${assignmentId}`, {
      [`${resource}_id`]: userId || null
    }).pipe(
      map((res: any) => new Assignment(res))
    );
  }

  computeRiskScores(assignmentId) {
    return this.http.post(`assignments/${assignmentId}/compute_risk_scores`, {});
  }

  getBodyString(assignment) {
    return JSON.stringify({
      assignment: {
        accident_number: assignment.accidentNumber,
        agency: assignment.agency,
        agency_code: assignment.agencyCode,
        factory_type_id: assignment.factoryTypeId,
        insured: assignment.insured,
        location: assignment.location,
        policy_number: assignment.policyNumber,
        practice_number: assignment.practiceNumber,
        principal: assignment.principal,
        surveyor: assignment.surveyor,
        assignment_notes: assignment.assignmentNotes,
        compiler_id: assignment.compiler_id
      }
    });
  }
}
