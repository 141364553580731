import { NgModule }                from '@angular/core';
import { RouterModule, Routes }    from '@angular/router';
import { DashboardComponent }      from './dashboard/dashboard.component';
import { LoginComponent }          from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { UserService } from './core/user/user.service';
import { PasswordChangeGuard } from './password-change/password-change.guard';
import { LoginGuard } from './login/login.guard';
import { SyncService } from './core/sync/sync.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'password-change',
    component: PasswordChangeComponent,
    canActivate: [PasswordChangeGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [UserService, SyncService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}
