import { Question } from '../question/question';

export class Subsection {
  id: number;
  name: string;
  sectionId: number;
  factoryTypeIds: number[];
  sort: number;
  questions: Question[];

  constructor(obj: any) {
    this.id =             obj && obj.id;
    this.sectionId =      obj && obj.section_id;
    this.name =           obj && obj.name           || null;
    this.factoryTypeIds =         obj && obj.factory_type_ids || null;
    this.sort =           obj && obj.sort;
  }
}
