import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { map } from 'rxjs/operators';
import { Picture } from './picture';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var PicturesService = /** @class */ (function () {
    function PicturesService(http) {
        this.http = http;
    }
    PicturesService.prototype.createPicture = function (pictureData) {
        var formData = new FormData();
        formData.append('picture[answer_id]', '' + pictureData.answerId);
        if (pictureData.caption) {
            formData.append('picture[caption]', pictureData.caption);
        }
        formData.append('picture[file]', pictureData.file);
        return this.http.post('pictures', formData, { headers: new HttpHeaders() }).pipe(map(function (res) { return new Picture(res); }));
    };
    PicturesService.prototype.getPicture = function (pictureId) {
        return this.http.get("pictures/" + pictureId, {
            responseType: 'blob'
        });
    };
    PicturesService.prototype.getPictures = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/pictures").pipe(map(function (res) { return res && res.pictures.map(function (picture) { return new Picture(picture); }); }));
    };
    PicturesService.prototype.deletePicture = function (pictureId) {
        return this.http.delete("pictures/" + pictureId);
    };
    PicturesService.prototype.updatePicture = function (picture) {
        var bodyString = {
            caption: picture.caption,
            sort: picture.sort
        };
        return this.http.put("pictures/" + picture.id, bodyString).pipe(map(function (newPicture) { return new Picture(newPicture); }));
    };
    PicturesService.ngInjectableDef = i0.defineInjectable({ factory: function PicturesService_Factory() { return new PicturesService(i0.inject(i1.ApiHttpClientService)); }, token: PicturesService, providedIn: "root" });
    return PicturesService;
}());
export { PicturesService };
