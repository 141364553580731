import { Component, OnDestroy, OnInit } from '@angular/core';
import { switchMap, tap }               from 'rxjs/operators';
import { ActivatedRoute }               from '@angular/router';
import { AssignmentsService }          from '../../core/models/assignment/assignments.service';
import { SideMenuService }             from '../../core/side-bar/side-menu/side-menu.service';
import { combineLatest, Subscription } from 'rxjs';
import { AttachmentsService } from '../../core/models/attachment/attachments.service';
import { Assignment }        from '../../core/models/assignment/assignment';
import { Attachment }        from '../../core/models/attachment/attachment';
import { UtilsService }      from '../../core/utils/utils.service';
import { DialogService }     from '../../core/dialog/dialog.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { Question }          from '../../core/models/question/question';
import { zoomLeave }         from '../../shared/animations/zoom-leave.animation';
import { NetStatusService }  from '../../core/net-status/net-status.service';
import { UserService }       from '../../core/user/user.service';
import { HttpResponse }      from '@angular/common/http';

@Component({
  selector: 'attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  animations: [zoomLeave],
})
export class AttachmentsComponent implements OnInit, OnDestroy {

  assignment: Assignment;
  attachments: Attachment[];
  loading = false;
  private activatedRouteSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private assignmentsService: AssignmentsService,
              private sideMenuService: SideMenuService,
              private attachmentsService: AttachmentsService,
              private utils: UtilsService,
              private dialog: DialogService,
              private idbWrapper: IdbWrapperService,
              public userService: UserService) {
  }

  onAttachmentDownload(attachment: Attachment) {
    attachment.busy = true;
    this.attachmentsService.getAttachment(attachment.id).subscribe((res: HttpResponse<any>) => {
      const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
      this.utils.downloadFile(blob, filename);
      attachment.busy = false;
    });
  }

  async onAttachmentDelete(attachment: Attachment) {
    const confirm = await this.dialog.confirmDialog(`Confermi l\'eliminazione del file?`).toPromise();
    if (confirm) {
      attachment.busy = true;
      this.attachmentsService.deleteAttachment(attachment.id).subscribe(() => {
        this.attachments.splice(this.attachments.findIndex((att: Attachment) => att.id === attachment.id), 1);
      });
    }
  }

  ngOnInit() {
    let id;
    this.loading = true;
    this.activatedRouteSubscription = this.activatedRoute.params.pipe(
      switchMap(() => this.activatedRoute.params),
      tap(res => id = +res.id),
      switchMap(() => combineLatest([
        this.assignmentsService.getAssignment(true, id),
        this.attachmentsService.getAttachments(id),
        this.idbWrapper.all('questions')
      ]))
    ).subscribe(([assignment, attachments, questions]: [Assignment, Attachment[], Question[]]) => {
      this.sideMenuService.sideMenuState = {
        state: 'attachments',
        assignment,
        currentSubRoute: 'attachments'
      };
      this.assignment = assignment;
      this.attachments = attachments.map((attachment: Attachment) => {
          const question = questions.find((q: Question) => q.id === attachment.questionId);
          if (question) {
            attachment.questionText = (
              question.questionTexts.find(text => text.factoryTypeId === assignment.factoryTypeId) ||
              question.questionTexts.find(text => text.factoryTypeId === null)
            ).text;
          }

          return attachment;
        }
      );
      this.loading = false;
    });
  }

  ngOnDestroy() {
    if (this.activatedRouteSubscription) this.activatedRouteSubscription.unsubscribe();
  }

}
