/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./confirm-dialog";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Conferma azione"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["No"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["mat-button", ""], ["tabindex", "1"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dialogRef.close(i1.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(16, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["S\u00EC"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = ""; _ck(_v, 11, 0, currVal_5); var currVal_9 = true; _ck(_v, 15, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.text; _ck(_v, 7, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 11)._hasAriaLabel ? i1.ɵnov(_v, 11).ariaLabel : null); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 15)._hasAriaLabel ? i1.ɵnov(_v, 15).ariaLabel : null); var currVal_7 = (i1.ɵnov(_v, 16).disabled || null); var currVal_8 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.ConfirmDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("confirm-dialog", i8.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
