import { NgModule }                                             from '@angular/core';
import { CommonModule }                                         from '@angular/common';
import { NotesComponent }                                       from './notes.component';
import { MatBottomSheetModule, MatButtonModule, MatIconModule } from '@angular/material';
import { NotesSheetComponent }                                  from './notes-sheet/notes-sheet';
import { FormsModule }                                          from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatBottomSheetModule
  ],
  declarations: [
    NotesComponent,
    NotesSheetComponent
  ],
  entryComponents: [NotesSheetComponent],
  exports: [NotesComponent]
})
export class NotesModule { }
