import { Component, OnDestroy, OnInit }                             from '@angular/core';
import { SideMenuService }                                          from '../../core/side-bar/side-menu/side-menu.service';
import { ActivatedRoute, Router }                                   from '@angular/router';
import { mapTo, switchMap, tap }                                    from 'rxjs/operators';
import { NetStatusService }                                         from '../../core/net-status/net-status.service';
import { AssignmentsService }                                       from '../../core/models/assignment/assignments.service';
import { Assignment }                                               from '../../core/models/assignment/assignment';
import { IdbWrapperService }                                        from '../../core/idb-wrapper/idb-wrapper.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { Subsection }                                               from '../../core/models/subsection/subsection';
import { Question }                                                 from '../../core/models/question/question';
import { Answer }                                                   from '../../core/models/answer/answer';
import { ToasterService }                                           from '../../core/toaster/toaster.service';
import { AnswersService }                                           from '../../core/models/answer/answers.service';
import { AuditRefreshService }                                      from './audit-refresh.service';
import { QuestionChanges }                                          from './question-card/question-card.component';
import { UserService }                                              from '../../core/user/user.service';
import { collapse }                                                 from '../../shared/animations/collapse.animation';
import * as scrollMonitor                                           from 'scrollmonitor';
import { RequiredAttachmentService }                                from '../../core/models/required-attachment/required-attachment.service';
import { RequiredAttachment }                                       from '../../core/models/required-attachment/required-attachment';
import { PicturesService }                                          from '../../core/models/picture/pictures.service';
import { Picture }                                                  from '../../core/models/picture/picture';
import { AttachmentsService }                                       from '../../core/models/attachment/attachments.service';
import { Attachment }                                               from '../../core/models/attachment/attachment';
import { DomSanitizer }                                             from '@angular/platform-browser';
import { UtilsService }                                             from '../../core/utils/utils.service';
import { Section }                                                  from '../../core/models/section/section';
import { HttpResponse }                                             from '@angular/common/http';
import { SaveState }                                                from '../../shared/last-saved/save-state';

/*
  Please don't judge all the spaghetti code you'll find here too harshly. Deadlines fucking suck
 */

@Component({
  selector: 'audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  animations: [
    collapse
  ]
})
export class AuditComponent implements OnInit, OnDestroy {

  currentSection: Section;
  assignment: Assignment;
  subsections = [];
  // In this array I store all the answer option ids that were given in this assignment. Used for the "unlocked by" functionality
  answerOptions: {
    questionId: number,
    answerOptionId: number,
    parentAnswerId: number
  }[];
  animate = false;
  private watchersInitialized = false;
  private containerMonitor;
  activatedRouteSubscription: Subscription;
  openPicture: { url, caption: string };
  savingSubject$: BehaviorSubject<SaveState> = new BehaviorSubject<SaveState>({});
  saving$ = this.savingSubject$.asObservable();

  constructor(private sideMenuService: SideMenuService,
              private activatedRoute: ActivatedRoute,
              private netStatus: NetStatusService,
              private assignmentsService: AssignmentsService,
              private idbWrapperService: IdbWrapperService,
              private answersService: AnswersService,
              private toaster: ToasterService,
              private router: Router,
              private auditRefresh: AuditRefreshService,
              private userService: UserService,
              private requiredAttachmentsService: RequiredAttachmentService,
              private picturesService: PicturesService,
              private attachmentsService: AttachmentsService,
              private sanitizer: DomSanitizer,
              private utils: UtilsService) {
  }

  // Event handlers

  onQuestionChange(event: { changes: QuestionChanges, question: Question }) {
    const {changes, question} = event;
    changes.assignmentId = this.assignment.id;
    this.setSaving();
    if (this.assignment.status === 'inspection') {
      this.idbWrapperService.all('answers').subscribe(answers => {
        const index = answers.findIndex(el =>
          el.questionId === changes.questionId &&
          el.assignmentId === changes.assignmentId
        );
        if (index === -1) {
          this.idbWrapperService.add('answers', changes);
        } else {
          this.idbWrapperService.update('answers', answers[index].id, changes);
        }
        this.setSaved();
      });
    } else {
      let answerObs: Observable<Answer>;
      if (question.answer) {
        answerObs = this.answersService.updateAnswer(question.answer.id, changes);
      } else {
        answerObs = this.answersService.createAnswer(this.assignment.id, changes);
      }

      answerObs.subscribe((answer: Answer) => {
        const questionToUpdate = this.subsections
          .map((subsection: Subsection) => subsection.questions)
          .reduce((questions: Question[], subsectionQuestions: Question[]) => [...questions, ...subsectionQuestions], [])
          .find((q: Question) => q.id === question.id);

        if (questionToUpdate.answer) {
          questionToUpdate.answer.value = answer.value;
          questionToUpdate.answer.freeNotes = answer.freeNotes;
        } else {
          questionToUpdate.answer = answer;
        }
        this.setSaved();
      });
    }
    this.updateAnswerOptionIds(changes.value, question);
  }

  onChildQuestionChange(event: { changes: QuestionChanges, parentAnswer: Answer }) {
    const {changes, parentAnswer} = event;
    changes.assignmentId = this.assignment.id;
    this.savingSubject$.next({saving: true});
    if (this.assignment.status === 'inspection') {
      this.idbWrapperService.all('answers').subscribe(answers => {
        const index = answers.findIndex(el =>
          el.questionId === changes.questionId &&
          el.assignmentId === changes.assignmentId &&
          el.parentAnswerId === changes.parentAnswerId
        );
        if (index === -1) {
          this.idbWrapperService.add('answers', changes).pipe(
            switchMap(newAnswerId => this.idbWrapperService.get('answers', newAnswerId))
          ).subscribe(newAnswer => {
            if (!parentAnswer.childAnswers) {
              parentAnswer.childAnswers = [];
            }
            parentAnswer.childAnswers.push(newAnswer);
            this.setSaved();
          });
        } else {
          this.idbWrapperService.update('answers', answers[index].id, changes);
          const childAnswer = parentAnswer.childAnswers.find(ans =>
            ans.questionId === changes.questionId &&
            ans.assignmentId === changes.assignmentId &&
            ans.parentAnswerId === changes.parentAnswerId
          );
          childAnswer.freeNotes = changes.freeNotes;
          childAnswer.value = changes.value;
          this.setSaved();
        }
      });
    } else {
      const childAnswer = parentAnswer.childAnswers.find(ans =>
        ans.questionId === changes.questionId &&
        ans.assignmentId === changes.assignmentId &&
        ans.parentAnswerId === changes.parentAnswerId
      );
      if (childAnswer) {
        this.answersService.updateAnswer(childAnswer.id, changes).subscribe((answer: Answer) => {
          childAnswer.id = answer.id;
          childAnswer.freeNotes = changes.freeNotes;
          childAnswer.value = changes.value;
          this.setSaved();
        });
      } else {
        this.answersService.createAnswer(this.assignment.id, changes).subscribe((answer: Answer) => {
          parentAnswer.childAnswers.push(answer);
          this.setSaved();
        });
      }
    }
    this.idbWrapperService.get('questions', changes.questionId).subscribe(question => {
      this.updateAnswerOptionIds(changes.value, question, parentAnswer.id);
    });
  }

  onParentAnswerCreate(question: Question, answer: Answer) {
    if (this.assignment.status === 'inspection') {
      this.idbWrapperService.add('answers', {questionId: answer.questionId, assignmentId: this.assignment.id}).subscribe(answerId => {
        answer.id = answerId;
      });
    } else {
      this.answersService.createAnswer(this.assignment.id, answer).subscribe(createdAnswer => {
        answer.id = createdAnswer.id;
      });
    }
  }

  onParentAnswerDelete(question: Question, answer: Answer) {
    if (this.assignment.status === 'inspection') {
      combineLatest([
        this.idbWrapperService.delete('answers', answer.id),
        ...answer.childAnswers.map(childAnswer => this.idbWrapperService.delete('answers', childAnswer.id))
      ]).subscribe(() => {
        question.groupAnswers.splice(question.groupAnswers.findIndex(ans => answer.id === ans.id), 1);
      });
    } else {
      combineLatest([
        this.answersService.deleteAnswer(answer.id),
        ...answer.childAnswers.map(childAnswer => this.answersService.deleteAnswer(childAnswer.id))
      ]).subscribe(() => {
        question.groupAnswers.splice(question.groupAnswers.findIndex(ans => answer.id === ans.id), 1);
      });
    }
  }

  onRequiredAttachmentAdd(description: string, question: Question) {
    const requiredAttachment: RequiredAttachment = {
      questionId: question.id,
      assignmentId: this.assignment.id,
      description
    } as RequiredAttachment;

    let requestObs;
    if (this.assignment.status === 'inspection') {
      requestObs = this.idbWrapperService.update(
        'assignments',
        this.assignment.id,
        {requiredAttachments: [...this.assignment.requiredAttachments, requiredAttachment]}
      ).pipe(
        mapTo({...requiredAttachment, id: this.assignment.requiredAttachments.length + 1})
      );
    } else {
      requestObs = this.requiredAttachmentsService.createRequiredAttachment(requiredAttachment);
    }
    requestObs.subscribe(res => {
      question.requiredAttachments.push(res);
      this.assignment.requiredAttachments.push(res);
    });
  }

  onRequiredAttachmentEdit(event: { requiredAttachment: RequiredAttachment, description: string }) {
    const {requiredAttachment, description} = event,
      newRA = {...requiredAttachment, description},
      assignmentRAs = [...this.assignment.requiredAttachments];
    let requestObs;
    assignmentRAs.forEach((reqAtt: RequiredAttachment) => {
      if (reqAtt.id === requiredAttachment.id) {
        reqAtt.description = description;
      }
    });
    if (this.assignment.status === 'inspection') {
      requestObs = this.idbWrapperService.update(
        'assignments',
        this.assignment.id,
        {requiredAttachments: assignmentRAs});
    } else {
      requestObs = this.requiredAttachmentsService.updateRequiredAttachment(newRA);
    }
    requestObs.subscribe(() => {
      requiredAttachment.description = description;
      this.assignment.requiredAttachments = assignmentRAs;
    });
  }

  onRequiredAttachmentDelete(event: { requiredAttachment: RequiredAttachment, question: Question }) {
    const {requiredAttachment, question} = event,
      assignmentRAs = [...this.assignment.requiredAttachments];
    let requestObs;
    assignmentRAs.splice(assignmentRAs.findIndex((reqAtt: RequiredAttachment) => {
      return reqAtt.id === requiredAttachment.id && !!reqAtt.assignmentId;
    }), 1);
    if (this.assignment.status === 'inspection') {
      requestObs = this.idbWrapperService.update(
        'assignments',
        this.assignment.id,
        {requiredAttachments: assignmentRAs});
    } else {
      requestObs = this.requiredAttachmentsService.deleteRequiredAttachment(requiredAttachment);
    }
    requestObs.subscribe(() => {
      question.requiredAttachments.splice(question.requiredAttachments.findIndex((reqAtt: RequiredAttachment) => {
        return reqAtt.id === requiredAttachment.id && !!reqAtt.assignmentId;
      }), 1);
      this.assignment.requiredAttachments = assignmentRAs;
    });
  }

  onPictureAdd(pictureData: { caption: string, file: any }, question: Question) {
    let requestObs;
    const tempPicture: Picture = {
      caption: pictureData.caption,
      updating: true
    } as Picture;
    if (question.answer) {
      question.answer.pictures.push(tempPicture);
      requestObs = this.picturesService.createPicture({...pictureData, answerId: question.answer.id});
    } else {
      requestObs = this.answersService.createAnswer(this.assignment.id, {questionId: question.id}).pipe(
        tap((answer: Answer) => {
          answer.pictures.push(tempPicture);
          question.answer = answer;
        }),
        switchMap((answer: Answer) => this.picturesService.createPicture({...pictureData, answerId: answer.id}))
      );
    }

    requestObs.subscribe(
      (picture: Picture) => {
        Object.keys(picture).forEach((key: string) => tempPicture[key] = picture[key]);
        tempPicture.updating = false;
      }
    );
  }

  onChildPictureAdd(event: { pictureData: { caption: string, file: any }, question: Question, parentAnswer: Answer }) {
    const {parentAnswer, question, pictureData} = event;
    const existingAnswer = parentAnswer.childAnswers.find((childAnswer: Answer) => childAnswer.questionId === question.id);
    const tempPicture: Picture = {
      caption: pictureData.caption,
      updating: true
    } as Picture;
    let requestObs, pictureAnswer: Answer;
    if (existingAnswer) {
      requestObs = this.picturesService.createPicture({...pictureData, answerId: existingAnswer.id});
      pictureAnswer = existingAnswer;
    } else {
      requestObs = this.answersService.createAnswer(this.assignment.id, {parentAnswerId: parentAnswer.id, questionId: question.id}).pipe(
        tap((answer: Answer) => {
          parentAnswer.childAnswers.push(answer);
          pictureAnswer = answer;
        }),
        switchMap((answer: Answer) => this.picturesService.createPicture({...pictureData, answerId: answer.id}))
      );
    }

    pictureAnswer.pictures.push(tempPicture);
    requestObs.subscribe(
      (picture: Picture) => {
        Object.keys(picture).forEach((key: string) => tempPicture[key] = picture[key]);
        tempPicture.updating = false;
      }
    );
  }

  onPictureOpen(picture: Picture) {
    this.openPicture = {
      url: null,
      caption: picture.caption
    };
    this.picturesService.getPicture(picture.id).subscribe((blob: Blob) => {
      // The modal might've been closed meanwhile
      if (this.openPicture) {
        const url = URL.createObjectURL(blob);
        this.openPicture.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    });
  }

  onPictureClose() {
    if (this.openPicture) {
      URL.revokeObjectURL(this.openPicture.url);
    }
    this.openPicture = null;
  }

  onPictureDelete(event: { picture: Picture, answer: Answer }) {
    const {picture, answer} = event;
    this.picturesService.deletePicture(picture.id).subscribe(() => {
      answer.pictures.splice(answer.pictures.findIndex((pic: Picture) => picture.id === pic.id), 1);
    });
  }

  onPictureEdit(event: { picture: Picture, answer: Answer }) {
    const {picture, answer} = event;
    this.picturesService.updatePicture(picture).subscribe(() => {
      answer.pictures.find((pic: Picture) => picture.id === pic.id).caption = picture.caption;
    });
  }

  onAttachmentAdd(attachmentData: { file: any, requiredAttachmentId?: number }, question: Question) {
    let requestObs, attachmentAnswer: Answer, requiredAttachment: RequiredAttachment;
    if (attachmentData.requiredAttachmentId) {
      requiredAttachment = question.requiredAttachments
        .find((att: RequiredAttachment) => att.id === attachmentData.requiredAttachmentId);
      requiredAttachment.busy = true;
    }
    if (question.answer) {
      attachmentAnswer = question.answer;
      requestObs = this.attachmentsService.createAttachment({...attachmentData, answerId: question.answer.id});
    } else {
      requestObs = this.answersService.createAnswer(this.assignment.id, {questionId: question.id}).pipe(
        tap((answer: Answer) => {
          question.answer = answer;
          attachmentAnswer = answer;
        }),
        switchMap((answer: Answer) => this.attachmentsService.createAttachment({...attachmentData, answerId: answer.id}))
      );
    }

    requestObs.subscribe((attachment: Attachment) => {
        if (requiredAttachment) {
          requiredAttachment.attachment = attachment;
          requiredAttachment.busy = false;
        } else {
          attachmentAnswer.attachments.push(attachment);
        }
      }
    );
  }

  onAttachmentDelete(event: { attachment: Attachment, answer: Answer, requiredAttachment?: RequiredAttachment }) {
    const {attachment, answer, requiredAttachment} = event;
    if (requiredAttachment) {
      requiredAttachment.busy = true;
    }
    this.attachmentsService.deleteAttachment(attachment.id).subscribe(() => {
      if (requiredAttachment) {
        requiredAttachment.attachment = null;
        requiredAttachment.busy = false;
      } else {
        answer.attachments.splice(answer.attachments.findIndex((att: Attachment) => attachment.id === att.id), 1);
      }
    });
  }

  onAttachmentDownload(attachment: Attachment) {
    attachment.busy = true;
    this.attachmentsService.getAttachment(attachment.id).subscribe((res: HttpResponse<any>) => {
      const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
      this.utils.downloadFile(blob, filename);
      attachment.busy = false;
    });
  }

  onNotesChange(event) {
    this.assignment.assignmentNotes = event;
  }

  // Utility methods

  updateAnswerOptionIds(value, question: Question, parentAnswerId?: number) {
    if (question.questionType !== 'dropdown' && question.questionType !== 'checkboxes') {
      return;
    }

    this.answerOptions = this.answerOptions.filter(answerOption => answerOption.questionId !== question.id || (parentAnswerId && answerOption.parentAnswerId !== parentAnswerId));

    if (value != null) {
      if (question.questionType === 'dropdown') {
        this.answerOptions.push({
          questionId: question.id,
          answerOptionId: value,
          parentAnswerId
        });
      } else {
        value.forEach(answerOptionId => {
          this.answerOptions.push({
            questionId: question.id,
            answerOptionId: answerOptionId,
            parentAnswerId
          });
        });
      }
    }

    this.checkUnlockableQuestions();
  }

  checkUnlockableQuestions() {
    const answerOptionIds = this.answerOptions.map(answerOption => answerOption.answerOptionId);
    this.subsections.forEach((subsection: Subsection) => {
      subsection.questions.forEach((question: Question) => {
        if (question.childQuestions) question.childQuestions
          .filter((childQuestion: Question) => childQuestion.unlockedByIds.length > 0)
          .forEach((childQuestion: Question) => {
            let answer = [].concat(...question.groupAnswers
              .filter(q => childQuestion.unlockedByParentAnswerIds && childQuestion.unlockedByParentAnswerIds.indexOf(q.id) >= 0)
              .map(q => q.childAnswers))
              .find(q => q.questionId === childQuestion.id);
            const childWasUnlocked = childQuestion.unlockedByParentAnswerIds && answer && childQuestion.unlockedByParentAnswerIds.includes(answer.parentAnswerId);
            childQuestion.unlockedByParentAnswerIds = this.answerOptions.filter(el => childQuestion.unlockedByIds.indexOf(el.answerOptionId) >= 0).map(p => p.parentAnswerId);
            answer = [].concat(...question.groupAnswers
              .map(q => q.childAnswers))
              .find(q => q.questionId === childQuestion.id);
            if (childQuestion.unlockedByParentAnswerIds && childWasUnlocked && answer && !childQuestion.unlockedByParentAnswerIds.includes(answer.parentAnswerId)) {
              let deleteObs;
              if (this.assignment.status === 'inspection') {
                deleteObs = this.idbWrapperService.delete('answers', answer.id);
              } else {
                deleteObs = this.answersService.deleteAnswer(answer.id);
              }
              deleteObs.subscribe(() => {
                question.groupAnswers.forEach(groupAnswer => groupAnswer.childAnswers = groupAnswer.childAnswers.filter(a => a.id !== answer.id));
              });
            }
          });
      });
      subsection.questions
        .filter((question: Question) => question.unlockedByIds.length > 0)
        .forEach((question: Question) => {
          const wasUnlocked = question.unlocked;
          question.unlocked = answerOptionIds.some(item => question.unlockedByIds.indexOf(item) >= 0);
          if (wasUnlocked && !question.unlocked && question.answer) {
            let deleteObs;
            if (this.assignment.status === 'inspection') {
              deleteObs = this.idbWrapperService.delete('answers', question.answer.id);
            } else {
              deleteObs = this.answersService.deleteAnswer(question.answer.id);
            }
            deleteObs.subscribe(() => {
              question.answer = undefined;
              this.updateAnswerOptionIds(null, question);
            });
          }
        });
    });
  }

  highlightSubsection(subsectionId: number) {
    if (document.getElementsByClassName('side-menu__child-links').length > 0) {
      this.subsections.forEach(subsection => {
        const el = document.getElementById(`subsection-menu-${subsection.id}`);
        if (el) el.classList.remove('active', 'active--bold');
      });
      document.getElementById(`subsection-menu-${subsectionId}`).classList.add('active', 'active--bold');
    }
  }

  mapFilterAndUpdateAnswerOptionIds(answers, questions) {
    answers
      .map((answer: Answer) => [answer, questions.find(question => question.id === answer.questionId)])
      .filter(([answer, question]: [Answer, Question]) =>
        answer.assignmentId === this.assignment.id &&
        (question.questionType === 'dropdown' || question.questionType === 'checkboxes')
      )
      .forEach(([answer, question]: [Answer, Question]) => {
        this.updateAnswerOptionIds(answer.value, question, answer.parentAnswerId);
      });
  }

  questionTrackBy(index, item: Question) {
    return item.id;
  }

  subsectionTrackBy(index, item: Subsection) {
    return item.id;
  }

  setSaving() {
    this.savingSubject$.next({saving: true});
  }

  setSaved() {
    this.savingSubject$.next({lastSaved: new Date()});
  }

  destroyWatchers() {
    if (this.containerMonitor) {
      this.containerMonitor.watchers.forEach(watcher => watcher.destroy());
      this.containerMonitor.destroy();
    }
  }

  // Lifecycle hooks

  ngOnInit() {
    let id, sectionId;
    this.activatedRouteSubscription = this.activatedRoute.params.pipe(
      tap(res => {
        if (id !== +res.id) {
          this.savingSubject$.next({});
        }
        id = +res.id;
        sectionId = +res.sectionId;
        this.currentSection = null;
      }),
      switchMap(() => this.auditRefresh.auditRefresh$),
      switchMap(() => this.netStatus.onlineStatus),
      switchMap((isOnline: boolean) => this.assignmentsService.getAssignment(isOnline, id)),
      tap(assignment => {
        this.animate = false;
        this.watchersInitialized = false;
        this.subsections = [];

        this.destroyWatchers();
        this.sideMenuService.sideMenuState = {state: 'sections', assignment, currentSubRoute: sectionId};
        this.assignment = assignment;
        if (!this.assignment.offline) {
          this.idbWrapperService.add('assignments', this.assignment).subscribe(
            res => {
              this.assignment.offline = true;
            }, error => {
              console.error(error.message);
            });
        }
      }),
      switchMap(() => combineLatest([
        this.idbWrapperService.all('sections'),
        this.idbWrapperService.all('subsections', 'sort'),
        this.idbWrapperService.all('questions', 'sort'),
        this.assignment.status === 'inspection' ? this.idbWrapperService.all('answers') : this.answersService.getAnswers(this.assignment.id),
        this.assignment.status === 'inspection' ? this.idbWrapperService.all('requiredAttachments') : this.requiredAttachmentsService.getRequiredAttachments(this.assignment.id)
      ]))
    ).subscribe(
      ([sections, subsections, questions, answers, requiredAttachments]: [Section[], Subsection[], Question[], Answer[], RequiredAttachment[]]) => {
        this.currentSection = sections.find((section: Section) => section.id === sectionId);
        // Assign a fake id to the offline required attachments which is useful for deleting them
        this.assignment.requiredAttachments.forEach((requiredAttachment: RequiredAttachment, index: number) => requiredAttachment.id = index);
        /*
         Filter the subsections, excluding those that don't belong to the current section and to the assignment factory type,
         as well as those without any question
        */
        const newSubsections = subsections.filter(subsection =>
          subsection.sectionId === sectionId &&
          subsection.factoryTypeIds.includes(this.assignment.factoryTypeId) &&
          questions
            .filter(question => question.factoryTypeIds.includes(this.assignment.factoryTypeId))
            .map(question => question.subsectionId)
            .includes(subsection.id)
        );

        // Restructure the answer array
        answers
          .filter((answer: Answer) => !!answer.parentAnswerId)
          .forEach((childAnswer: Answer) => {
            const parentAnswer = answers.find(answer => answer.id === childAnswer.parentAnswerId);

            if (!parentAnswer.childAnswers) {
              parentAnswer.childAnswers = [];
            }
            parentAnswer.childAnswers.push(childAnswer);
          });
        // Remove the child answers
        answers = answers.filter((answer: Answer) => !answer.parentAnswerId);

        // Restructure the question array and assign them their answers
        questions.forEach((question: Question) => {
          question.questionText = (
            question.questionTexts.find(text => text.factoryTypeId === this.assignment.factoryTypeId) ||
            question.questionTexts.find(text => text.factoryTypeId === null)
          ).text;
          question.templateMappingRule = (
            question.templateMappingRules.find(rule => rule.factoryTypeId === this.assignment.factoryTypeId) ||
            question.templateMappingRules.find(rule => rule.factoryTypeId === null)
          );
          question.requiredAttachments = [
            ...requiredAttachments.filter(
              (requiredAttachment: RequiredAttachment) => requiredAttachment.questionId === question.id
            ),
            ...this.assignment.requiredAttachments.filter(
              (requiredAttachment: RequiredAttachment) => requiredAttachment.questionId === question.id
            )
          ] as RequiredAttachment[];
        });

        questions
          .filter((question: Question) => !!question.parentQuestionId)
          .forEach((childQuestion: Question) => {
            const parentQuestion = questions.find(question => question.id === childQuestion.parentQuestionId);

            if (!parentQuestion.childQuestions) {
              parentQuestion.childQuestions = [];
            }
            parentQuestion.childQuestions.push(childQuestion);
          });
        // Remove the child questions
        questions = questions.filter((question: Question) => !question.parentQuestionId);

        questions.forEach((question: Question) => {
          if (question.questionType !== 'group_question') {
            const answer = answers.find((ans: Answer) =>
              ans.questionId === question.id &&
              ans.assignmentId === this.assignment.id
            );
            if (answer) {
              question.answer = answer;
            }
          } else {
            question.groupAnswers = answers.filter((answer: Answer) =>
              answer.questionId === question.id &&
              answer.assignmentId === this.assignment.id
            );
          }
        });

        // Attach questions to their subsections
        newSubsections.forEach((subsection: Subsection) => {
          subsection.questions = questions.filter(question =>
            question.subsectionId === subsection.id &&
            question.factoryTypeIds.includes(this.assignment.factoryTypeId)
          );
        });

        this.subsections = newSubsections;
        if (this.assignment.status === 'inspection') {
          this.subsections = this.subsections.filter(
            subsection => !!subsection.questions.filter(
              question => !question.processingOnly
            ).length
          );
        }

        this.answerOptions = [];

        this.mapFilterAndUpdateAnswerOptionIds(answers, questions);

        const childQuestions = [];
        questions.forEach(question => question.childQuestions && childQuestions.push(...question.childQuestions));
        answers.forEach(a => a.childAnswers && this.mapFilterAndUpdateAnswerOptionIds(a.childAnswers, childQuestions));

        setTimeout(() => {
          this.animate = true;

          if (this.subsections.length && document.getElementById('router-wrapper') && !this.watchersInitialized) {
            this.containerMonitor = scrollMonitor.createContainer(document.getElementById('router-wrapper'));
            this.subsections.forEach((subsection, index) => {
              if (index === 0) {
                this.highlightSubsection(subsection.id);
              }

              const subsectionWatcher = this.containerMonitor.create(document.getElementById(`subsection-${subsection.id}`));
              subsectionWatcher.enterViewport(() => {
                if (subsectionWatcher.isAboveViewport) {
                  this.highlightSubsection(subsection.id);
                }
              });
              subsectionWatcher.exitViewport(() => {
                if (subsectionWatcher.isAboveViewport) {
                  this.highlightSubsection(this.subsections[index + 1].id);
                }
              });
            });

            this.watchersInitialized = true;
          }
        });
      },
      () => {
        this.toaster.displayError('Errore nell\'apertura dell\'incarico.');
        this.router.navigate(['/dashboard']);
      }
    );
  }

  ngOnDestroy() {
    this.destroyWatchers();
    this.activatedRouteSubscription.unsubscribe();
  }
}
