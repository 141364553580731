import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var NetStatusService = /** @class */ (function () {
    function NetStatusService() {
        this._isOnline = navigator.onLine;
        this._onlineStatusSource = new BehaviorSubject(this._isOnline);
        this.onlineStatus = this._onlineStatusSource.asObservable();
    }
    NetStatusService.prototype.isOnline = function () {
        return this._isOnline;
    };
    NetStatusService.prototype.setOnline = function () {
        this._isOnline = true;
        this._onlineStatusSource.next(true);
    };
    NetStatusService.prototype.setOffline = function () {
        this._isOnline = false;
        this._onlineStatusSource.next(false);
    };
    NetStatusService.ngInjectableDef = i0.defineInjectable({ factory: function NetStatusService_Factory() { return new NetStatusService(); }, token: NetStatusService, providedIn: "root" });
    return NetStatusService;
}());
export { NetStatusService };
