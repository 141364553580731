/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./risk-scores.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./risk-score-row/risk-score-row.component.ngfactory";
import * as i3 from "./risk-score-row/risk-score-row.component";
import * as i4 from "@angular/common";
import * as i5 from "../../shared/notes/notes.component.ngfactory";
import * as i6 from "../../shared/notes/notes.component";
import * as i7 from "@angular/material/bottom-sheet";
import * as i8 from "../../core/models/assignment/assignments.service";
import * as i9 from "../../core/idb-wrapper/idb-wrapper.service";
import * as i10 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "../../shared/last-saved/last-saved.component.ngfactory";
import * as i16 from "../../shared/last-saved/last-saved.component";
import * as i17 from "./risk-scores.component";
import * as i18 from "@angular/router";
import * as i19 from "../../core/models/risk-score/risk-scores.service";
import * as i20 from "../../core/utils/utils.service";
import * as i21 from "../../core/side-bar/side-menu/side-menu.service";
import * as i22 from "../../core/user/user.service";
import * as i23 from "../../core/toaster/toaster.service";
import * as i24 from "../../core/spinner/spinner.service";
import * as i25 from "../../core/dialog/dialog.service";
var styles_RiskScoresComponent = [i0.styles];
var RenderType_RiskScoresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RiskScoresComponent, data: {} });
export { RenderType_RiskScoresComponent as RenderType_RiskScoresComponent };
function View_RiskScoresComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "risk-scores__header__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_RiskScoresComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "risk-scores__scores-table__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "risk-score-row", [], null, [[null, "scoreMappingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scoreMappingChange" === en)) {
        var pd_0 = ((_co.scoreMappings[_v.context.index] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("scoreMappingChange" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_RiskScoreRowComponent_0, i2.RenderType_RiskScoreRowComponent)), i1.ɵdid(2, 114688, null, 0, i3.RiskScoreRowComponent, [], { scoreMapping: [0, "scoreMapping"], viewMode: [1, "viewMode"] }, { scoreMappingChange: "scoreMappingChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scoreMappings[_v.context.index]; var currVal_1 = _co.getViewMode(); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_RiskScoresComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "risk-scores__scores-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "risk-scores__scores-table__caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "risk-scores__scores-table__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "risk-scores__scores-table__heading__cell target"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bersaglio"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "risk-scores__scores-table__heading__cell score"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Probabilit\u00E0"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "risk-scores__scores-table__heading__cell score"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Magnitudo"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "risk-scores__scores-table__heading__cell notes"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Note"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RiskScoresComponent_3)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.scoreMappings; _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentSource == null) ? null : _co.currentSource.name); _ck(_v, 2, 0, currVal_0); }); }
function View_RiskScoresComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notes", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onNotesChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NotesComponent_0, i5.RenderType_NotesComponent)), i1.ɵdid(1, 114688, null, 0, i6.NotesComponent, [i7.MatBottomSheet, i8.AssignmentsService, i9.IdbWrapperService], { assignment: [0, "assignment"], viewMode: [1, "viewMode"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assignment; var currVal_1 = _co.getViewMode(); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RiskScoresComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "risk-scores"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "risk-scores__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "risk-scores__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Punteggi di rischio"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "assignment__header__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "risk-scores__header__button sebastian-button sebastian-button--primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onComputeScores() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Calcola punteggi "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RiskScoresComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RiskScoresComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "last-saved", [], null, null, null, i15.View_LastSavedComponent_0, i15.RenderType_LastSavedComponent)), i1.ɵdid(13, 114688, null, 0, i16.LastSavedComponent, [], { saveState: [0, "saveState"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RiskScoresComponent_4)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.saving$)); _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.assignment; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).disabled || null); var currVal_1 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_RiskScoresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "risk-scores", [], null, null, null, View_RiskScoresComponent_0, RenderType_RiskScoresComponent)), i1.ɵdid(1, 245760, null, 0, i17.RiskScoresComponent, [i18.ActivatedRoute, i8.AssignmentsService, i19.RiskScoresService, i9.IdbWrapperService, i20.UtilsService, i21.SideMenuService, i22.UserService, i23.ToasterService, i24.SpinnerService, i25.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RiskScoresComponentNgFactory = i1.ɵccf("risk-scores", i17.RiskScoresComponent, View_RiskScoresComponent_Host_0, {}, {}, []);
export { RiskScoresComponentNgFactory as RiskScoresComponentNgFactory };
