import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { Subsection }           from './subsection';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SubsectionsService {

  constructor(private http: ApiHttpClientService) { }

  getSubsections(): Observable<Subsection[]> {
    return this.http.get('subsections').pipe(
      map((res: any) => res.subsections.map(subsection => new Subsection(subsection)))
    );
  }
}
