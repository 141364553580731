var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { SideMenuService } from '../../core/side-bar/side-menu/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { NetStatusService } from '../../core/net-status/net-status.service';
import { AssignmentsService } from '../../core/models/assignment/assignments.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ToasterService } from '../../core/toaster/toaster.service';
import { AnswersService } from '../../core/models/answer/answers.service';
import { AuditRefreshService } from './audit-refresh.service';
import { UserService } from '../../core/user/user.service';
import * as scrollMonitor from 'scrollmonitor';
import { RequiredAttachmentService } from '../../core/models/required-attachment/required-attachment.service';
import { PicturesService } from '../../core/models/picture/pictures.service';
import { AttachmentsService } from '../../core/models/attachment/attachments.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../core/utils/utils.service';
/*
  Please don't judge all the spaghetti code you'll find here too harshly. Deadlines fucking suck
 */
var AuditComponent = /** @class */ (function () {
    function AuditComponent(sideMenuService, activatedRoute, netStatus, assignmentsService, idbWrapperService, answersService, toaster, router, auditRefresh, userService, requiredAttachmentsService, picturesService, attachmentsService, sanitizer, utils) {
        this.sideMenuService = sideMenuService;
        this.activatedRoute = activatedRoute;
        this.netStatus = netStatus;
        this.assignmentsService = assignmentsService;
        this.idbWrapperService = idbWrapperService;
        this.answersService = answersService;
        this.toaster = toaster;
        this.router = router;
        this.auditRefresh = auditRefresh;
        this.userService = userService;
        this.requiredAttachmentsService = requiredAttachmentsService;
        this.picturesService = picturesService;
        this.attachmentsService = attachmentsService;
        this.sanitizer = sanitizer;
        this.utils = utils;
        this.subsections = [];
        this.animate = false;
        this.watchersInitialized = false;
        this.savingSubject$ = new BehaviorSubject({});
        this.saving$ = this.savingSubject$.asObservable();
    }
    // Event handlers
    AuditComponent.prototype.onQuestionChange = function (event) {
        var _this = this;
        var changes = event.changes, question = event.question;
        changes.assignmentId = this.assignment.id;
        this.setSaving();
        if (this.assignment.status === 'inspection') {
            this.idbWrapperService.all('answers').subscribe(function (answers) {
                var index = answers.findIndex(function (el) {
                    return el.questionId === changes.questionId &&
                        el.assignmentId === changes.assignmentId;
                });
                if (index === -1) {
                    _this.idbWrapperService.add('answers', changes);
                }
                else {
                    _this.idbWrapperService.update('answers', answers[index].id, changes);
                }
                _this.setSaved();
            });
        }
        else {
            var answerObs = void 0;
            if (question.answer) {
                answerObs = this.answersService.updateAnswer(question.answer.id, changes);
            }
            else {
                answerObs = this.answersService.createAnswer(this.assignment.id, changes);
            }
            answerObs.subscribe(function (answer) {
                var questionToUpdate = _this.subsections
                    .map(function (subsection) { return subsection.questions; })
                    .reduce(function (questions, subsectionQuestions) { return questions.concat(subsectionQuestions); }, [])
                    .find(function (q) { return q.id === question.id; });
                if (questionToUpdate.answer) {
                    questionToUpdate.answer.value = answer.value;
                    questionToUpdate.answer.freeNotes = answer.freeNotes;
                }
                else {
                    questionToUpdate.answer = answer;
                }
                _this.setSaved();
            });
        }
        this.updateAnswerOptionIds(changes.value, question);
    };
    AuditComponent.prototype.onChildQuestionChange = function (event) {
        var _this = this;
        var changes = event.changes, parentAnswer = event.parentAnswer;
        changes.assignmentId = this.assignment.id;
        this.savingSubject$.next({ saving: true });
        if (this.assignment.status === 'inspection') {
            this.idbWrapperService.all('answers').subscribe(function (answers) {
                var index = answers.findIndex(function (el) {
                    return el.questionId === changes.questionId &&
                        el.assignmentId === changes.assignmentId &&
                        el.parentAnswerId === changes.parentAnswerId;
                });
                if (index === -1) {
                    _this.idbWrapperService.add('answers', changes).pipe(switchMap(function (newAnswerId) { return _this.idbWrapperService.get('answers', newAnswerId); })).subscribe(function (newAnswer) {
                        if (!parentAnswer.childAnswers) {
                            parentAnswer.childAnswers = [];
                        }
                        parentAnswer.childAnswers.push(newAnswer);
                        _this.setSaved();
                    });
                }
                else {
                    _this.idbWrapperService.update('answers', answers[index].id, changes);
                    var childAnswer = parentAnswer.childAnswers.find(function (ans) {
                        return ans.questionId === changes.questionId &&
                            ans.assignmentId === changes.assignmentId &&
                            ans.parentAnswerId === changes.parentAnswerId;
                    });
                    childAnswer.freeNotes = changes.freeNotes;
                    childAnswer.value = changes.value;
                    _this.setSaved();
                }
            });
        }
        else {
            var childAnswer_1 = parentAnswer.childAnswers.find(function (ans) {
                return ans.questionId === changes.questionId &&
                    ans.assignmentId === changes.assignmentId &&
                    ans.parentAnswerId === changes.parentAnswerId;
            });
            if (childAnswer_1) {
                this.answersService.updateAnswer(childAnswer_1.id, changes).subscribe(function (answer) {
                    childAnswer_1.id = answer.id;
                    childAnswer_1.freeNotes = changes.freeNotes;
                    childAnswer_1.value = changes.value;
                    _this.setSaved();
                });
            }
            else {
                this.answersService.createAnswer(this.assignment.id, changes).subscribe(function (answer) {
                    parentAnswer.childAnswers.push(answer);
                    _this.setSaved();
                });
            }
        }
        this.idbWrapperService.get('questions', changes.questionId).subscribe(function (question) {
            _this.updateAnswerOptionIds(changes.value, question, parentAnswer.id);
        });
    };
    AuditComponent.prototype.onParentAnswerCreate = function (question, answer) {
        if (this.assignment.status === 'inspection') {
            this.idbWrapperService.add('answers', { questionId: answer.questionId, assignmentId: this.assignment.id }).subscribe(function (answerId) {
                answer.id = answerId;
            });
        }
        else {
            this.answersService.createAnswer(this.assignment.id, answer).subscribe(function (createdAnswer) {
                answer.id = createdAnswer.id;
            });
        }
    };
    AuditComponent.prototype.onParentAnswerDelete = function (question, answer) {
        var _this = this;
        if (this.assignment.status === 'inspection') {
            combineLatest([
                this.idbWrapperService.delete('answers', answer.id)
            ].concat(answer.childAnswers.map(function (childAnswer) { return _this.idbWrapperService.delete('answers', childAnswer.id); }))).subscribe(function () {
                question.groupAnswers.splice(question.groupAnswers.findIndex(function (ans) { return answer.id === ans.id; }), 1);
            });
        }
        else {
            combineLatest([
                this.answersService.deleteAnswer(answer.id)
            ].concat(answer.childAnswers.map(function (childAnswer) { return _this.answersService.deleteAnswer(childAnswer.id); }))).subscribe(function () {
                question.groupAnswers.splice(question.groupAnswers.findIndex(function (ans) { return answer.id === ans.id; }), 1);
            });
        }
    };
    AuditComponent.prototype.onRequiredAttachmentAdd = function (description, question) {
        var _this = this;
        var requiredAttachment = {
            questionId: question.id,
            assignmentId: this.assignment.id,
            description: description
        };
        var requestObs;
        if (this.assignment.status === 'inspection') {
            requestObs = this.idbWrapperService.update('assignments', this.assignment.id, { requiredAttachments: this.assignment.requiredAttachments.concat([requiredAttachment]) }).pipe(mapTo(__assign({}, requiredAttachment, { id: this.assignment.requiredAttachments.length + 1 })));
        }
        else {
            requestObs = this.requiredAttachmentsService.createRequiredAttachment(requiredAttachment);
        }
        requestObs.subscribe(function (res) {
            question.requiredAttachments.push(res);
            _this.assignment.requiredAttachments.push(res);
        });
    };
    AuditComponent.prototype.onRequiredAttachmentEdit = function (event) {
        var _this = this;
        var requiredAttachment = event.requiredAttachment, description = event.description, newRA = __assign({}, requiredAttachment, { description: description }), assignmentRAs = this.assignment.requiredAttachments.slice();
        var requestObs;
        assignmentRAs.forEach(function (reqAtt) {
            if (reqAtt.id === requiredAttachment.id) {
                reqAtt.description = description;
            }
        });
        if (this.assignment.status === 'inspection') {
            requestObs = this.idbWrapperService.update('assignments', this.assignment.id, { requiredAttachments: assignmentRAs });
        }
        else {
            requestObs = this.requiredAttachmentsService.updateRequiredAttachment(newRA);
        }
        requestObs.subscribe(function () {
            requiredAttachment.description = description;
            _this.assignment.requiredAttachments = assignmentRAs;
        });
    };
    AuditComponent.prototype.onRequiredAttachmentDelete = function (event) {
        var _this = this;
        var requiredAttachment = event.requiredAttachment, question = event.question, assignmentRAs = this.assignment.requiredAttachments.slice();
        var requestObs;
        assignmentRAs.splice(assignmentRAs.findIndex(function (reqAtt) {
            return reqAtt.id === requiredAttachment.id && !!reqAtt.assignmentId;
        }), 1);
        if (this.assignment.status === 'inspection') {
            requestObs = this.idbWrapperService.update('assignments', this.assignment.id, { requiredAttachments: assignmentRAs });
        }
        else {
            requestObs = this.requiredAttachmentsService.deleteRequiredAttachment(requiredAttachment);
        }
        requestObs.subscribe(function () {
            question.requiredAttachments.splice(question.requiredAttachments.findIndex(function (reqAtt) {
                return reqAtt.id === requiredAttachment.id && !!reqAtt.assignmentId;
            }), 1);
            _this.assignment.requiredAttachments = assignmentRAs;
        });
    };
    AuditComponent.prototype.onPictureAdd = function (pictureData, question) {
        var _this = this;
        var requestObs;
        var tempPicture = {
            caption: pictureData.caption,
            updating: true
        };
        if (question.answer) {
            question.answer.pictures.push(tempPicture);
            requestObs = this.picturesService.createPicture(__assign({}, pictureData, { answerId: question.answer.id }));
        }
        else {
            requestObs = this.answersService.createAnswer(this.assignment.id, { questionId: question.id }).pipe(tap(function (answer) {
                answer.pictures.push(tempPicture);
                question.answer = answer;
            }), switchMap(function (answer) { return _this.picturesService.createPicture(__assign({}, pictureData, { answerId: answer.id })); }));
        }
        requestObs.subscribe(function (picture) {
            Object.keys(picture).forEach(function (key) { return tempPicture[key] = picture[key]; });
            tempPicture.updating = false;
        });
    };
    AuditComponent.prototype.onChildPictureAdd = function (event) {
        var _this = this;
        var parentAnswer = event.parentAnswer, question = event.question, pictureData = event.pictureData;
        var existingAnswer = parentAnswer.childAnswers.find(function (childAnswer) { return childAnswer.questionId === question.id; });
        var tempPicture = {
            caption: pictureData.caption,
            updating: true
        };
        var requestObs, pictureAnswer;
        if (existingAnswer) {
            requestObs = this.picturesService.createPicture(__assign({}, pictureData, { answerId: existingAnswer.id }));
            pictureAnswer = existingAnswer;
        }
        else {
            requestObs = this.answersService.createAnswer(this.assignment.id, { parentAnswerId: parentAnswer.id, questionId: question.id }).pipe(tap(function (answer) {
                parentAnswer.childAnswers.push(answer);
                pictureAnswer = answer;
            }), switchMap(function (answer) { return _this.picturesService.createPicture(__assign({}, pictureData, { answerId: answer.id })); }));
        }
        pictureAnswer.pictures.push(tempPicture);
        requestObs.subscribe(function (picture) {
            Object.keys(picture).forEach(function (key) { return tempPicture[key] = picture[key]; });
            tempPicture.updating = false;
        });
    };
    AuditComponent.prototype.onPictureOpen = function (picture) {
        var _this = this;
        this.openPicture = {
            url: null,
            caption: picture.caption
        };
        this.picturesService.getPicture(picture.id).subscribe(function (blob) {
            // The modal might've been closed meanwhile
            if (_this.openPicture) {
                var url = URL.createObjectURL(blob);
                _this.openPicture.url = _this.sanitizer.bypassSecurityTrustResourceUrl(url);
            }
        });
    };
    AuditComponent.prototype.onPictureClose = function () {
        if (this.openPicture) {
            URL.revokeObjectURL(this.openPicture.url);
        }
        this.openPicture = null;
    };
    AuditComponent.prototype.onPictureDelete = function (event) {
        var picture = event.picture, answer = event.answer;
        this.picturesService.deletePicture(picture.id).subscribe(function () {
            answer.pictures.splice(answer.pictures.findIndex(function (pic) { return picture.id === pic.id; }), 1);
        });
    };
    AuditComponent.prototype.onPictureEdit = function (event) {
        var picture = event.picture, answer = event.answer;
        this.picturesService.updatePicture(picture).subscribe(function () {
            answer.pictures.find(function (pic) { return picture.id === pic.id; }).caption = picture.caption;
        });
    };
    AuditComponent.prototype.onAttachmentAdd = function (attachmentData, question) {
        var _this = this;
        var requestObs, attachmentAnswer, requiredAttachment;
        if (attachmentData.requiredAttachmentId) {
            requiredAttachment = question.requiredAttachments
                .find(function (att) { return att.id === attachmentData.requiredAttachmentId; });
            requiredAttachment.busy = true;
        }
        if (question.answer) {
            attachmentAnswer = question.answer;
            requestObs = this.attachmentsService.createAttachment(__assign({}, attachmentData, { answerId: question.answer.id }));
        }
        else {
            requestObs = this.answersService.createAnswer(this.assignment.id, { questionId: question.id }).pipe(tap(function (answer) {
                question.answer = answer;
                attachmentAnswer = answer;
            }), switchMap(function (answer) { return _this.attachmentsService.createAttachment(__assign({}, attachmentData, { answerId: answer.id })); }));
        }
        requestObs.subscribe(function (attachment) {
            if (requiredAttachment) {
                requiredAttachment.attachment = attachment;
                requiredAttachment.busy = false;
            }
            else {
                attachmentAnswer.attachments.push(attachment);
            }
        });
    };
    AuditComponent.prototype.onAttachmentDelete = function (event) {
        var attachment = event.attachment, answer = event.answer, requiredAttachment = event.requiredAttachment;
        if (requiredAttachment) {
            requiredAttachment.busy = true;
        }
        this.attachmentsService.deleteAttachment(attachment.id).subscribe(function () {
            if (requiredAttachment) {
                requiredAttachment.attachment = null;
                requiredAttachment.busy = false;
            }
            else {
                answer.attachments.splice(answer.attachments.findIndex(function (att) { return attachment.id === att.id; }), 1);
            }
        });
    };
    AuditComponent.prototype.onAttachmentDownload = function (attachment) {
        var _this = this;
        attachment.busy = true;
        this.attachmentsService.getAttachment(attachment.id).subscribe(function (res) {
            var _a = _this.utils.blobAndFilenameFromRes(res), blob = _a.blob, filename = _a.filename;
            _this.utils.downloadFile(blob, filename);
            attachment.busy = false;
        });
    };
    AuditComponent.prototype.onNotesChange = function (event) {
        this.assignment.assignmentNotes = event;
    };
    // Utility methods
    AuditComponent.prototype.updateAnswerOptionIds = function (value, question, parentAnswerId) {
        var _this = this;
        if (question.questionType !== 'dropdown' && question.questionType !== 'checkboxes') {
            return;
        }
        this.answerOptions = this.answerOptions.filter(function (answerOption) { return answerOption.questionId !== question.id || (parentAnswerId && answerOption.parentAnswerId !== parentAnswerId); });
        if (value != null) {
            if (question.questionType === 'dropdown') {
                this.answerOptions.push({
                    questionId: question.id,
                    answerOptionId: value,
                    parentAnswerId: parentAnswerId
                });
            }
            else {
                value.forEach(function (answerOptionId) {
                    _this.answerOptions.push({
                        questionId: question.id,
                        answerOptionId: answerOptionId,
                        parentAnswerId: parentAnswerId
                    });
                });
            }
        }
        this.checkUnlockableQuestions();
    };
    AuditComponent.prototype.checkUnlockableQuestions = function () {
        var _this = this;
        var answerOptionIds = this.answerOptions.map(function (answerOption) { return answerOption.answerOptionId; });
        this.subsections.forEach(function (subsection) {
            subsection.questions.forEach(function (question) {
                if (question.childQuestions)
                    question.childQuestions
                        .filter(function (childQuestion) { return childQuestion.unlockedByIds.length > 0; })
                        .forEach(function (childQuestion) {
                        var answer = [].concat.apply([], question.groupAnswers
                            .filter(function (q) { return childQuestion.unlockedByParentAnswerIds && childQuestion.unlockedByParentAnswerIds.indexOf(q.id) >= 0; })
                            .map(function (q) { return q.childAnswers; })).find(function (q) { return q.questionId === childQuestion.id; });
                        var childWasUnlocked = childQuestion.unlockedByParentAnswerIds && answer && childQuestion.unlockedByParentAnswerIds.includes(answer.parentAnswerId);
                        childQuestion.unlockedByParentAnswerIds = _this.answerOptions.filter(function (el) { return childQuestion.unlockedByIds.indexOf(el.answerOptionId) >= 0; }).map(function (p) { return p.parentAnswerId; });
                        answer = [].concat.apply([], question.groupAnswers
                            .map(function (q) { return q.childAnswers; })).find(function (q) { return q.questionId === childQuestion.id; });
                        if (childQuestion.unlockedByParentAnswerIds && childWasUnlocked && answer && !childQuestion.unlockedByParentAnswerIds.includes(answer.parentAnswerId)) {
                            var deleteObs = void 0;
                            if (_this.assignment.status === 'inspection') {
                                deleteObs = _this.idbWrapperService.delete('answers', answer.id);
                            }
                            else {
                                deleteObs = _this.answersService.deleteAnswer(answer.id);
                            }
                            deleteObs.subscribe(function () {
                                question.groupAnswers.forEach(function (groupAnswer) { return groupAnswer.childAnswers = groupAnswer.childAnswers.filter(function (a) { return a.id !== answer.id; }); });
                            });
                        }
                    });
            });
            subsection.questions
                .filter(function (question) { return question.unlockedByIds.length > 0; })
                .forEach(function (question) {
                var wasUnlocked = question.unlocked;
                question.unlocked = answerOptionIds.some(function (item) { return question.unlockedByIds.indexOf(item) >= 0; });
                if (wasUnlocked && !question.unlocked && question.answer) {
                    var deleteObs = void 0;
                    if (_this.assignment.status === 'inspection') {
                        deleteObs = _this.idbWrapperService.delete('answers', question.answer.id);
                    }
                    else {
                        deleteObs = _this.answersService.deleteAnswer(question.answer.id);
                    }
                    deleteObs.subscribe(function () {
                        question.answer = undefined;
                        _this.updateAnswerOptionIds(null, question);
                    });
                }
            });
        });
    };
    AuditComponent.prototype.highlightSubsection = function (subsectionId) {
        if (document.getElementsByClassName('side-menu__child-links').length > 0) {
            this.subsections.forEach(function (subsection) {
                var el = document.getElementById("subsection-menu-" + subsection.id);
                if (el)
                    el.classList.remove('active', 'active--bold');
            });
            document.getElementById("subsection-menu-" + subsectionId).classList.add('active', 'active--bold');
        }
    };
    AuditComponent.prototype.mapFilterAndUpdateAnswerOptionIds = function (answers, questions) {
        var _this = this;
        answers
            .map(function (answer) { return [answer, questions.find(function (question) { return question.id === answer.questionId; })]; })
            .filter(function (_a) {
            var answer = _a[0], question = _a[1];
            return answer.assignmentId === _this.assignment.id &&
                (question.questionType === 'dropdown' || question.questionType === 'checkboxes');
        })
            .forEach(function (_a) {
            var answer = _a[0], question = _a[1];
            _this.updateAnswerOptionIds(answer.value, question, answer.parentAnswerId);
        });
    };
    AuditComponent.prototype.questionTrackBy = function (index, item) {
        return item.id;
    };
    AuditComponent.prototype.subsectionTrackBy = function (index, item) {
        return item.id;
    };
    AuditComponent.prototype.setSaving = function () {
        this.savingSubject$.next({ saving: true });
    };
    AuditComponent.prototype.setSaved = function () {
        this.savingSubject$.next({ lastSaved: new Date() });
    };
    AuditComponent.prototype.destroyWatchers = function () {
        if (this.containerMonitor) {
            this.containerMonitor.watchers.forEach(function (watcher) { return watcher.destroy(); });
            this.containerMonitor.destroy();
        }
    };
    // Lifecycle hooks
    AuditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id, sectionId;
        this.activatedRouteSubscription = this.activatedRoute.params.pipe(tap(function (res) {
            if (id !== +res.id) {
                _this.savingSubject$.next({});
            }
            id = +res.id;
            sectionId = +res.sectionId;
            _this.currentSection = null;
        }), switchMap(function () { return _this.auditRefresh.auditRefresh$; }), switchMap(function () { return _this.netStatus.onlineStatus; }), switchMap(function (isOnline) { return _this.assignmentsService.getAssignment(isOnline, id); }), tap(function (assignment) {
            _this.animate = false;
            _this.watchersInitialized = false;
            _this.subsections = [];
            _this.destroyWatchers();
            _this.sideMenuService.sideMenuState = { state: 'sections', assignment: assignment, currentSubRoute: sectionId };
            _this.assignment = assignment;
            if (!_this.assignment.offline) {
                _this.idbWrapperService.add('assignments', _this.assignment).subscribe(function (res) {
                    _this.assignment.offline = true;
                }, function (error) {
                    console.error(error.message);
                });
            }
        }), switchMap(function () { return combineLatest([
            _this.idbWrapperService.all('sections'),
            _this.idbWrapperService.all('subsections', 'sort'),
            _this.idbWrapperService.all('questions', 'sort'),
            _this.assignment.status === 'inspection' ? _this.idbWrapperService.all('answers') : _this.answersService.getAnswers(_this.assignment.id),
            _this.assignment.status === 'inspection' ? _this.idbWrapperService.all('requiredAttachments') : _this.requiredAttachmentsService.getRequiredAttachments(_this.assignment.id)
        ]); })).subscribe(function (_a) {
            var sections = _a[0], subsections = _a[1], questions = _a[2], answers = _a[3], requiredAttachments = _a[4];
            _this.currentSection = sections.find(function (section) { return section.id === sectionId; });
            // Assign a fake id to the offline required attachments which is useful for deleting them
            _this.assignment.requiredAttachments.forEach(function (requiredAttachment, index) { return requiredAttachment.id = index; });
            /*
             Filter the subsections, excluding those that don't belong to the current section and to the assignment factory type,
             as well as those without any question
            */
            var newSubsections = subsections.filter(function (subsection) {
                return subsection.sectionId === sectionId &&
                    subsection.factoryTypeIds.includes(_this.assignment.factoryTypeId) &&
                    questions
                        .filter(function (question) { return question.factoryTypeIds.includes(_this.assignment.factoryTypeId); })
                        .map(function (question) { return question.subsectionId; })
                        .includes(subsection.id);
            });
            // Restructure the answer array
            answers
                .filter(function (answer) { return !!answer.parentAnswerId; })
                .forEach(function (childAnswer) {
                var parentAnswer = answers.find(function (answer) { return answer.id === childAnswer.parentAnswerId; });
                if (!parentAnswer.childAnswers) {
                    parentAnswer.childAnswers = [];
                }
                parentAnswer.childAnswers.push(childAnswer);
            });
            // Remove the child answers
            answers = answers.filter(function (answer) { return !answer.parentAnswerId; });
            // Restructure the question array and assign them their answers
            questions.forEach(function (question) {
                question.questionText = (question.questionTexts.find(function (text) { return text.factoryTypeId === _this.assignment.factoryTypeId; }) ||
                    question.questionTexts.find(function (text) { return text.factoryTypeId === null; })).text;
                question.templateMappingRule = (question.templateMappingRules.find(function (rule) { return rule.factoryTypeId === _this.assignment.factoryTypeId; }) ||
                    question.templateMappingRules.find(function (rule) { return rule.factoryTypeId === null; }));
                question.requiredAttachments = requiredAttachments.filter(function (requiredAttachment) { return requiredAttachment.questionId === question.id; }).concat(_this.assignment.requiredAttachments.filter(function (requiredAttachment) { return requiredAttachment.questionId === question.id; }));
            });
            questions
                .filter(function (question) { return !!question.parentQuestionId; })
                .forEach(function (childQuestion) {
                var parentQuestion = questions.find(function (question) { return question.id === childQuestion.parentQuestionId; });
                if (!parentQuestion.childQuestions) {
                    parentQuestion.childQuestions = [];
                }
                parentQuestion.childQuestions.push(childQuestion);
            });
            // Remove the child questions
            questions = questions.filter(function (question) { return !question.parentQuestionId; });
            questions.forEach(function (question) {
                if (question.questionType !== 'group_question') {
                    var answer = answers.find(function (ans) {
                        return ans.questionId === question.id &&
                            ans.assignmentId === _this.assignment.id;
                    });
                    if (answer) {
                        question.answer = answer;
                    }
                }
                else {
                    question.groupAnswers = answers.filter(function (answer) {
                        return answer.questionId === question.id &&
                            answer.assignmentId === _this.assignment.id;
                    });
                }
            });
            // Attach questions to their subsections
            newSubsections.forEach(function (subsection) {
                subsection.questions = questions.filter(function (question) {
                    return question.subsectionId === subsection.id &&
                        question.factoryTypeIds.includes(_this.assignment.factoryTypeId);
                });
            });
            _this.subsections = newSubsections;
            if (_this.assignment.status === 'inspection') {
                _this.subsections = _this.subsections.filter(function (subsection) { return !!subsection.questions.filter(function (question) { return !question.processingOnly; }).length; });
            }
            _this.answerOptions = [];
            _this.mapFilterAndUpdateAnswerOptionIds(answers, questions);
            var childQuestions = [];
            questions.forEach(function (question) { return question.childQuestions && childQuestions.push.apply(childQuestions, question.childQuestions); });
            answers.forEach(function (a) { return a.childAnswers && _this.mapFilterAndUpdateAnswerOptionIds(a.childAnswers, childQuestions); });
            setTimeout(function () {
                _this.animate = true;
                if (_this.subsections.length && document.getElementById('router-wrapper') && !_this.watchersInitialized) {
                    _this.containerMonitor = scrollMonitor.createContainer(document.getElementById('router-wrapper'));
                    _this.subsections.forEach(function (subsection, index) {
                        if (index === 0) {
                            _this.highlightSubsection(subsection.id);
                        }
                        var subsectionWatcher = _this.containerMonitor.create(document.getElementById("subsection-" + subsection.id));
                        subsectionWatcher.enterViewport(function () {
                            if (subsectionWatcher.isAboveViewport) {
                                _this.highlightSubsection(subsection.id);
                            }
                        });
                        subsectionWatcher.exitViewport(function () {
                            if (subsectionWatcher.isAboveViewport) {
                                _this.highlightSubsection(_this.subsections[index + 1].id);
                            }
                        });
                    });
                    _this.watchersInitialized = true;
                }
            });
        }, function () {
            _this.toaster.displayError('Errore nell\'apertura dell\'incarico.');
            _this.router.navigate(['/dashboard']);
        });
    };
    AuditComponent.prototype.ngOnDestroy = function () {
        this.destroyWatchers();
        this.activatedRouteSubscription.unsubscribe();
    };
    return AuditComponent;
}());
export { AuditComponent };
