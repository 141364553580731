import { Subsection } from '../subsection/subsection';

export class Section {
  id: number;
  name: string;
  factoryTypeIds: number[];
  sort: number;
  subsections: Subsection[];

  constructor(obj: any) {
    this.id =         obj && obj.id;
    this.name =       obj && obj.name           || null;
    this.factoryTypeIds =         obj && obj.factory_type_ids || null;
    this.sort =       obj && obj.sort;
  }
}
