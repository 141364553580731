/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./question-card/question-card.component.ngfactory";
import * as i3 from "./question-card/question-card.component";
import * as i4 from "../../core/dialog/dialog.service";
import * as i5 from "./group-question/group-question.component.ngfactory";
import * as i6 from "./group-question/group-question.component";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/notes/notes.component.ngfactory";
import * as i9 from "../../shared/notes/notes.component";
import * as i10 from "@angular/material/bottom-sheet";
import * as i11 from "../../core/models/assignment/assignments.service";
import * as i12 from "../../core/idb-wrapper/idb-wrapper.service";
import * as i13 from "../../shared/last-saved/last-saved.component.ngfactory";
import * as i14 from "../../shared/last-saved/last-saved.component";
import * as i15 from "../../shared/fullscreen-image/fullscreen-image.component.ngfactory";
import * as i16 from "../../shared/fullscreen-image/fullscreen-image.component";
import * as i17 from "./audit.component";
import * as i18 from "../../core/side-bar/side-menu/side-menu.service";
import * as i19 from "@angular/router";
import * as i20 from "../../core/net-status/net-status.service";
import * as i21 from "../../core/models/answer/answers.service";
import * as i22 from "../../core/toaster/toaster.service";
import * as i23 from "./audit-refresh.service";
import * as i24 from "../../core/user/user.service";
import * as i25 from "../../core/models/required-attachment/required-attachment.service";
import * as i26 from "../../core/models/picture/pictures.service";
import * as i27 from "../../core/models/attachment/attachments.service";
import * as i28 from "@angular/platform-browser";
import * as i29 from "../../core/utils/utils.service";
var styles_AuditComponent = [i0.styles];
var RenderType_AuditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuditComponent, data: { "animation": [{ type: 7, name: "collapse", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0", overflow: "hidden", height: "0", margin: "0", padding: "0" }, offset: null }, { type: 4, styles: { type: 6, styles: { height: "*", padding: "*", margin: "*", "opacity": 1 }, offset: null }, timings: "150ms ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { height: "*", padding: "*", margin: "*", "opacity": 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "0", overflow: "hidden", height: "0", margin: "0", padding: "0" }, offset: null }, timings: "150ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_AuditComponent as RenderType_AuditComponent };
function View_AuditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "audit__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_AuditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[24, "@collapse", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "question-card", [], null, [[null, "answerChange"], [null, "pictureAdd"], [null, "pictureDelete"], [null, "pictureEdit"], [null, "pictureOpen"], [null, "attachmentAdd"], [null, "attachmentDownload"], [null, "attachmentDelete"], [null, "requiredAttachmentAdd"], [null, "requiredAttachmentEdit"], [null, "requiredAttachmentDelete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("answerChange" === en)) {
        var pd_0 = (_co.onQuestionChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("pictureAdd" === en)) {
        var pd_1 = (_co.onPictureAdd($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("pictureDelete" === en)) {
        var pd_2 = (_co.onPictureDelete($event) !== false);
        ad = (pd_2 && ad);
    } if (("pictureEdit" === en)) {
        var pd_3 = (_co.onPictureEdit($event) !== false);
        ad = (pd_3 && ad);
    } if (("pictureOpen" === en)) {
        var pd_4 = (_co.onPictureOpen($event) !== false);
        ad = (pd_4 && ad);
    } if (("attachmentAdd" === en)) {
        var pd_5 = (_co.onAttachmentAdd($event, _v.parent.context.$implicit) !== false);
        ad = (pd_5 && ad);
    } if (("attachmentDownload" === en)) {
        var pd_6 = (_co.onAttachmentDownload($event) !== false);
        ad = (pd_6 && ad);
    } if (("attachmentDelete" === en)) {
        var pd_7 = (_co.onAttachmentDelete($event) !== false);
        ad = (pd_7 && ad);
    } if (("requiredAttachmentAdd" === en)) {
        var pd_8 = (_co.onRequiredAttachmentAdd($event, _v.parent.context.$implicit) !== false);
        ad = (pd_8 && ad);
    } if (("requiredAttachmentEdit" === en)) {
        var pd_9 = (_co.onRequiredAttachmentEdit($event) !== false);
        ad = (pd_9 && ad);
    } if (("requiredAttachmentDelete" === en)) {
        var pd_10 = (_co.onRequiredAttachmentDelete($event) !== false);
        ad = (pd_10 && ad);
    } return ad; }, i2.View_QuestionCardComponent_0, i2.RenderType_QuestionCardComponent)), i1.ɵdid(2, 4964352, null, 0, i3.QuestionCardComponent, [i4.DialogService], { question: [0, "question"], answer: [1, "answer"], assignment: [2, "assignment"], viewMode: [3, "viewMode"], shouldFocus: [4, "shouldFocus"], user: [5, "user"] }, { answerChange: "answerChange", pictureAdd: "pictureAdd", attachmentAdd: "attachmentAdd", attachmentDelete: "attachmentDelete", attachmentDownload: "attachmentDownload", pictureOpen: "pictureOpen", pictureEdit: "pictureEdit", pictureDelete: "pictureDelete", requiredAttachmentAdd: "requiredAttachmentAdd", requiredAttachmentEdit: "requiredAttachmentEdit", requiredAttachmentDelete: "requiredAttachmentDelete" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _v.parent.context.$implicit.answer; var currVal_3 = _co.assignment; var currVal_4 = ((((_co.userService.user == null) ? null : _co.userService.user.role) === "clerk") || (_co.assignment.status === "completed")); var currVal_5 = ((_v.parent.parent.context.index === 0) && (_v.parent.context.index === 0)); var currVal_6 = _co.userService.user; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_AuditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[24, "@collapse", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "group-question", [], null, [[null, "parentAnswerCreate"], [null, "parentAnswerDelete"], [null, "childAnswerChange"], [null, "pictureAdd"], [null, "pictureDelete"], [null, "pictureEdit"], [null, "pictureOpen"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("parentAnswerCreate" === en)) {
        var pd_0 = (_co.onParentAnswerCreate(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("parentAnswerDelete" === en)) {
        var pd_1 = (_co.onParentAnswerDelete(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_1 && ad);
    } if (("childAnswerChange" === en)) {
        var pd_2 = (_co.onChildQuestionChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("pictureAdd" === en)) {
        var pd_3 = (_co.onChildPictureAdd($event) !== false);
        ad = (pd_3 && ad);
    } if (("pictureDelete" === en)) {
        var pd_4 = (_co.onPictureDelete($event) !== false);
        ad = (pd_4 && ad);
    } if (("pictureEdit" === en)) {
        var pd_5 = (_co.onPictureEdit($event) !== false);
        ad = (pd_5 && ad);
    } if (("pictureOpen" === en)) {
        var pd_6 = (_co.onPictureOpen($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i5.View_GroupQuestionComponent_0, i5.RenderType_GroupQuestionComponent)), i1.ɵdid(2, 114688, null, 0, i6.GroupQuestionComponent, [i4.DialogService], { question: [0, "question"], assignment: [1, "assignment"], viewMode: [2, "viewMode"], user: [3, "user"] }, { parentAnswerCreate: "parentAnswerCreate", parentAnswerDelete: "parentAnswerDelete", childAnswerChange: "childAnswerChange", pictureAdd: "pictureAdd", pictureOpen: "pictureOpen", pictureDelete: "pictureDelete", pictureEdit: "pictureEdit" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.assignment; var currVal_3 = ((((_co.userService.user == null) ? null : _co.userService.user.role) === "clerk") || (_co.assignment.status === "completed")); var currVal_4 = _co.userService.user; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_AuditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_5)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.questionType !== "group_question") && _v.context.$implicit.unlocked) && !((_co.assignment.status === "inspection") && _v.context.$implicit.processingOnly)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.questionType === "group_question") && !((_co.assignment.status === "inspection") && _v.context.$implicit.processingOnly)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_AuditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "audit__subsection"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "audit__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_3)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.questions; var currVal_3 = _co.questionTrackBy; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "subsection-", _v.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); }); }
function View_AuditComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notes", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onNotesChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NotesComponent_0, i8.RenderType_NotesComponent)), i1.ɵdid(1, 114688, null, 0, i9.NotesComponent, [i10.MatBottomSheet, i11.AssignmentsService, i12.IdbWrapperService], { assignment: [0, "assignment"], viewMode: [1, "viewMode"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assignment; var currVal_1 = ((((_co.userService.user == null) ? null : _co.userService.user.role) === "clerk") || (_co.assignment.status === "completed")); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AuditComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "last-saved", [], null, null, null, i13.View_LastSavedComponent_0, i13.RenderType_LastSavedComponent)), i1.ɵdid(1, 114688, null, 0, i14.LastSavedComponent, [], { saveState: [0, "saveState"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.saving$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AuditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "audit"]], [[24, "@.disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "audit__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_2)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_6)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuditComponent_7)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "fullscreen-image", [], null, [[null, "close"], ["document", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onPictureClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_FullscreenImageComponent_0, i15.RenderType_FullscreenImageComponent)), i1.ɵdid(12, 114688, null, 0, i16.FullscreenImageComponent, [], { image: [0, "image"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.subsections.length; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.subsections; var currVal_4 = _co.subsectionTrackBy; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.assignment; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.subsections.length; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.openPicture; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.animate; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.currentSection == null) ? null : _co.currentSection.name); _ck(_v, 2, 0, currVal_1); }); }
export function View_AuditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "audit", [], null, null, null, View_AuditComponent_0, RenderType_AuditComponent)), i1.ɵdid(1, 245760, null, 0, i17.AuditComponent, [i18.SideMenuService, i19.ActivatedRoute, i20.NetStatusService, i11.AssignmentsService, i12.IdbWrapperService, i21.AnswersService, i22.ToasterService, i19.Router, i23.AuditRefreshService, i24.UserService, i25.RequiredAttachmentService, i26.PicturesService, i27.AttachmentsService, i28.DomSanitizer, i29.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuditComponentNgFactory = i1.ɵccf("audit", i17.AuditComponent, View_AuditComponent_Host_0, {}, {}, []);
export { AuditComponentNgFactory as AuditComponentNgFactory };
