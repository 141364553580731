/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-dialog.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/common";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./upload-dialog";
var styles_UploadDialogComponent = [i0.styles];
var RenderType_UploadDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadDialogComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { "opacity": 1 }, offset: null }, timings: "150ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_UploadDialogComponent as RenderType_UploadDialogComponent };
function View_UploadDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "upload-box"]], [[2, "upload-box--drag-over", null]], [[null, "drop"], [null, "dragenter"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (_co.onDragOver(true) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (_co.onDragOver(false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "note_add"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Trascina ", " qui"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["oppure"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "sebastian-button sebastian-button--primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 20).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, ["Scegli ", " "]))], function (_ck, _v) { var currVal_3 = "note_add"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dragOver; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).inline; var currVal_2 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _co.text; _ck(_v, 4, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 8).disabled || null); var currVal_6 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = _co.text; _ck(_v, 9, 0, currVal_7); }); }
function View_UploadDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [24, "@fade", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgPreview; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_UploadDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_UploadDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_4)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_5)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgPreview; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.imgPreview; _ck(_v, 5, 0, currVal_1); }, null); }
function View_UploadDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "picture_as_pdf"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "picture_as_pdf"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UploadDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "file-container upload-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_3)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_6)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFileDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "delete"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.resource === "picture"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.data.resource === "pdf"); _ck(_v, 4, 0, currVal_1); var currVal_7 = "delete"; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.file.name; _ck(_v, 6, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
function View_UploadDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "caption__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Didascalia"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "textarea", [["class", "caption__textarea"], ["rows", "3"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.caption = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i10.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i10.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i10.NgModel, [[8, null], [8, null], [8, null], [6, i10.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i10.NgControl, null, [i10.NgModel]), i1.ɵdid(8, 16384, null, 0, i10.NgControlStatus, [[4, i10.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.caption; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_UploadDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i11.MatDialogTitle, [[2, i11.MatDialogRef], i1.ElementRef, i11.MatDialog], null, null), (_l()(), i1.ɵted(2, null, [" Carica ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i11.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_2)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_7)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i11.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(14, 606208, null, 0, i11.MatDialogClose, [[2, i11.MatDialogRef], i1.ElementRef, i11.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(15, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Annulla"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["mat-button", ""], ["tabindex", "1"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Conferma"])), (_l()(), i1.ɵeld(20, 0, [["input", 1]], null, 0, "input", [["class", "inputfile"], ["name", "file"], ["type", "file"]], [[8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileUpload($event.target.files[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = !_co.file; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.file; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.data.resource === "picture"); _ck(_v, 10, 0, currVal_4); var currVal_8 = ""; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 2, 0, currVal_1); var currVal_5 = (i1.ɵnov(_v, 14)._hasAriaLabel ? i1.ɵnov(_v, 14).ariaLabel : null); var currVal_6 = (i1.ɵnov(_v, 15).disabled || null); var currVal_7 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = (i1.ɵnov(_v, 18).disabled || null); var currVal_10 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_11 = _co.accept; _ck(_v, 20, 0, currVal_11); }); }
export function View_UploadDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "upload-dialog", [], null, null, null, View_UploadDialogComponent_0, RenderType_UploadDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.UploadDialogComponent, [i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadDialogComponentNgFactory = i1.ɵccf("upload-dialog", i12.UploadDialogComponent, View_UploadDialogComponent_Host_0, {}, {}, []);
export { UploadDialogComponentNgFactory as UploadDialogComponentNgFactory };
