import { Injectable }                 from '@angular/core';
import { MatDialog }                  from '@angular/material';
import { ConfirmDialogComponent }            from './confirm-dialog/confirm-dialog';
import { ChoiceDialogComponent }             from './choice-dialog/choice-dialog';
import { ApiHttpClientService }              from '../api-http-client/api-http-client.service';
import { UploadDialogComponent }             from './upload-dialog/upload-dialog';
import { PictureEditDialogComponent }        from './picture-edit-dialog/picture-edit-dialog';
import { RequiredAttachment }                from '../models/required-attachment/required-attachment';
import { RequiredAttachmentDialogComponent } from './required-attachment-dialog/required-attachment-dialog';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog, private httpService: ApiHttpClientService) { }

  confirmDialog(text?: string) {
    text = text || 'Sicuro?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { text }
    });

    return dialogRef.afterClosed();
  }

  choiceDialog(data) {
    const dialogRef = this.dialog.open(ChoiceDialogComponent, {data});
    return dialogRef.afterClosed();
  }

  uploadDialog(data) {
    const dialogRef = this.dialog.open(UploadDialogComponent, {data});
    return dialogRef.afterClosed();
  }

  pictureEditDialog(data) {
    const dialogRef = this.dialog.open(PictureEditDialogComponent, {data});
    return dialogRef.afterClosed();
  }

  requiredAttachmentDialog(requiredAttachment?: RequiredAttachment) {
    const dialogRef = this.dialog.open(RequiredAttachmentDialogComponent, {
      data: { requiredAttachment }
    });
    return dialogRef.afterClosed();
  }

  closeAll() {
    this.dialog.closeAll();
  }


}
