import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AuditRefreshService = /** @class */ (function () {
    function AuditRefreshService() {
        this.auditRefreshSource$ = new BehaviorSubject(null);
        this.auditRefresh$ = this.auditRefreshSource$.asObservable();
    }
    AuditRefreshService.prototype.requestAuditRefresh = function () {
        this.auditRefreshSource$.next(null);
    };
    AuditRefreshService.ngInjectableDef = i0.defineInjectable({ factory: function AuditRefreshService_Factory() { return new AuditRefreshService(); }, token: AuditRefreshService, providedIn: "root" });
    return AuditRefreshService;
}());
export { AuditRefreshService };
