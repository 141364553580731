
<div class="fullscreen-image" *ngIf="image" [@fade]>
  <div class="fullscreen-image__content">
    <div class="fullscreen-image__content__image">
      <div class="spinner" *ngIf="!image.url"></div>
      <img *ngIf="image.url" [src]="image.url" [@fade] />
    </div>
    <div class="fullscreen-image__content__description">
      {{image.caption}}
    </div>
  </div>
  <div class="sebastian-back" (click)="onClose()">
    <mat-icon svgIcon="keyboard_backspace"></mat-icon>
  </div>
</div>
