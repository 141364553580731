import { NgModule }                                       from '@angular/core';
import { AttachmentChipComponent }                        from './attachment-chip.component';
import { MatButtonModule, MatChipsModule, MatIconModule } from '@angular/material';
import { CommonModule }                                   from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [AttachmentChipComponent],
  exports: [AttachmentChipComponent]
})
export class AttachmentChipModule { }
