var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NativeDateAdapter } from '@angular/material';
import { Platform } from '@angular/cdk/platform';
var ItDateAdapter = /** @class */ (function (_super) {
    __extends(ItDateAdapter, _super);
    function ItDateAdapter() {
        return _super.call(this, 'it-IT', new Platform()) || this;
    }
    ItDateAdapter.prototype.getFirstDayOfWeek = function () {
        return 1;
    };
    return ItDateAdapter;
}(NativeDateAdapter));
export { ItDateAdapter };
