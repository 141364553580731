import { map } from 'rxjs/operators';
import { AssignmentField } from './assignment-field';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var AssignmentFieldsService = /** @class */ (function () {
    function AssignmentFieldsService(http) {
        this.http = http;
    }
    AssignmentFieldsService.prototype.getAssignmentFields = function () {
        return this.http.get('assignment_fields').pipe(map(function (res) { return res.assignment_fields.map(function (assignmentField) { return new AssignmentField(assignmentField); }); }));
    };
    AssignmentFieldsService.ngInjectableDef = i0.defineInjectable({ factory: function AssignmentFieldsService_Factory() { return new AssignmentFieldsService(i0.inject(i1.ApiHttpClientService)); }, token: AssignmentFieldsService, providedIn: "root" });
    return AssignmentFieldsService;
}());
export { AssignmentFieldsService };
