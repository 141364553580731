<side-bar></side-bar>

<!-- Router outlet -->
<div id="app-content" (konami)="onKonami()">
  <div id="router-wrapper" class="router-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="spinner-overlay" *ngIf="showSpinner">
  <div class="spinner"></div>
  <div class="spinner-message" *ngIf="spinnerMessage">{{ spinnerMessage }}</div>
</div>
