import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { Picture }              from '../../models/picture/picture';

@Injectable()
export class UploadDialogService {

  constructor(private httpService: ApiHttpClientService) { }
}
