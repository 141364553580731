import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
}                                      from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { UserService }                 from '../../core/user/user.service';

@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, OnChanges {

  @Input() displayedColumns: string[];
  @Input() columnHeaders: { [key: string]: string };
  @Input() data;
  @Input() offline = false;
  @Output() download = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  dataSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;

  constructor(public userService: UserService) {
  }

  onDownload(element) {
    this.download.emit(element);
  }

  onRowClick(element) {
    this.rowClick.emit(element);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.dataSource.data = this.data || [];
  }
}
