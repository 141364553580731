import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment }                                     from '../../core/models/attachment/attachment';

@Component({
  selector: 'attachment-chip',
  templateUrl: './attachment-chip.component.html',
  styleUrls: ['./attachment-chip.component.scss']
})
export class AttachmentChipComponent {
  @Input() attachment: Attachment;
  @Input() canDelete: boolean;
  @Output() download = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor() { }

  onDownload() {
    this.download.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
