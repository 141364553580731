<div class="pictures" [@.disabled]="!animate">
  <div class="pictures__header">
    <h4 class="pictures__header__title">Fotogrammi</h4>
  </div>
  <div class="pictures__loading" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <div class="pictures__table" *ngIf="!loading && pictures?.length > 0">
    <div class="pictures__table__header">
      <div></div>
      <div>Foto</div>
      <div>Didascalia</div>
      <div>
        <ng-container *ngIf="userService.user.role !== 'clerk'">Azioni</ng-container>
      </div>
    </div>
    <div class="pictures__table__body">
      <div cdkDropList (cdkDropListDropped)="onPictureReorder($event)">
        <div class="pictures__table__body__row"
             *ngFor="let picture of pictures"
             [@zoomLeave]
             cdkDrag
             cdkDragLockAxis="y">
          <div class="pictures__table__body__row__content">
            <div class="pictures__table__body__row__drag-handle">
              <mat-icon svgIcon="open_with" cdkDragHandle [hidden]="userService.user.role === 'clerk'"></mat-icon>
            </div>
            <div class="pictures__table__body__row__picture-wrapper">
              <div class="pictures__table__body__row__picture-wrapper__picture"
                   [style]="'background-image: url(\'' + picture.thumbnail + '\')' | safe: {type: 'style'}"
                   (click)="onPictureOpen(picture)">
              </div>
            </div>
            <div>
              <p *ngIf="!picture.updating">{{picture.caption}}</p>
              <div class="spinner" *ngIf="picture.updating"></div>
            </div>
            <div>
              <button mat-icon-button disabled> <!-- ugly but gets the job done -->
                <mat-icon svgIcon="contact_support"
                          [matTooltip]="picture.questionText"
                          matTooltipPosition="left"
                          matTooltipClass="question-card__header__actions__tooltip"></mat-icon>
              </button>
              <ng-container *ngIf="userService.user.role !== 'clerk'">
                <button mat-icon-button (click)="onPictureEdit(picture)">
                  <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button mat-icon-button (click)="onPictureDelete(picture)">
                  <mat-icon svgIcon="delete"></mat-icon>
                </button>
              </ng-container>
            </div>
            <div *cdkDragPlaceholder class="pictures__table__body__row__drag-placeholder">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pictures__table" *ngIf="!loading && pictures?.length === 0">
    Nessun fotogramma
  </div>
</div>

<fullscreen-image [image]="fullscreenImage" (close)="onPictureClose()"></fullscreen-image>

