<div class="side-menu">
  <div class="side-menu__header" *ngIf="assignment">
    <p>Stabilimento di {{assignment?.insured}} a {{assignment?.location}}</p>
    <div class="side-menu__header__row">
      <div
        class="sebastian-status sebastian-status--{{assignment?.status}}" [ngClass]="{ 'sebastian-status--not-downloaded': assignment?.status === 'inspection' && !assignment?.offline }">
        {{assignment?.translatedStatus}}
      </div>
      <button mat-icon-button class="side-menu__header__row__confirm-button"
              (click)="onInspectionClose()"
              matTooltip="Chiudi la fase di sopralluogo offline"
              matTooltipPosition="right"
              matTooltipClass="side-menu__header__row__tooltip"
              *ngIf="!syncingAssignment && (netStatus.onlineStatus | async) && assignment?.status === 'inspection' && userService.user?.role !== 'clerk'">
        <mat-icon svgIcon="done"></mat-icon>
      </button>
      <div class="side-menu__header__row__spinner" *ngIf="syncingAssignment">
        <mat-progress-spinner color="primary"
                              mode="determinate"
                              diameter="40"
                              [value]="assignmentSyncProgress"
                              *ngIf="syncingAssignment && !changingAssignmentStatus">
        </mat-progress-spinner>
        <mat-progress-spinner color="primary"
                              mode="indeterminate"
                              diameter="40"
                              *ngIf="syncingAssignment && changingAssignmentStatus">
        </mat-progress-spinner>
      </div>
      <button mat-icon-button class="side-menu__header__row__confirm-button"
              (click)="onProcessingClose()"
              matTooltip="Chiudi la fase di elaborazione"
              matTooltipPosition="right"
              matTooltipClass="side-menu__header__row__tooltip"
              *ngIf="!syncingAssignment && (netStatus.onlineStatus | async) && assignment?.status === 'processing' && userService.user?.role !== 'clerk'">
        <mat-icon svgIcon="done"></mat-icon>
      </button>
      <button mat-icon-button class="side-menu__header__row__confirm-button"
              (click)="onCompletedReopen()"
              matTooltip="Riapri incarico"
              matTooltipPosition="right"
              matTooltipClass="side-menu__header__row__tooltip"
              *ngIf="!syncingAssignment && (netStatus.onlineStatus | async) && assignment?.status === 'completed'">
        <mat-icon svgIcon="undo"></mat-icon>
      </button>
    </div>
    <!--<div>Aggiornato il {{assignment?.updated_at | date:short}}</div>-->
  </div>

  <div class="side-menu__links" *ngIf="!linksLoading" [@fade]>
    <ng-container *ngFor="let link of links">
      <div class="side-menu__link"
           [ngClass]="{ 'side-menu__link--section': !!link.sectionId }"
           (click)="onLinkClick(link)"
           [routerLink]="link.routerLink"
           [routerLinkActiveOptions]= "{exact: true}"
           [routerLinkActive]="!!link.routerLink ? 'active' : ''">

        <div class="side-menu__link__left">
          <mat-icon class="side-menu__link__prefix-icon" *ngIf="link.prefixIcon" [svgIcon]="link.prefixIcon"></mat-icon>
          <a [class.active--bold]="link.expanded">{{link.label}}</a>
        </div>
        <div class="side-menu__link__right">
          <mat-icon class="side-menu__link__incomplete-icon" svgIcon="lens" *ngIf="link.complete === false"></mat-icon>
          <mat-icon class="side-menu__link__suffix-icon" *ngIf="link.suffixIcon" [svgIcon]="link.suffixIcon"></mat-icon>
        </div>
      </div>

      <!-- Child links -->
      <div class="side-menu__child-links" *ngIf="link.expanded" [@collapse]>
        <div class="side-menu__link"
             id="subsection-menu-{{child.subsectionId}}"
             *ngFor="let child of link.children"
             (click)="onChildClick(child.subsectionId)"
             [ngx-scroll-to-offset]="-10"
             [ngx-scroll-to-duration]="200"
             [ngx-scroll-to]="'#subsection-' + child.subsectionId">
          <a class="side-menu__child">
            {{child.label}}
          </a>
          <mat-icon svgIcon="lens" class="side-menu__link__incomplete-icon" *ngIf="link.complete === false"></mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
