import { Injectable }                     from '@angular/core';
import { MatSnackBar, MatSnackBarConfig }   from '@angular/material';

@Injectable()
export class ToasterService {

  constructor(private snackBar: MatSnackBar) { }

  dismissAll() {
    this.snackBar.dismiss();
  }

  displaySuccess(message, duration?) {
    this.displayMessage(message, 'success', duration);
  }

  displayError(message, duration?) {
    this.displayMessage(message, 'error', duration);
  }

  displayNotice(message, duration?) {
    this.displayMessage(message, 'notice', duration)
  }

  private displayMessage(message, type: 'success' | 'error' | 'notice', duration?: number) {
    const snackBarConfig = new MatSnackBarConfig();
    let action = 'Chiudi';
    snackBarConfig.duration = duration || 2500;
    // As of now, success and error popups behave the same, but the distinction is there for future differences
    switch (type) {
      case 'success':
        break;
      case 'error':
        snackBarConfig.panelClass = 'error';
        break;
      case 'notice':
        action = '';
        break;
    }
    this.snackBar.open(message, action, snackBarConfig);
  }
}
