import { Component, Input, OnInit } from '@angular/core';
import { SaveState }                from './save-state';

@Component({
  selector: 'last-saved',
  templateUrl: './last-saved.component.html',
  styleUrls: ['./last-saved.component.scss']
})
export class LastSavedComponent implements OnInit {

  @Input() saveState: SaveState;

  constructor() { }

  ngOnInit() {
  }

}
