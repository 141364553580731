import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { Attachment }           from './attachment';
import { HttpHeaders }          from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(private http: ApiHttpClientService) {
  }

  createAttachment(attachmentData: {answerId: number, requiredAttachmentId?: number, file: any}): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('attachment[answer_id]', '' + attachmentData.answerId);
    if (attachmentData.requiredAttachmentId) {
      formData.append('attachment[required_attachment_id]', '' + attachmentData.requiredAttachmentId);
    }
    formData.append('attachment[file]', attachmentData.file);
    return this.http.post('attachments', formData, { headers: new HttpHeaders() }).pipe(
      map((res: any) => new Attachment(res))
    );
  }

  getAttachments(assignmentId: number): Observable<Attachment[]> {
    return this.http.get(`assignments/${assignmentId}/attachments`).pipe(
      map((res: any) => res && res.attachments.map(attachment => new Attachment(attachment)))
    );
  }

  getAttachment(attachmentId: number): Observable<Object> {
    return this.http.get(`attachments/${attachmentId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  deleteAttachment(attachmentId: number): Observable<any> {
    return this.http.delete(`attachments/${attachmentId}`);
  }
}
