import { Injectable }      from '@angular/core';
import { DashboardData } from './dashboard-data';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiHttpClientService } from '../core/api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  dashboardUrl = 'dashboard';

  constructor(private http: ApiHttpClientService) { }

  getDashboardData(): Observable<DashboardData> {
    return this.http.get(this.dashboardUrl).pipe(
      map((res: any) => new DashboardData(res))
    );
  }
}
