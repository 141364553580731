import { RiskScore } from '../risk-score/risk-score';
import { Target }    from '../target/target';
import { Source }    from '../source/source';

export class ScoreMapping {
  id: number;
  sourceId: number;
  targetId: number;
  factoryKindId: number;

  riskScore: RiskScore;
  source: Source;
  target: Target;

  constructor(obj) {
    this.id =               obj && obj.id;
    this.sourceId =         obj && obj.source_id;
    this.targetId =         obj && obj.target_id;
    this.factoryKindId =    obj && obj.factory_kind_id;
  }
}
