/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./required-attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/attachment-chip/attachment-chip.component.ngfactory";
import * as i3 from "../../shared/attachment-chip/attachment-chip.component";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/common";
import * as i12 from "./required-attachments.component";
import * as i13 from "../../core/dialog/dialog.service";
import * as i14 from "@angular/router";
import * as i15 from "../../core/models/assignment/assignments.service";
import * as i16 from "../../core/side-bar/side-menu/side-menu.service";
import * as i17 from "../../core/models/required-attachment/required-attachment.service";
import * as i18 from "../../core/models/attachment/attachments.service";
import * as i19 from "../../core/idb-wrapper/idb-wrapper.service";
import * as i20 from "../../core/utils/utils.service";
import * as i21 from "../../core/models/answer/answers.service";
import * as i22 from "../../core/net-status/net-status.service";
import * as i23 from "../../core/user/user.service";
var styles_RequiredAttachmentsComponent = [i0.styles];
var RenderType_RequiredAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequiredAttachmentsComponent, data: {} });
export { RenderType_RequiredAttachmentsComponent as RenderType_RequiredAttachmentsComponent };
function View_RequiredAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "required-attachments__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_RequiredAttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome file"]))], null, null); }
function View_RequiredAttachmentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "attachment-chip", [], null, [[null, "download"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("download" === en)) {
        var pd_0 = (_co.onAttachmentDownload(_v.parent.parent.context.$implicit.attachment) !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.onAttachmentDelete(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AttachmentChipComponent_0, i2.RenderType_AttachmentChipComponent)), i1.ɵdid(1, 49152, null, 0, i3.AttachmentChipComponent, [], { attachment: [0, "attachment"], canDelete: [1, "canDelete"] }, { download: "download", delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.attachment; var currVal_1 = (_co.assignment.status !== "completed"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_RequiredAttachmentsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "icon-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAttachmentAdd(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "attach_file"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, [" Aggiungi allegato "]))], function (_ck, _v) { var currVal_4 = "attach_file"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_RequiredAttachmentsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_RequiredAttachmentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "required-attachments__table__body__row__document"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_6)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_7)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_8)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.attachment && !_v.parent.context.$implicit.busy) && !((_v.parent.context.$implicit.attachment == null) ? null : _v.parent.context.$implicit.attachment.busy)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_v.parent.context.$implicit.attachment && !_v.parent.context.$implicit.busy) && !((_v.parent.context.$implicit.attachment == null) ? null : _v.parent.context.$implicit.attachment.busy)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.busy || ((_v.parent.context.$implicit.attachment == null) ? null : _v.parent.context.$implicit.attachment.busy)); _ck(_v, 6, 0, currVal_2); }, null); }
function View_RequiredAttachmentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "required-attachments__table__body__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_5)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.assignment.status !== "inspection"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.questionText; _ck(_v, 6, 0, currVal_2); }); }
function View_RequiredAttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "required-attachments__table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "required-attachments__table__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descrizione"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Domanda"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_3)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "required-attachments__table__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_4)), i1.ɵdid(12, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.assignment.status !== "inspection"); _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.requiredAttachments; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_RequiredAttachmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "required-attachments"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "required-attachments__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "required-attachments__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Allegati"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_1)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequiredAttachmentsComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_RequiredAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "required-attachments", [], null, null, null, View_RequiredAttachmentsComponent_0, RenderType_RequiredAttachmentsComponent)), i1.ɵdid(1, 245760, null, 0, i12.RequiredAttachmentsComponent, [i13.DialogService, i14.ActivatedRoute, i15.AssignmentsService, i16.SideMenuService, i17.RequiredAttachmentService, i18.AttachmentsService, i19.IdbWrapperService, i20.UtilsService, i21.AnswersService, i22.NetStatusService, i23.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequiredAttachmentsComponentNgFactory = i1.ɵccf("required-attachments", i12.RequiredAttachmentsComponent, View_RequiredAttachmentsComponent_Host_0, {}, {}, []);
export { RequiredAttachmentsComponentNgFactory as RequiredAttachmentsComponentNgFactory };
