import { Injectable }                      from '@angular/core';
import { Observable, of }                  from 'rxjs';
import { AngularTokenService }             from 'angular-token';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CanActivate, Router }             from '@angular/router';
import { NetStatusService }                from '../net-status/net-status.service';
import { ApiHttpClientService }            from '../api-http-client/api-http-client.service';
import { ToasterService }                  from '../toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements CanActivate {
  private _user: any;
  private tokenValidated = false;
  private translatedRoles = {
    compiler: 'Compilatore',
    manager: 'Manager',
    clerk: 'Segreteria'
  };

  constructor(private router: Router,
              private tokenService: AngularTokenService,
              private http: ApiHttpClientService,
              private netStatus: NetStatusService,
              private toaster: ToasterService) {
  }

  userSignedIn() {
    return this.tokenService.userSignedIn();
  }

  get user() {
    return this._user;
  }

  set user(val: any) {
    this._user = val;
    if (!!val) {
      localStorage.setItem('userData', JSON.stringify(this.user));
    } else {
      localStorage.removeItem('userData');
    }
  }

  signIn(data: any) {
    return this.tokenService.signIn(data).pipe(
      tap(res => {
        this.tokenValidated = true;
        this.user = res && res.body && res.body.data || null;
      })
    );
  }

  signOut() {
    if (this.netStatus.isOnline()) {
      this.user = null;
      this.tokenService.signOut().subscribe(_ => this.router.navigate(['/login']));
    }
  }

  updatePassword(obj) {
    const bodyString = JSON.stringify({user: obj});
    return this.http.put(`users/${this.user.id}`, bodyString).pipe(
      tap((res: any) => {
        this.user = res && res['user'] || null;
      })
    );
  }

  getTranslatedRole() {
    return this._user ? this.translatedRoles[this._user.role] : '';
  }

  canActivate(): Observable<boolean> {
    let obs;
    if (this.tokenValidated) {
      obs = of(true);
    } else {
      if (this.netStatus.isOnline()) {
        obs = this.tokenService.validateToken().pipe(
          map((res: Response) => res['data']),
          tap(user => {
            this.user = user;
            this.tokenValidated = true;
          }),
          catchError(error => {
            this.tokenValidated = true;
            this.user = null;
            return of(null);
          })
        );
      } else {
        obs = of(true);
      }
    }

    return obs.pipe(
      switchMap(() => {
        if (!this.userSignedIn()) {
          this.router.navigate(['login']);
          this.toaster.dismissAll();
        } else {
          this.user = this.user || JSON.parse(localStorage.getItem('userData'));
          if (!this.user) {
            this.signOut();
          } else {
            if (!this.user.first_password_set) {
              this.router.navigate(['password-change']);
            }
          }
        }

        return of(this.userSignedIn());
      })
    );
  }
}
