/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choice-dialog.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../shared/user-autocomplete-form-field/user-autocomplete-form-field.component.ngfactory";
import * as i4 from "../../../shared/user-autocomplete-form-field/user-autocomplete-form-field.component";
import * as i5 from "../../utils/utils.service";
import * as i6 from "../../../shared/user-autocomplete-form-field/user-autocomplete-form-field.service";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./choice-dialog";
var styles_ChoiceDialogComponent = [i0.styles];
var RenderType_ChoiceDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoiceDialogComponent, data: {} });
export { RenderType_ChoiceDialogComponent as RenderType_ChoiceDialogComponent };
export function View_ChoiceDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "user-autocomplete-form-field", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onUserChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_UserAutocompleteFormFieldComponent_0, i3.RenderType_UserAutocompleteFormFieldComponent)), i1.ɵdid(9, 114688, null, 0, i4.UserAutocompleteFormFieldComponent, [i5.UtilsService, i6.UserAutocompleteFormFieldService], { user: [0, "user"], placeholder: [1, "placeholder"] }, { changed: "changed" }), (_l()(), i1.ɵeld(10, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(14, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Annulla"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["mat-button", ""], ["tabindex", "1"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).dialogRef.close(i1.ɵnov(_v, 17).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(17, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(18, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = (_co.data.user || i1.ɵEMPTY_MAP); var currVal_4 = _co.data.placeholder; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_8 = ""; _ck(_v, 13, 0, currVal_8); var currVal_12 = _co.user; _ck(_v, 17, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.body; _ck(_v, 7, 0, currVal_2); var currVal_5 = (i1.ɵnov(_v, 13)._hasAriaLabel ? i1.ɵnov(_v, 13).ariaLabel : null); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = (i1.ɵnov(_v, 17)._hasAriaLabel ? i1.ɵnov(_v, 17).ariaLabel : null); var currVal_10 = (i1.ɵnov(_v, 18).disabled || null); var currVal_11 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_9, currVal_10, currVal_11); }); }
export function View_ChoiceDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "choice-dialog", [], null, null, null, View_ChoiceDialogComponent_0, RenderType_ChoiceDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.ChoiceDialogComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChoiceDialogComponentNgFactory = i1.ɵccf("choice-dialog", i12.ChoiceDialogComponent, View_ChoiceDialogComponent_Host_0, {}, {}, []);
export { ChoiceDialogComponentNgFactory as ChoiceDialogComponentNgFactory };
