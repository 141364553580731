<div class="user-bar">
  <div class="user-bar__upper">
    <!--<div class="user-bar__logo" routerLink="/">-->
    <img src="/assets/images/logo.png" class="user-bar__logo" routerLink="/"/>
    <img src="/assets/images/ayylmao.jpg" class="user-bar__logo ayylmao" routerLink="/"/>
    <!--</div>-->
    <div class="user-bar__net-status"
         [ngClass]="{ hidden: (netStatus.onlineStatus | async)}"
         matTooltip="Offline"
         matTooltipPosition="right">
      <mat-icon svgIcon="offline_bolt"></mat-icon>
    </div>
  </div>

  <div class="user-bar__user" [matMenuTriggerFor]="menu">
    {{ usernameInitials() }}
  </div>
</div>

<mat-menu #menu="matMenu" [class]="'user-bar__mat-menu'">
  <div>{{userService.user?.name}} ({{userService.getTranslatedRole()}})</div>
  <div>{{userService.user?.email}}</div>
  <button mat-menu-item (click)="onLogout()" [disabled]="!(netStatus.onlineStatus | async)">Logout</button>
</mat-menu>
