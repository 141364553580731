import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { RequiredAttachment }   from './required-attachment';
import { map }                  from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequiredAttachmentService {
  constructor(private http: ApiHttpClientService) { }

  getRequiredAttachments(assignmentId?: number): Observable<RequiredAttachment[]> {
    let requestObs;

    if (assignmentId) {
      requestObs = this.http.get(`assignments/${assignmentId}/required_attachments`);
    } else {
      requestObs = this.http.get(`required_attachments`);
    }
    return requestObs.pipe(
      map((res: any) => res.required_attachments.map(requiredAttachments => new RequiredAttachment(requiredAttachments)))
    );
  }

  createRequiredAttachment(requiredAttachment: RequiredAttachment) {
    const bodyString = JSON.stringify({
      required_attachment: {
        question_id: requiredAttachment.questionId,
        description: requiredAttachment.description
      }
    });

    return this.http.post(`assignments/${requiredAttachment.assignmentId}/required_attachments`, bodyString).pipe(
      map((res: any) => new RequiredAttachment(res))
    );
  }

  updateRequiredAttachment(requiredAttachment: RequiredAttachment) {
    const bodyString = JSON.stringify({
      required_attachment: {
        description: requiredAttachment.description
      }
    });

    return this.http.put(`required_attachments/${requiredAttachment.id}`, bodyString).pipe(
      map((res: any) => new RequiredAttachment(res))
    );
  }

  deleteRequiredAttachment(requiredAttachment: RequiredAttachment) {
    return this.http.delete(`required_attachments/${requiredAttachment.id}`);
  }
}
