import { NgModule }             from '@angular/core';
import { ToasterService }       from './toaster.service';
import { MatSnackBarModule }     from '@angular/material';

@NgModule({
  imports: [
    MatSnackBarModule
  ],
  providers: [
    ToasterService
  ]
})
export class ToasterModule {}
