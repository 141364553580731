import { Component, OnDestroy, OnInit }                 from '@angular/core';
import { Source }                                       from '../../core/models/source/source';
import { Target }                                       from '../../core/models/target/target';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, switchMap, tap }                          from 'rxjs/operators';
import { ActivatedRoute }                               from '@angular/router';
import { AssignmentsService }                           from '../../core/models/assignment/assignments.service';
import { RiskScoresService }                            from '../../core/models/risk-score/risk-scores.service';
import { Assignment }                                   from '../../core/models/assignment/assignment';
import { RiskScore }         from '../../core/models/risk-score/risk-score';
import { UtilsService }      from '../../core/utils/utils.service';
import { ScoreMapping }      from '../../core/models/score-mapping/score-mapping';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { SideMenuService }   from '../../core/side-bar/side-menu/side-menu.service';
import { UserService }       from '../../core/user/user.service';
import { ToasterService }    from '../../core/toaster/toaster.service';
import { SpinnerService }    from '../../core/spinner/spinner.service';
import { DialogService }     from '../../core/dialog/dialog.service';
import { SaveState }         from '../../shared/last-saved/save-state';

@Component({
  selector: 'risk-scores',
  templateUrl: './risk-scores.component.html',
  styleUrls: ['./risk-scores.component.scss']
})
export class RiskScoresComponent implements OnInit, OnDestroy {
  activatedRouteSubscription: Subscription;
  assignment: Assignment;
  sources: Source[];
  targets: Target[];
  scoreMappings: ScoreMapping[];
  currentSource: Source;
  reloadSubject = new BehaviorSubject(null);
  loading: boolean;
  savingSubject$: BehaviorSubject<SaveState> = new BehaviorSubject<SaveState>({});
  saving$ = this.savingSubject$.asObservable();

  constructor(private activatedRoute: ActivatedRoute,
              private assignmentsService: AssignmentsService,
              private riskScoresService: RiskScoresService,
              private idWrapper: IdbWrapperService,
              private utils: UtilsService,
              private sideMenuService: SideMenuService,
              private userService: UserService,
              private toaster: ToasterService,
              private spinnerService: SpinnerService,
              private dialogService: DialogService) {
  }

  setSaving() {
    this.savingSubject$.next({saving: true});
  }

  setSaved() {
    this.savingSubject$.next({lastSaved: new Date()});
  }

  ngOnInit() {
    let id, sourceId;

    this.loading = true;
    this.activatedRouteSubscription = combineLatest(
      this.idWrapper.all('sources'),
      this.idWrapper.all('targets')
    ).pipe(
      tap(([sources, targets]: [Source[], Target[]]) => {
        this.sources = sources;
        this.targets = targets;
      }),
      switchMap(() => this.activatedRoute.params),
      tap(res => {
        id = +res.id;
        if (id !== +res.id) {
          this.savingSubject$.next({});
        }
        sourceId = +res.sourceId;
      }),
      switchMap(() => this.reloadSubject.asObservable()),
      tap(() => this.loading = true),
      switchMap(() => combineLatest(
        this.assignmentsService.getAssignment(true, id),
        this.riskScoresService.getRiskScores(id)
      )),
      switchMap(([assignment, riskScores]: [Assignment, RiskScore[]]) =>
        this.utils.getFilteredScoreMappings(assignment.factoryTypeId).pipe(
          map((scoreMappings: ScoreMapping[]) => [assignment, riskScores, scoreMappings])
        )
      )
    ).subscribe(([assignment, riskScores, scoreMappings]: [Assignment, RiskScore[], ScoreMapping[]]) => {
      this.sideMenuService.sideMenuState = {state: 'riskScores', assignment, currentSubRoute: sourceId};
      this.assignment = assignment;
      this.scoreMappings = scoreMappings
        .filter(scoreMapping => scoreMapping.sourceId === sourceId)
        .map(scoreMapping => ({
          ...scoreMapping,
          riskScore: riskScores.find(riskScore => riskScore.scoreMappingId === scoreMapping.id),
          target: this.targets.find(target => target.id === scoreMapping.targetId)
        }));
      this.currentSource = this.sources.find(source => source.id === sourceId);
      this.loading = false;
    });
  }

  onChange(scoreMapping: ScoreMapping) {
    this.setSaving();
    this.riskScoresService.updateRiskScore(scoreMapping.riskScore).subscribe(() => this.setSaved());
  }

  onNotesChange(event) {
    this.assignment.assignmentNotes = event;
    this.setSaved();
  }

  async onComputeScores() {
    const confirm = await this.dialogService.confirmDialog('Vuoi lanciare il calcolo automatico dei punteggi?').toPromise();
    if (confirm) {
      this.spinnerService.toggleSpinner(true, 'Calcolo punteggi in corso');
      this.assignmentsService.computeRiskScores(this.assignment.id).subscribe(
        () => {
          this.reloadSubject.next(null);
          this.spinnerService.toggleSpinner(false);
        },
        () => {
          this.spinnerService.toggleSpinner(false);
          this.toaster.displayError('Errore nella generazione dei punteggi');
        }
      );
    }
  }

  getViewMode() {
    return this.userService.user.role === 'clerk' || this.assignment.status === 'completed';
  }

  ngOnDestroy() {
    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription.unsubscribe();
    }
  }
}
