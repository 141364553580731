import { Injectable }      from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SideMenuState } from './side-menu-state.interface';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {

  private sideMenuStateSource$ = new Subject<SideMenuState>();
  sideMenuState$ = this.sideMenuStateSource$
    .asObservable()
    .pipe(
      distinctUntilChanged((previous, next) => {
        return previous.state === next.state &&
          (previous.assignment == null || next.assignment == null || (previous.assignment.id === next.assignment.id)) &&
          previous.currentSubRoute === next.currentSubRoute
      })
    );

  private links$ = new BehaviorSubject([
    {
      routerLink: '/dashboard',
      label: 'Dashboard incarichi',
      complete: true
    },
    {
      routerLink: '/assignments',
      label: 'Archivio incarichi',
      complete: true
    }
  ]);

  set sideMenuState(newState: SideMenuState) {
    this.sideMenuStateSource$.next(newState);
  }
}
