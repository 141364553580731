import { Injectable } from '@angular/core';
import { SebastianDb } from './sebastian-db';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdbWrapperService {
  db: SebastianDb;

  constructor() {
    this.db = new SebastianDb();
  }

  all(store, sortBy?: string): Observable<any> {
    if (sortBy) {
      return fromPromise(this.db[store].toCollection().sortBy(sortBy));
    } else {
      return fromPromise(this.db[store].toArray());
    }
  }

  get(store: string, id: number): Observable<any> {
    return fromPromise(this.db[store].get(id));
  }

  add(store: string, item: any): Observable<any> {
    return fromPromise(this.db[store].add(item));
  }

  bulkAdd(store: string, items: any[]): Observable<any> {
    return fromPromise(this.db[store].bulkAdd(items));
  }

  update(store: string, id: number, changes: any): Observable<any> {
    return fromPromise(this.db[store].update(id, changes));
  }

  delete(store: string, id: number): Observable<any> {
    return fromPromise(this.db[store].delete(id));
  }

  clear(store: string): Observable<any> {
    return fromPromise(this.db[store].clear());
  }

  where(store: string, condition: object): Observable<any> {
    return fromPromise(this.db[store].where(condition));
  }
}
