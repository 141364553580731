<div class="audit" [@.disabled]="!animate">
  <h2 class="audit__title">{{ currentSection?.name }}</h2>
  <div class="audit__loading" *ngIf="!subsections.length">
    <div class="spinner"></div>
  </div>
  <div id="subsection-{{subsection.id}}" class="audit__subsection"
       *ngFor="let subsection of subsections; trackBy: subsectionTrackBy; let subsectionIndex = index">
    <h4 class="audit__subtitle">{{subsection.name}}</h4>
    <ng-container *ngFor="let question of subsection.questions; trackBy: questionTrackBy; let questionIndex = index">
      <div [@collapse] *ngIf="question.questionType !== 'group_question' && question.unlocked && !(assignment.status === 'inspection' && question.processingOnly)">
        <question-card [question]="question"
                       [answer]="question.answer"
                       [assignment]="assignment"
                       [viewMode]="userService.user?.role === 'clerk' || this.assignment.status === 'completed'"
                       [user]="userService.user"
                       (answerChange)="onQuestionChange($event)"
                       (pictureAdd)="onPictureAdd($event, question)"
                       (pictureDelete)="onPictureDelete($event)"
                       (pictureEdit)="onPictureEdit($event)"
                       (pictureOpen)="onPictureOpen($event)"
                       (attachmentAdd)="onAttachmentAdd($event, question)"
                       (attachmentDownload)="onAttachmentDownload($event)"
                       (attachmentDelete)="onAttachmentDelete($event)"
                       (requiredAttachmentAdd)="onRequiredAttachmentAdd($event, question)"
                       (requiredAttachmentEdit)="onRequiredAttachmentEdit($event)"
                       (requiredAttachmentDelete)="onRequiredAttachmentDelete($event)"
                       [shouldFocus]="subsectionIndex === 0 && questionIndex === 0"></question-card>
      </div>
      <div [@collapse] *ngIf="question.questionType === 'group_question' && !(assignment.status === 'inspection' && question.processingOnly)">
        <group-question [question]="question"
                        [assignment]="assignment"
                        [viewMode]="userService.user?.role === 'clerk' || this.assignment.status === 'completed'"
                        [user]="userService.user"
                        (parentAnswerCreate)="onParentAnswerCreate(question, $event)"
                        (parentAnswerDelete)="onParentAnswerDelete(question, $event)"
                        (childAnswerChange)="onChildQuestionChange($event)"
                        (pictureAdd)="onChildPictureAdd($event)"
                        (pictureDelete)="onPictureDelete($event)"
                        (pictureEdit)="onPictureEdit($event)"
                        (pictureOpen)="onPictureOpen($event)"></group-question>
      </div>
    </ng-container>
  </div>
  <notes *ngIf="assignment" [assignment]="assignment" [viewMode]="userService.user?.role === 'clerk' || this.assignment.status === 'completed'" (changed)="onNotesChange($event)"></notes>
  <last-saved *ngIf="subsections.length" [saveState]="saving$ | async"></last-saved>
</div>
<fullscreen-image [image]="openPicture" (close)="onPictureClose()"></fullscreen-image>
