import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { RiskScore } from './risk-score';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var RiskScoresService = /** @class */ (function () {
    function RiskScoresService(http) {
        this.http = http;
    }
    RiskScoresService.prototype.getRiskScores = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/risk_scores").pipe(map(function (res) { return res.risk_scores.map(function (riskScore) { return new RiskScore(riskScore); }); }));
    };
    RiskScoresService.prototype.updateRiskScore = function (riskScore) {
        var bodyString = JSON.stringify({
            risk_score: {
                magnitude_score: riskScore.magnitudeScore,
                probability_score: riskScore.probabilityScore,
                notes: riskScore.notes,
            }
        });
        return this.http.put("risk_scores/" + riskScore.id, bodyString).pipe(map(function (res) { return new RiskScore(res); }));
    };
    RiskScoresService.ngInjectableDef = i0.defineInjectable({ factory: function RiskScoresService_Factory() { return new RiskScoresService(i0.inject(i1.ApiHttpClientService)); }, token: RiskScoresService, providedIn: "root" });
    return RiskScoresService;
}());
export { RiskScoresService };
