import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef }                         from '@angular/material';

@Component({
  selector: 'notes-sheet',
  templateUrl: './notes-sheet.html',
  styleUrls: ['./notes-sheet.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesSheetComponent {

  @Output() changedEmitter = new EventEmitter();

  constructor(
    public sheetRef: MatBottomSheetRef<NotesSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
  }

  onChange(event) {
    this.changedEmitter.emit(event);
  }

}
