import { NgModule }           from '@angular/core';
import { LastSavedComponent } from './last-saved.component';
import { MomentModule }       from 'ngx-moment';
import { CommonModule }       from '@angular/common';

@NgModule({
  imports: [
    MomentModule,
    CommonModule
  ],
  declarations: [LastSavedComponent],
  exports: [LastSavedComponent]
})
export class LastSavedModule { }
