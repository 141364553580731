import { map } from 'rxjs/operators';
import { Subsection } from './subsection';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var SubsectionsService = /** @class */ (function () {
    function SubsectionsService(http) {
        this.http = http;
    }
    SubsectionsService.prototype.getSubsections = function () {
        return this.http.get('subsections').pipe(map(function (res) { return res.subsections.map(function (subsection) { return new Subsection(subsection); }); }));
    };
    SubsectionsService.ngInjectableDef = i0.defineInjectable({ factory: function SubsectionsService_Factory() { return new SubsectionsService(i0.inject(i1.ApiHttpClientService)); }, token: SubsectionsService, providedIn: "root" });
    return SubsectionsService;
}());
export { SubsectionsService };
