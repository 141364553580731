var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { SideMenuService } from './side-menu.service';
import { combineLatest, of } from 'rxjs';
import { IdbWrapperService } from '../../idb-wrapper/idb-wrapper.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NetStatusService } from '../../net-status/net-status.service';
import { SyncService } from '../../sync/sync.service';
import { ToasterService } from '../../toaster/toaster.service';
import { AssignmentsService } from '../../models/assignment/assignments.service';
import { AuditRefreshService } from '../../../assignments/audit/audit-refresh.service';
import { DialogService } from '../../dialog/dialog.service';
import { UtilsService } from '../../utils/utils.service';
import { UserService } from '../../user/user.service';
var SideMenuComponent = /** @class */ (function () {
    function SideMenuComponent(sideMenuService, idbWrapper, netStatus, sync, toaster, assignmentsService, auditRefresh, dialog, utils, userService) {
        var _this = this;
        this.sideMenuService = sideMenuService;
        this.idbWrapper = idbWrapper;
        this.netStatus = netStatus;
        this.sync = sync;
        this.toaster = toaster;
        this.assignmentsService = assignmentsService;
        this.auditRefresh = auditRefresh;
        this.dialog = dialog;
        this.utils = utils;
        this.userService = userService;
        this.links = [];
        this.syncingAssignment = false;
        this.changingAssignmentStatus = false;
        this.sideMenuStateSubscription = this.sideMenuService.sideMenuState$.subscribe(function (sideMenuState) {
            var currentState = __assign({}, _this.currentState);
            switch (sideMenuState.state) {
                case 'root':
                    if (!currentState || currentState.state !== 'root') {
                        _this.linksLoading = true;
                        setTimeout(function () {
                            _this.links = [
                                {
                                    label: 'Dashboard incarichi',
                                    routerLink: '/dashboard'
                                },
                                {
                                    label: 'Archivio incarichi',
                                    routerLink: '/assignments'
                                }
                            ];
                            _this.linksLoading = false;
                        }, currentState && currentState.state === 'sections' ? 150 : 0);
                    }
                    break;
                case 'assignment':
                    if (!currentState || currentState.state !== 'assignment' || !currentState.assignment || currentState.assignment.id !== sideMenuState.assignment.id) {
                        _this.linksLoading = true;
                        setTimeout(function () {
                            if (sideMenuState.currentSubRoute) {
                                if (currentState.state === 'sections') {
                                    _this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId).subscribe(function (sources) {
                                        _this.links = _this.buildAssignmentLinks(sideMenuState.assignment, sideMenuState.currentSubRoute, sources[0].id);
                                        _this.linksLoading = false;
                                    });
                                }
                                else if (currentState.state === 'riskScores') {
                                    _this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status)
                                        .pipe(map(function (_a) {
                                        var sections = _a[0];
                                        return sections;
                                    }))
                                        .subscribe(function (sections) {
                                        _this.links = _this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sideMenuState.currentSubRoute);
                                        _this.linksLoading = false;
                                    });
                                }
                                else if (currentState.state === 'attachments') {
                                    combineLatest([
                                        _this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).pipe(map(function (_a) {
                                            var sections = _a[0];
                                            return sections;
                                        })),
                                        _this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId)
                                    ]).subscribe(function (_a) {
                                        var sections = _a[0], sources = _a[1];
                                        _this.links = _this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sources[0].id, sideMenuState.currentSubRoute);
                                        _this.linksLoading = false;
                                    });
                                }
                            }
                            else {
                                combineLatest([
                                    _this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).pipe(map(function (_a) {
                                        var sections = _a[0];
                                        return sections;
                                    })),
                                    _this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId)
                                ]).subscribe(function (_a) {
                                    var sections = _a[0], sources = _a[1];
                                    _this.links = _this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sources[0].id);
                                    _this.linksLoading = false;
                                });
                            }
                        }, currentState && currentState.state === 'sections' ? 150 : 0);
                    }
                    else {
                        _this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status)
                            .pipe(map(function (_a) {
                            var sections = _a[0];
                            return sections;
                        }))
                            .subscribe(function (sections) { return _this.links[1].routerLink = ['assignments', sideMenuState.assignment.id, 'audit', sections[0].id]; });
                    }
                    break;
                case 'sections':
                    if (currentState && currentState.state === 'sections' && currentState.assignment.id === sideMenuState.assignment.id) {
                        _this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
                        _this.links.forEach(function (link) {
                            link.expanded = link.sectionId === sideMenuState.currentSubRoute;
                        });
                    }
                    else {
                        _this.linksLoading = true;
                        _this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).subscribe(function (_a) {
                            var sections = _a[0], subsections = _a[1];
                            _this.links = [
                                {
                                    label: 'Raccolta dati',
                                    nextState: {
                                        state: 'assignment',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: sideMenuState.currentSubRoute
                                    },
                                    prefixIcon: 'arrow_back'
                                }
                            ].concat(sections.map(function (section) { return ({
                                label: section.name,
                                sectionId: section.id,
                                routerLink: ['assignments', sideMenuState.assignment.id, 'audit', section.id],
                                nextState: { state: 'sections', assignment: sideMenuState.assignment, currentSubRoute: section.id },
                                expanded: section.id === sideMenuState.currentSubRoute,
                                children: subsections
                                    .filter(function (subsection) { return subsection.sectionId === section.id; })
                                    .map(function (subsection) { return ({
                                    label: subsection.name,
                                    subsectionId: subsection.id
                                }); })
                            }); }));
                            _this.linksLoading = false;
                        });
                    }
                    break;
                case 'riskScores':
                    if (currentState && currentState.state === 'riskScores' && currentState.assignment && currentState.assignment.id === sideMenuState.assignment.id) {
                        _this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
                    }
                    else {
                        _this.linksLoading = true;
                        _this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId).subscribe(function (sources) {
                            _this.links = [
                                {
                                    label: 'Punteggi di rischio',
                                    nextState: {
                                        state: 'assignment',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: sideMenuState.currentSubRoute
                                    },
                                    prefixIcon: 'arrow_back'
                                }
                            ].concat(sources.map(function (source) { return ({
                                label: source.name,
                                routerLink: ['assignments', sideMenuState.assignment.id, 'risk-scores', source.id],
                                nextState: { state: 'riskScores', assignment: sideMenuState.assignment, currentSubRoute: source.id }
                            }); }));
                            _this.linksLoading = false;
                        });
                    }
                    break;
                case 'attachments':
                    if (currentState && currentState.state === 'attachments' && currentState.assignment.id === sideMenuState.assignment.id) {
                        _this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
                    }
                    else {
                        _this.linksLoading = true;
                        setTimeout(function () {
                            var links = [
                                {
                                    label: 'Documentazione',
                                    nextState: {
                                        state: 'assignment',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: sideMenuState.currentSubRoute
                                    },
                                    prefixIcon: 'arrow_back'
                                },
                                {
                                    label: 'Allegati',
                                    routerLink: ['assignments', sideMenuState.assignment.id, 'required-attachments'],
                                    nextState: {
                                        state: 'attachments',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: 'required-attachments'
                                    }
                                }
                            ];
                            if (sideMenuState.assignment.status !== 'inspection') {
                                links.push({
                                    label: 'Fotogrammi',
                                    routerLink: ['assignments', sideMenuState.assignment.id, 'pictures'],
                                    nextState: {
                                        state: 'attachments',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: 'pictures'
                                    }
                                });
                                links.push({
                                    label: 'Altri documenti',
                                    routerLink: ['assignments', sideMenuState.assignment.id, 'attachments'],
                                    nextState: {
                                        state: 'attachments',
                                        assignment: sideMenuState.assignment,
                                        currentSubRoute: 'attachments'
                                    }
                                });
                            }
                            _this.links = links;
                            _this.linksLoading = false;
                        }, 150);
                    }
                    break;
            }
            _this.currentState = sideMenuState;
            console.debug(currentState, '->', _this.currentState);
        });
        this.sideMenuStateSubscription2 = this.sideMenuService.sideMenuState$.pipe(map(function (sideMenuState) { return sideMenuState.assignment; }), switchMap(function (assignment) {
            if (assignment) {
                return _this.idbWrapper.get('assignments', assignment.id).pipe(map(function (offlineAssignment) { return ([assignment, offlineAssignment]); }));
            }
            else {
                return of([null, null]);
            }
        }), map(function (_a) {
            var assignment = _a[0], offlineAssignment = _a[1];
            if (offlineAssignment) {
                assignment.offline = true;
            }
            return assignment;
        }), catchError(function () { return of(null); })).subscribe(function (assignment) { return _this.assignment = assignment; });
    }
    SideMenuComponent.prototype.buildAssignmentLinks = function (assignment, currentSectionId, currentSourceId, currentAttachmentRoute) {
        if (currentAttachmentRoute === void 0) { currentAttachmentRoute = 'required-attachments'; }
        var links = [
            {
                label: 'Dettagli',
                routerLink: ['assignments', assignment.id]
            },
            {
                label: 'Raccolta dati',
                routerLink: ['assignments', assignment.id, 'audit', currentSectionId],
                nextState: {
                    state: 'sections',
                    assignment: assignment,
                    currentSubRoute: currentSectionId
                },
                suffixIcon: 'arrow_forward'
            }
        ];
        if (assignment.status !== 'inspection') {
            links.push({
                label: 'Punteggi di rischio',
                routerLink: ['assignments', assignment.id, 'risk-scores', currentSourceId],
                nextState: {
                    state: 'riskScores',
                    assignment: assignment,
                    currentSubRoute: currentSourceId
                },
                suffixIcon: 'arrow_forward'
            });
        }
        links.push({
            label: 'Documentazione',
            routerLink: ['assignments', assignment.id, currentAttachmentRoute],
            nextState: {
                state: 'attachments',
                assignment: assignment,
                currentSubRoute: currentAttachmentRoute
            },
            suffixIcon: 'arrow_forward'
        });
        return links;
    };
    SideMenuComponent.prototype.onLinkClick = function (link) {
        if (link.nextState) {
            this.sideMenuService.sideMenuState = link.nextState;
        }
    };
    SideMenuComponent.prototype.onInspectionClose = function () {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.choiceDialog({
                            title: 'Conferma azione',
                            body: 'Confermi la chiusura del sopralluogo?',
                            placeholder: 'Scegli un revisore'
                        }).toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (!!confirm) {
                            this.assignmentSyncProgress = 0;
                            this.syncingAssignment = true;
                            this.changingAssignmentStatus = false;
                            this.sync.syncAssignment(this.assignment).subscribe(function (total) { return _this.assignmentSyncProgress = total; }, function () {
                                _this.toaster.displayError('Errore nella sincronizzazione delle risposte.');
                                _this.syncingAssignment = false;
                            }, function () {
                                setTimeout(function () {
                                    _this.changingAssignmentStatus = true;
                                    _this.assignmentsService.closeInspection(_this.assignment.id, confirm.id).subscribe(function (assignment) {
                                        _this.assignment = assignment;
                                        _this.updateLinksAssignment();
                                        _this.syncingAssignment = false;
                                        _this.auditRefresh.requestAuditRefresh();
                                    }, function () {
                                        _this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
                                        _this.syncingAssignment = false;
                                    });
                                }, 500);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SideMenuComponent.prototype.onProcessingClose = function () {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.confirmDialog('Confermi la chiusura dell\'elaborazione?').toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (!!confirm) {
                            this.changingAssignmentStatus = true;
                            this.syncingAssignment = true;
                            this.assignmentsService.setToUnderRevision(this.assignment.id).subscribe(function (assignment) {
                                _this.assignment = assignment;
                                _this.updateLinksAssignment();
                                _this.syncingAssignment = false;
                                _this.changingAssignmentStatus = true;
                                _this.auditRefresh.requestAuditRefresh();
                            }, function () {
                                _this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
                                _this.syncingAssignment = false;
                                _this.changingAssignmentStatus = true;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SideMenuComponent.prototype.onCompletedReopen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.confirmDialog('Confermi la riapertura dell\'incarico?').toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.changingAssignmentStatus = true;
                            this.syncingAssignment = true;
                            this.assignmentsService.setToUnderRevision(this.assignment.id).subscribe(function (assignment) {
                                _this.assignment = assignment;
                                _this.updateLinksAssignment();
                                _this.syncingAssignment = false;
                                _this.changingAssignmentStatus = true;
                                _this.auditRefresh.requestAuditRefresh();
                            }, function () {
                                _this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
                                _this.syncingAssignment = false;
                                _this.changingAssignmentStatus = true;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SideMenuComponent.prototype.updateLinksAssignment = function () {
        var _this = this;
        this.links.forEach(function (link) {
            if (link.nextState && link.nextState.assignment) {
                link.nextState.assignment = _this.assignment;
            }
        });
    };
    SideMenuComponent.prototype.onChildClick = function (subsectionId) {
        var el = document.querySelector("#subsection-" + subsectionId + " .focusable");
        if (el) {
            // @ts-ignore
            el.focus({ preventScroll: true });
        }
    };
    SideMenuComponent.prototype.ngOnInit = function () {
    };
    SideMenuComponent.prototype.ngOnDestroy = function () {
        this.sideMenuStateSubscription.unsubscribe();
        this.sideMenuStateSubscription2.unsubscribe();
    };
    return SideMenuComponent;
}());
export { SideMenuComponent };
