import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question }                                       from '../../../core/models/question/question';
import { Answer }                                         from '../../../core/models/answer/answer';
import { Assignment }                                     from '../../../core/models/assignment/assignment';
import { FormControl }                                    from '@angular/forms';
import { QuestionChanges }                                from '../question-card/question-card.component';
import { DialogService }                                  from '../../../core/dialog/dialog.service';
import { Picture }                                        from '../../../core/models/picture/picture';
import { collapse }                                       from '../../../shared/animations/collapse.animation';

@Component({
  selector: 'group-question',
  templateUrl: './group-question.component.html',
  styleUrls: ['./group-question.component.scss'],
  animations: [collapse]
})
export class GroupQuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() assignment: Assignment;
  @Input() viewMode;
  @Input() user;
  @Output() parentAnswerCreate = new EventEmitter();
  @Output() parentAnswerDelete = new EventEmitter();
  @Output() childAnswerChange = new EventEmitter();
  @Output() pictureAdd = new EventEmitter();
  @Output() pictureOpen = new EventEmitter();
  @Output() pictureDelete = new EventEmitter();
  @Output() pictureEdit = new EventEmitter();
  @Output() focus = new EventEmitter();
  selectedTab = new FormControl(0);

  constructor(private dialog: DialogService) { }

  ngOnInit() {
    this.focus.emit(this.question);
  }

  addParentAnswer() {
    const newAnswer = new Answer({});
    newAnswer.questionId = this.question.id;
    newAnswer.assignmentId = this.assignment.id;
    this.question.groupAnswers.push(newAnswer);
    this.selectedTab.setValue(this.question.groupAnswers.length - 1);
    this.parentAnswerCreate.emit(newAnswer);
  }

  async deleteParentAnswer() {
    const confirm = await this.dialog.confirmDialog('Confermi l\'eliminazione della riga?').toPromise();

    if (confirm) {
      this.parentAnswerDelete.emit(this.question.groupAnswers[this.selectedTab.value]);
    }
  }

  getChildAnswer(question, parentAnswer) {
    if (!parentAnswer || !parentAnswer.childAnswers) {
      return null;
    }
    return parentAnswer.childAnswers.find(childAnswer => childAnswer.questionId === question.id);
  }

  onAnswerChange(event: { changes: QuestionChanges, question: Question }, parentAnswer: Answer) {
    event.changes.parentAnswerId = this.question.groupAnswers[this.selectedTab.value].id;
    this.childAnswerChange.emit({
      changes: event.changes,
      parentAnswer
    });
  }

  onPictureAdd(pictureData, question, parentAnswer) {
    this.pictureAdd.emit({
      pictureData,
      question,
      parentAnswer
    });
  }

  onPictureOpen(picture: Picture) {
    this.pictureOpen.emit(picture);
  }

  onPictureDelete(event: {picture: Picture, answer: Answer}) {
    this.pictureDelete.emit(event);
  }

  onPictureEdit(event: {picture: Picture, answer: Answer}) {
    this.pictureEdit.emit(event);
  }

  questionTrackBy(index, item: Question) {
    return item.id;
  }
}
