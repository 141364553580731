<div mat-dialog-title>
  <h4>{{data.title}}</h4>
</div>
<mat-dialog-content>
  <p>{{data.body}}</p>
  <user-autocomplete-form-field [user]="data.user || {}" [placeholder]="data.placeholder" (changed)="onUserChanged($event)"></user-autocomplete-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Annulla</button>
  <button mat-button [mat-dialog-close]="user" tabindex="1">Ok</button>
</mat-dialog-actions>

