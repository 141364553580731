import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { RequiredAttachment } from './required-attachment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var RequiredAttachmentService = /** @class */ (function () {
    function RequiredAttachmentService(http) {
        this.http = http;
    }
    RequiredAttachmentService.prototype.getRequiredAttachments = function (assignmentId) {
        var requestObs;
        if (assignmentId) {
            requestObs = this.http.get("assignments/" + assignmentId + "/required_attachments");
        }
        else {
            requestObs = this.http.get("required_attachments");
        }
        return requestObs.pipe(map(function (res) { return res.required_attachments.map(function (requiredAttachments) { return new RequiredAttachment(requiredAttachments); }); }));
    };
    RequiredAttachmentService.prototype.createRequiredAttachment = function (requiredAttachment) {
        var bodyString = JSON.stringify({
            required_attachment: {
                question_id: requiredAttachment.questionId,
                description: requiredAttachment.description
            }
        });
        return this.http.post("assignments/" + requiredAttachment.assignmentId + "/required_attachments", bodyString).pipe(map(function (res) { return new RequiredAttachment(res); }));
    };
    RequiredAttachmentService.prototype.updateRequiredAttachment = function (requiredAttachment) {
        var bodyString = JSON.stringify({
            required_attachment: {
                description: requiredAttachment.description
            }
        });
        return this.http.put("required_attachments/" + requiredAttachment.id, bodyString).pipe(map(function (res) { return new RequiredAttachment(res); }));
    };
    RequiredAttachmentService.prototype.deleteRequiredAttachment = function (requiredAttachment) {
        return this.http.delete("required_attachments/" + requiredAttachment.id);
    };
    RequiredAttachmentService.ngInjectableDef = i0.defineInjectable({ factory: function RequiredAttachmentService_Factory() { return new RequiredAttachmentService(i0.inject(i1.ApiHttpClientService)); }, token: RequiredAttachmentService, providedIn: "root" });
    return RequiredAttachmentService;
}());
export { RequiredAttachmentService };
