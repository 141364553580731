<div class="risk-scores">
  <div class="risk-scores__header">
    <h4 class="risk-scores__header__title">Punteggi di rischio</h4>
    <div class="assignment__header__actions">
      <button mat-button
              class="risk-scores__header__button sebastian-button sebastian-button--primary"
              (click)="onComputeScores()">
        Calcola punteggi
      </button>
    </div>
  </div>
  <div class="risk-scores__header__loading" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <div class="risk-scores__scores-table" *ngIf="!loading">
    <div class="risk-scores__scores-table__caption">{{currentSource?.name}}</div>
    <div class="risk-scores__scores-table__heading">
      <div class="risk-scores__scores-table__heading__cell target">Bersaglio</div>
      <div class="risk-scores__scores-table__heading__cell score">Probabilità</div>
      <div class="risk-scores__scores-table__heading__cell score">Magnitudo</div>
      <div class="risk-scores__scores-table__heading__cell notes">Note</div>
    </div>
    <div class="risk-scores__scores-table__row" *ngFor="let scoreMapping of scoreMappings; let i = index">
      <risk-score-row [(scoreMapping)]="scoreMappings[i]" [viewMode]="getViewMode()" (scoreMappingChange)="onChange($event)"></risk-score-row>
    </div>
  </div>
  <last-saved [saveState]="saving$ | async"></last-saved>
  <notes *ngIf="assignment" [assignment]="assignment" [viewMode]="getViewMode()" (changed)="onNotesChange($event)"></notes>
</div>
