import { NgModule }                        from '@angular/core';
import { PasswordChangeComponent }         from './password-change.component';
import { SharedModule }                    from '../shared/shared.module';
import { MatButtonModule, MatInputModule } from '@angular/material';
import { PasswordChangeGuard } from './password-change.guard';

@NgModule({
  imports: [
    SharedModule,
    MatButtonModule,
    MatInputModule
  ],
  declarations: [PasswordChangeComponent],
  providers: [PasswordChangeGuard]
})
export class PasswordChangeModule {
}
