import { NgModule }                                                      from '@angular/core';
import { CommonModule }                                                  from '@angular/common';
import { CustomTableComponent }                                          from './custom-table.component';
import { MatButtonModule, MatIconModule, MatSlideToggleModule, MatSortModule, MatTableModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule
  ],
  declarations: [CustomTableComponent],
  exports: [CustomTableComponent]
})
export class CustomTableModule { }
