import { Component, OnDestroy, OnInit }    from '@angular/core';
import { SideMenuService }                 from './side-menu.service';
import { SideMenuState }                   from './side-menu-state.interface';
import { combineLatest, of, Subscription } from 'rxjs';
import { SideMenuLink }                    from './side-menu-link.interface';
import { IdbWrapperService }               from '../../idb-wrapper/idb-wrapper.service';
import { Section }                         from '../../models/section/section';
import { Subsection }                      from '../../models/subsection/subsection';
import { Assignment }                      from '../../models/assignment/assignment';
import { catchError, map, switchMap }      from 'rxjs/operators';
import { NetStatusService }                from '../../net-status/net-status.service';
import { collapse }                        from '../../../shared/animations/collapse.animation';
import { fade }                            from '../../../shared/animations/fade.animation';
import { SyncService }                     from '../../sync/sync.service';
import { ToasterService }                  from '../../toaster/toaster.service';
import { AssignmentsService }              from '../../models/assignment/assignments.service';
import { AuditRefreshService }             from '../../../assignments/audit/audit-refresh.service';
import { DialogService }                   from '../../dialog/dialog.service';
import { Source }                          from '../../models/source/source';
import { UtilsService }                    from '../../utils/utils.service';
import { UserService }                     from '../../user/user.service';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [collapse, fade]
})
/*
Saying that this component needs a refactor would be a huge, undeservedly flattering understatement; it needs a fucking overhaul.
This is bodging at its finest, held together with spit and prone to break at the slightest change: if I die tomorrow, don't remember me for this.
 */
export class SideMenuComponent implements OnInit, OnDestroy {
  links: SideMenuLink[] = [];
  assignment: Assignment;
  currentState: SideMenuState;
  linksLoading: boolean;

  sideMenuStateSubscription: Subscription;
  sideMenuStateSubscription2: Subscription;

  assignmentSyncProgress: number;
  syncingAssignment = false;
  changingAssignmentStatus = false;

  constructor(private sideMenuService: SideMenuService,
              private idbWrapper: IdbWrapperService,
              public netStatus: NetStatusService,
              private sync: SyncService,
              private toaster: ToasterService,
              private assignmentsService: AssignmentsService,
              private auditRefresh: AuditRefreshService,
              private dialog: DialogService,
              private utils: UtilsService,
              public userService: UserService) {

    this.sideMenuStateSubscription = this.sideMenuService.sideMenuState$.subscribe((sideMenuState: SideMenuState) => {
      const currentState = {...this.currentState};

      switch (sideMenuState.state) {
        case 'root':
          if (!currentState || currentState.state !== 'root') {
            this.linksLoading = true;

            setTimeout(() => {
              this.links = [
                {
                  label: 'Dashboard incarichi',
                  routerLink: '/dashboard'
                },
                {
                  label: 'Archivio incarichi',
                  routerLink: '/assignments'
                }
              ];
              this.linksLoading = false;
            }, currentState && currentState.state === 'sections' ? 150 : 0);
          }
          break;

        case 'assignment':
          if (!currentState || currentState.state !== 'assignment' || !currentState.assignment || currentState.assignment.id !== sideMenuState.assignment.id) {
            this.linksLoading = true;
            setTimeout(() => {
              if (sideMenuState.currentSubRoute) {
                if (currentState.state === 'sections') {
                  this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId).subscribe((sources: Source[]) => {
                    this.links = this.buildAssignmentLinks(sideMenuState.assignment, sideMenuState.currentSubRoute as number, sources[0].id);
                    this.linksLoading = false;
                  });
                } else if (currentState.state === 'riskScores') {
                  this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status)
                    .pipe(map(([sections]) => sections))
                    .subscribe((sections: Section[]) => {
                      this.links = this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sideMenuState.currentSubRoute as number);
                      this.linksLoading = false;
                    });
                } else if (currentState.state === 'attachments') {
                  combineLatest([
                    this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).pipe(map(([sections]) => sections)),
                    this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId)
                  ]).subscribe(([sections, sources]: [Section[], Source[]]) => {
                    this.links = this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sources[0].id, sideMenuState.currentSubRoute as string);
                    this.linksLoading = false;
                  });
                }
              } else {
                combineLatest([
                  this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).pipe(map(([sections]) => sections)),
                  this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId)
                ]).subscribe(([sections, sources]: [Section[], Source[]]) => {
                  this.links = this.buildAssignmentLinks(sideMenuState.assignment, sections[0].id, sources[0].id);
                  this.linksLoading = false;
                });
              }
            }, currentState && currentState.state === 'sections' ? 150 : 0);
          } else {
            this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status)
              .pipe(map(([sections]) => sections))
              .subscribe((sections: Section[]) => this.links[1].routerLink = ['assignments', sideMenuState.assignment.id, 'audit', sections[0].id]);
          }
          break;

        case 'sections':
          if (currentState && currentState.state === 'sections' && currentState.assignment.id === sideMenuState.assignment.id) {
            this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
            this.links.forEach((link: SideMenuLink) => {
              link.expanded = link.sectionId === sideMenuState.currentSubRoute;
            });
          } else {
            this.linksLoading = true;
            this.utils.getFilteredSectionsTree(sideMenuState.assignment.factoryTypeId, sideMenuState.assignment.status).subscribe(([sections, subsections]: [Section[], Subsection[]]) => {
              this.links = [
                {
                  label: 'Raccolta dati',
                  nextState: {
                    state: 'assignment',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: sideMenuState.currentSubRoute
                  },
                  prefixIcon: 'arrow_back'
                },
                ...sections.map((section: Section) => ({
                  label: section.name,
                  sectionId: section.id,
                  routerLink: ['assignments', sideMenuState.assignment.id, 'audit', section.id],
                  nextState: {state: 'sections', assignment: sideMenuState.assignment, currentSubRoute: section.id} as SideMenuState,
                  expanded: section.id === sideMenuState.currentSubRoute,
                  children: subsections
                    .filter((subsection: Subsection) => subsection.sectionId === section.id)
                    .map((subsection: Subsection) => ({
                      label: subsection.name,
                      subsectionId: subsection.id
                    }))
                }))
              ];
              this.linksLoading = false;
            });
          }
          break;

        case 'riskScores':
          if (currentState && currentState.state === 'riskScores' && currentState.assignment && currentState.assignment.id === sideMenuState.assignment.id) {
            this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
          } else {
            this.linksLoading = true;
            this.utils.getFilteredSources(sideMenuState.assignment.factoryTypeId).subscribe((sources: Source[]) => {
              this.links = [
                {
                  label: 'Punteggi di rischio',
                  nextState: {
                    state: 'assignment',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: sideMenuState.currentSubRoute
                  },
                  prefixIcon: 'arrow_back'
                },
                ...sources.map((source: Source) => ({
                  label: source.name,
                  routerLink: ['assignments', sideMenuState.assignment.id, 'risk-scores', source.id],
                  nextState: {state: 'riskScores', assignment: sideMenuState.assignment, currentSubRoute: source.id} as SideMenuState
                }))
              ];
              this.linksLoading = false;
            });
          }
          break;

        case 'attachments':
          if (currentState && currentState.state === 'attachments' && currentState.assignment.id === sideMenuState.assignment.id) {
            this.links[0].nextState.currentSubRoute = sideMenuState.currentSubRoute;
          } else {
            this.linksLoading = true;
            setTimeout(() => {
              const links: SideMenuLink[] = [
                {
                  label: 'Documentazione',
                  nextState: {
                    state: 'assignment',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: sideMenuState.currentSubRoute
                  },
                  prefixIcon: 'arrow_back'
                },
                {
                  label: 'Allegati',
                  routerLink: ['assignments', sideMenuState.assignment.id, 'required-attachments'],
                  nextState: {
                    state: 'attachments',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: 'required-attachments'
                  }
                }];
              if (sideMenuState.assignment.status !== 'inspection') {
                links.push({
                  label: 'Fotogrammi',
                  routerLink: ['assignments', sideMenuState.assignment.id, 'pictures'],
                  nextState: {
                    state: 'attachments',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: 'pictures'
                  }
                });
                links.push({
                  label: 'Altri documenti',
                  routerLink: ['assignments', sideMenuState.assignment.id, 'attachments'],
                  nextState: {
                    state: 'attachments',
                    assignment: sideMenuState.assignment,
                    currentSubRoute: 'attachments'
                  }
                });
              }
              this.links = links;
              this.linksLoading = false;

            }, 150);
          }
          break;
      }

      this.currentState = sideMenuState;
      console.debug(currentState, '->', this.currentState);
    });

    this.sideMenuStateSubscription2 = this.sideMenuService.sideMenuState$.pipe(
      map((sideMenuState: SideMenuState) => sideMenuState.assignment),
      switchMap((assignment: Assignment) => {
        if (assignment) {
          return this.idbWrapper.get('assignments', assignment.id).pipe(
            map(offlineAssignment => ([assignment, offlineAssignment]))
          );
        } else {
          return of([null, null]);
        }
      }),
      map(([assignment, offlineAssignment]) => {
        if (offlineAssignment) {
          assignment.offline = true;
        }

        return assignment;
      }),
      catchError(() => of(null))
    ).subscribe(assignment => this.assignment = assignment);
  }


  private buildAssignmentLinks(
    assignment: Assignment,
    currentSectionId: number,
    currentSourceId: number,
    currentAttachmentRoute: string = 'required-attachments'
  ): SideMenuLink[] {
    const links: SideMenuLink[] = [
      {
        label: 'Dettagli',
        routerLink: ['assignments', assignment.id]
      },
      {
        label: 'Raccolta dati',
        routerLink: ['assignments', assignment.id, 'audit', currentSectionId],
        nextState: {
          state: 'sections',
          assignment: assignment,
          currentSubRoute: currentSectionId
        },
        suffixIcon: 'arrow_forward'
      }
    ];
    if (assignment.status !== 'inspection') {
      links.push({
        label: 'Punteggi di rischio',
        routerLink: ['assignments', assignment.id, 'risk-scores', currentSourceId],
        nextState: {
          state: 'riskScores',
          assignment: assignment,
          currentSubRoute: currentSourceId
        },
        suffixIcon: 'arrow_forward'
      });
    }
    links.push({
      label: 'Documentazione',
      routerLink: ['assignments', assignment.id, currentAttachmentRoute],
      nextState: {
        state: 'attachments',
        assignment: assignment,
        currentSubRoute: currentAttachmentRoute
      },
      suffixIcon: 'arrow_forward'
    });

    return links;
  }

  onLinkClick(link: SideMenuLink) {
    if (link.nextState) {
      this.sideMenuService.sideMenuState = link.nextState;
    }
  }

  async onInspectionClose() {
    const confirm = await this.dialog.choiceDialog({
      title: 'Conferma azione',
      body: 'Confermi la chiusura del sopralluogo?',
      placeholder: 'Scegli un revisore'
    }).toPromise();

    if (!!confirm) {
      this.assignmentSyncProgress = 0;
      this.syncingAssignment = true;
      this.changingAssignmentStatus = false;

      this.sync.syncAssignment(this.assignment).subscribe(
        total => this.assignmentSyncProgress = total,
        () => {
          this.toaster.displayError('Errore nella sincronizzazione delle risposte.');
          this.syncingAssignment = false;
        },
        () => {
          setTimeout(() => {
            this.changingAssignmentStatus = true;
            this.assignmentsService.closeInspection(this.assignment.id, confirm.id).subscribe(
              assignment => {
                this.assignment = assignment;
                this.updateLinksAssignment();
                this.syncingAssignment = false;
                this.auditRefresh.requestAuditRefresh();
              },
              () => {
                this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
                this.syncingAssignment = false;
              }
            );
          }, 500);
        }
      );
    }
  }

  async onProcessingClose() {
    const confirm = await this.dialog.confirmDialog('Confermi la chiusura dell\'elaborazione?').toPromise();

    if (!!confirm) {
      this.changingAssignmentStatus = true;
      this.syncingAssignment = true;

      this.assignmentsService.setToUnderRevision(this.assignment.id).subscribe(assignment => {
          this.assignment = assignment;
          this.updateLinksAssignment();
          this.syncingAssignment = false;
          this.changingAssignmentStatus = true;
          this.auditRefresh.requestAuditRefresh();
        },
        () => {
          this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
          this.syncingAssignment = false;
          this.changingAssignmentStatus = true;
        }
      );
    }
  }

  async onCompletedReopen() {
    const confirm = await this.dialog.confirmDialog('Confermi la riapertura dell\'incarico?').toPromise();

    if (confirm) {
      this.changingAssignmentStatus = true;
      this.syncingAssignment = true;

      this.assignmentsService.setToUnderRevision(this.assignment.id).subscribe(assignment => {
          this.assignment = assignment;
          this.updateLinksAssignment();
          this.syncingAssignment = false;
          this.changingAssignmentStatus = true;
          this.auditRefresh.requestAuditRefresh();
        },
        () => {
          this.toaster.displayError('Errore nel passaggio di stato dell\'incarico.');
          this.syncingAssignment = false;
          this.changingAssignmentStatus = true;
        }
      );
    }
  }

  updateLinksAssignment() {
    this.links.forEach(link => {
      if (link.nextState && link.nextState.assignment) {
        link.nextState.assignment = this.assignment;
      }
    });
  }

  onChildClick(subsectionId) {
    const el = (document.querySelector(`#subsection-${subsectionId} .focusable`) as HTMLElement);
    if (el) {
      // @ts-ignore
      el.focus({preventScroll: true});
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.sideMenuStateSubscription.unsubscribe();
    this.sideMenuStateSubscription2.unsubscribe();
  }
}
