import { NgModule }   from '@angular/core';
import { SpinnerService } from './spinner.service';

@NgModule({
  providers:    [
    SpinnerService
  ]
})
export class SpinnerModule {
}
