import { Attachment } from '../attachment/attachment';

export class RequiredAttachment {
  id: number;
  label: string;
  questionId: number;
  description: string;
  assignmentId: number;
  attachment: Attachment;

  questionText: string;
  busy: boolean;

  constructor(obj) {
    this.id =                 obj && obj.id;
    this.label =              obj && obj.label;
    this.questionId =         obj && obj.question_id;
    this.description =        obj && obj.description;
    this.assignmentId =       obj && obj.assignment_id;
    this.attachment =         obj && obj.attachment && new Attachment(obj.attachment);
  }
}
