import { map } from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Target } from './target';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var TargetsService = /** @class */ (function () {
    function TargetsService(http) {
        this.http = http;
    }
    TargetsService.prototype.getTargets = function () {
        return this.http.get('targets').pipe(map(function (res) { return res.targets.map(function (target) { return new Target(target); }); }));
    };
    TargetsService.ngInjectableDef = i0.defineInjectable({ factory: function TargetsService_Factory() { return new TargetsService(i0.inject(i1.ApiHttpClientService)); }, token: TargetsService, providedIn: "root" });
    return TargetsService;
}());
export { TargetsService };
