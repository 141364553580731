<div mat-dialog-title>
  <h4>Conferma azione</h4>
</div>
<mat-dialog-content>
  <p>{{data.text}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">No</button>
  <button mat-button [mat-dialog-close]="true" tabindex="1">Sì</button>
</mat-dialog-actions>

