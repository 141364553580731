import { Assignment } from '../core/models/assignment/assignment';

export class DashboardData {
  inspection: Assignment[];
  processing: Assignment[];
  underRevision: Assignment[];

  constructor(obj: { inspection: any[], processing: any[], under_revision: any[] }) {
    this.inspection =     obj && obj.inspection && obj.inspection.map(assignment => new Assignment(assignment))         || [];
    this.processing =     obj && obj.processing && obj.processing.map(assignment => new Assignment(assignment))         || [];
    this.underRevision =  obj && obj.under_revision && obj.under_revision.map(assignment => new Assignment(assignment))   || [];
  }
}
