import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, startWith, tap }                            from 'rxjs/operators';
import { FormControl }                                    from '@angular/forms';
import { Observable }                                     from 'rxjs';
import { UtilsService }                                   from '../../core/utils/utils.service';
import { UserAutocompleteFormFieldService }               from './user-autocomplete-form-field.service';

@Component({
  selector: 'user-autocomplete-form-field',
  templateUrl: './user-autocomplete-form-field.component.html',
  styleUrls: ['./user-autocomplete-form-field.component.scss']
})
export class UserAutocompleteFormFieldComponent implements OnInit {

  @Input() user = {};
  @Input() placeholder;
  @Output() changed = new EventEmitter();

  userCtrl = new FormControl({});
  filteredUsers: Observable<any[]>;

  users;

  constructor(private utils: UtilsService,
              private userAutocompleteFormFieldService: UserAutocompleteFormFieldService) { }

  ngOnInit() {
    this.userCtrl.setValue(this.user);
    this.userAutocompleteFormFieldService.getUsers().subscribe(res => {
      this.users = (res as any[]).filter(user => user.role !== 'clerk');
      this.filteredUsers = this.userCtrl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value['name']),
          map(user => user ? this._filterStates(user) : this.users.slice()),
          tap(_ => this.changed.emit(this.userCtrl.value))
        );
    });
  }

  private _filterStates(value: string) {
    const filterValue = value.toLowerCase();

    return this.users.filter(user => this.utils.matchByRegex(user.name, filterValue)); // todo use substring
  }

  displayFn(user) {
    return user ? user.name : undefined;
  }

}
