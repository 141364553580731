import { FactoryType } from './factory-type';
import { map } from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var FactoryTypesService = /** @class */ (function () {
    function FactoryTypesService(http) {
        this.http = http;
    }
    FactoryTypesService.prototype.getFactoryTypes = function () {
        return this.http.get('factory_types').pipe(map(function (res) { return res.factory_types.map(function (factoryType) { return new FactoryType(factoryType); }); }));
    };
    FactoryTypesService.ngInjectableDef = i0.defineInjectable({ factory: function FactoryTypesService_Factory() { return new FactoryTypesService(i0.inject(i1.ApiHttpClientService)); }, token: FactoryTypesService, providedIn: "root" });
    return FactoryTypesService;
}());
export { FactoryTypesService };
