import { Component, OnDestroy, OnInit } from '@angular/core';
import { SideMenuService }              from '../../core/side-bar/side-menu/side-menu.service';
import { ActivatedRoute, Router }       from '@angular/router';
import { AssignmentsService }           from '../../core/models/assignment/assignments.service';
import { Assignment }                   from '../../core/models/assignment/assignment';
import { catchError, switchMap }        from 'rxjs/operators';
import { NetStatusService }          from '../../core/net-status/net-status.service';
import { Subscription }              from 'rxjs';
import { DialogService }             from '../../core/dialog/dialog.service';
import { ToasterService }            from '../../core/toaster/toaster.service';
import { UserService }               from '../../core/user/user.service';
import { IdbWrapperService }         from '../../core/idb-wrapper/idb-wrapper.service';
import { AuditRefreshService }       from '../audit/audit-refresh.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { UtilsService }              from '../../core/utils/utils.service';
import { fade }                      from '../../shared/animations/fade.animation';

@Component({
  selector: 'assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
  animations: [fade]
})
export class AssignmentComponent implements OnInit, OnDestroy {

  id: number;
  assignment: Assignment;
  subscription: Subscription;
  assignmentFields;
  downloadingReport: boolean;
  downloadingPicturesReport: boolean;
  uploadingPdf: boolean;
  downloadingPdf: boolean;

  constructor(private sideMenuService: SideMenuService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private assignmentsService: AssignmentsService,
              public netStatus: NetStatusService,
              private dialog: DialogService,
              private toaster: ToasterService,
              private idbWrapper: IdbWrapperService,
              public userService: UserService,
              private auditRefresh: AuditRefreshService,
              private utils: UtilsService) {
  }

  onEdit() {
    this.router.navigate([`assignments/${this.id}/edit`]);
  }

  async onDelete() {
    const confirm = await this.dialog.confirmDialog('Confermi l\'eliminazione dell\'incarico?').toPromise();

    if (confirm) {
      this.assignmentsService.deleteAssignment(this.assignment.id).subscribe(
        () => {
          this.toaster.displaySuccess('Incarico eliminato correttamente');
          this.router.navigate(['assignments']);
        },
        error => {
          this.toaster.displayError('Errore nell\'eliminazione dell\'incarico!');
        }
      );
    }
  }

  onDownload(assignment) {
    this.assignmentsService.setAssignment(assignment);
  }

  onReportDownload() {
    this.downloadingReport = true;
    this.assignmentsService.getAssignmentReport(this.assignment.id).subscribe(
      (res: HttpResponse<any>) => {
        const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
        this.utils.downloadFile(blob, filename);
        this.downloadingReport = false;
      },
      () => this.downloadingReport = false
    );
  }

  onPictureReportDownload() {
    this.downloadingPicturesReport = true;
    this.assignmentsService.getPicturesReport(this.assignment.id).subscribe(
      (res: HttpResponse<any>) => {
        const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
        this.utils.downloadFile(blob, filename);
        this.downloadingPicturesReport = false;
      },
      () => this.downloadingPicturesReport = false
    );
  }

  onPdfDownload() {
    this.downloadingPdf = true;
    this.assignmentsService.getFinalReport(this.assignment.id).subscribe(
      (res: HttpResponse<any>) => {
        const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
        this.utils.downloadFile(blob, filename);
        this.downloadingPdf = false;
      },
      () => this.downloadingPdf = false
    );
  }

  onReportUpload(file) {
    this.uploadingPdf = true;
    const formData: FormData = new FormData();
    formData.append('final_report', file);
    this.assignmentsService.finalizeAssignment(this.assignment.id, formData, {
      headers: new HttpHeaders()
    }).subscribe(assignment => {
      this.updateAssignment(new Assignment(assignment));
      this.uploadingPdf = false;
    });
  }

  updateAssignment(assignment) {
    this.sideMenuService.sideMenuState = {state: 'assignment', assignment};
    this.assignment = assignment;
  }

  async onUserEdit(resource) {
    const confirm = await this.dialog.choiceDialog({
      title: `Selezione ${resource === 'compiler' ? 'compilatore' : 'revisore'}`,
      body: '',
      placeholder: `Scegli un ${resource === 'compiler' ? 'compilatore' : 'revisore'}`,
      user: this.assignment.compiler
    }).toPromise();
    if (!!confirm) this.assignmentsService.updateUser(this.assignment.id, confirm.id, resource).subscribe((assignment: Assignment) => {
      const user = this.userService.user;
      if (user.role === 'compiler' && assignment.compiler && assignment.compiler.id !== user.id && assignment.reviser && assignment.reviser.id !== user.id) {
        this.router.navigate(['/']);
      } else {
        this.updateAssignment(assignment);
      }
    });
  }

  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.params.id;

    this.idbWrapper.all('assignmentFields').subscribe(assignmentFields => {
      this.assignmentFields = assignmentFields.reduce((obj, item) => ({...obj, [item['mnemonicLabel']]: item}), {});
    });

    this.subscription = this.auditRefresh.auditRefresh$.pipe(
      switchMap(() => this.netStatus.onlineStatus),
      switchMap((isOnline: boolean) => this.assignmentsService.getAssignment(isOnline, this.id))
    ).subscribe(assignment => this.updateAssignment(assignment));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
