import { Injectable }                 from '@angular/core';
import { HttpClient, HttpHeaders }    from '@angular/common/http';
import { environment }                from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { ToasterService }             from '../toaster/toaster.service';
import { catchError }                 from 'rxjs/operators';
import { Router }                     from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpClientService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(private http: HttpClient,
              private toaster: ToasterService,
              private router: Router) { }

  get(url: string, options?: any): Observable<Object> {
    return this.http.get(this.composeUrl(url), { headers: this.headers, ...options }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  post(url: string, body: any | null, options?: any) {
    return this.http.post(this.composeUrl(url), body, { headers: this.headers, ...options }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  put(url: string, body: any | null, options?: any) {
    return this.http.put(this.composeUrl(url), body, { headers: this.headers, ...options }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  delete(url: string, options?: any) {
    return this.http.delete(this.composeUrl(url), { headers: this.headers, ...options }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private composeUrl(url: string) {
    return `${environment.apiUrl}/${url}`;
  }

  private handleError(error: any) {
    console.error(error);
    let errorMessage;
    if (error.status === 403) {
      errorMessage = 'Utente non autorizzato';
      this.toaster.displayError(errorMessage);
      this.router.navigate(['/dashboard']);
    } else if (error.status === 401) {
      errorMessage = 'Effettuare il login';
      this.toaster.displayError(errorMessage);
      this.router.navigate(['/login']);
    } else if (error.status === 0) {
      errorMessage = 'Connessione ad Internet assente';
      this.toaster.displayError(errorMessage);
    } else {
      errorMessage = 'Errore nel server';
      this.toaster.displayError(errorMessage);
    }
    return throwError(errorMessage);
  }
}
