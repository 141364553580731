import { NgModule }                                              from '@angular/core';
import { DateAdapter, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { ItDateAdapter }                                         from './it-date-adapter';

@NgModule({
  imports: [
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: DateAdapter, useClass: ItDateAdapter }
  ]
})
export class DateAdapterModule { }
