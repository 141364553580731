import { Answer }             from '../answer/answer';
import { RequiredAttachment } from '../required-attachment/required-attachment';

export class Question {
  id: number;
  questionType: 'dropdown' | 'checkboxes' | 'descriptive' | 'numeric' | 'group_question' | 'date';
  processingOnly: boolean;
  expectsFreeNotes: boolean;
  placeholder: string;
  answerOptions: { id: number, text: string }[];
  questionTexts: { factoryTypeId: number, text: string }[];
  sort: number;
  questionCode: string;
  parentQuestionId: number;
  factoryTypeIds: number[];
  subsectionId: number;
  unlockedByIds: number[];
  autoMapNotes: boolean;
  templateMappingRules: { id: number, wildcard: string, factoryTypeId: number }[];

  // Fields used by the audit component
  childQuestions: Question[];
  questionText: string;
  answer: Answer;
  groupAnswers: Answer[];
  unlocked: boolean;
  unlockedByParentAnswerIds;
  templateMappingRule: { id: number, wildcard: string, factoryTypeId: number };
  requiredAttachments: RequiredAttachment[];

  constructor(obj: any) {
    this.id =                     obj && obj.id;
    this.questionType =           obj && obj.question_type;
    this.processingOnly =         obj && !!obj.processing_only;
    this.expectsFreeNotes =       obj && !!obj.expects_free_notes;
    this.placeholder =            obj && obj.placeholder || '';
    this.sort =                   obj && obj.sort || null;
    this.questionCode =           obj && obj.question_code || null;
    this.answerOptions =          obj && obj.answer_options && obj.answer_options.map( answer_option => ({ id: answer_option.id, text: answer_option.text })) || [];
    this.questionTexts =          obj && obj.question_texts && obj.question_texts.map( question_text => ({ factoryTypeId: question_text.factory_type_id, text: question_text.text })) || [];
    this.parentQuestionId =       obj && obj.parent_question_id || null;
    this.factoryTypeIds =         obj && obj.factory_type_ids || null;
    this.subsectionId =           obj && obj.subsection_id || null;
    this.unlockedByIds =          obj && obj.unlocked_by_ids || [];
    this.autoMapNotes =           obj && !!obj.auto_map_notes;
    this.templateMappingRules =   obj && obj.template_mapping_rules && obj.template_mapping_rules.map(template_mapping_rule => ({ id: template_mapping_rule.id, wildcard: template_mapping_rule.wildcard, factoryTypeId: template_mapping_rule.factory_type_id })) || [];

    this.unlocked = this.unlockedByIds.length === 0;
  }
}
