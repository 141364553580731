var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Question } from '../../../core/models/question/question';
import { Answer } from '../../../core/models/answer/answer';
import { Assignment } from '../../../core/models/assignment/assignment';
import { DialogService } from '../../../core/dialog/dialog.service';
var QuestionCardComponent = /** @class */ (function () {
    function QuestionCardComponent(dialogService) {
        this.dialogService = dialogService;
        this.answerChange = new EventEmitter();
        this.pictureAdd = new EventEmitter();
        this.attachmentAdd = new EventEmitter();
        this.attachmentDelete = new EventEmitter();
        this.attachmentDownload = new EventEmitter();
        this.pictureOpen = new EventEmitter();
        this.pictureEdit = new EventEmitter();
        this.pictureDelete = new EventEmitter();
        this.requiredAttachmentAdd = new EventEmitter();
        this.requiredAttachmentEdit = new EventEmitter();
        this.requiredAttachmentDelete = new EventEmitter();
        this.showLens = true;
        this.picturesCollapsed = false;
        this.requiredAttachmentsCollapsed = false;
        this.attachmentsCollapsed = false;
        this.changesSubject = new Subject();
    }
    QuestionCardComponent.prototype.valueChanged = function () {
        this.updateLens();
        this.changesSubject.next({
            questionId: this.question.id,
            value: this.value,
            freeNotes: this.freeNotes
        });
    };
    // Event handlers
    QuestionCardComponent.prototype.onInputChange = function (value) {
        this.value = value;
        this.valueChanged();
    };
    QuestionCardComponent.prototype.onNumericInputKeydown = function (event) {
        if (event.key === '-' || (this.value && (this.value.toString().includes(',') || this.value.toString().includes('.')) && (event.key === ',' || event.key === '.')))
            event.preventDefault();
    };
    QuestionCardComponent.prototype.onSelectChange = function (optionId) {
        this.value = optionId;
        this.valueChanged();
    };
    QuestionCardComponent.prototype.onCheckboxChange = function (event, option) {
        if (event.checked) {
            this.value.push(option.id);
        }
        else {
            this.value.splice(this.value.indexOf(option.id), 1);
        }
        this.showLens = !this.value.length;
        this.valueChanged();
    };
    QuestionCardComponent.prototype.onFreeNotesChange = function (event) {
        this.freeNotes = event;
        if (this.question.expectsFreeNotes)
            this.showLens = !this.freeNotes;
        this.valueChanged();
    };
    QuestionCardComponent.prototype.onAttachmentDownload = function (attachment) {
        this.attachmentDownload.emit(attachment);
    };
    QuestionCardComponent.prototype.onDateDelete = function () {
        this.onInputChange(undefined);
    };
    QuestionCardComponent.prototype.onAddPicture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var upload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.uploadDialog({
                            resource: 'picture'
                        }).toPromise()];
                    case 1:
                        upload = _a.sent();
                        if (upload) {
                            this.pictureAdd.emit(upload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onAddAttachment = function (requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var options, upload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = {
                            resource: 'pdf'
                        };
                        if (requiredAttachment) {
                            options['requiredAttachmentId'] = requiredAttachment.id;
                        }
                        return [4 /*yield*/, this.dialogService.uploadDialog(options).toPromise()];
                    case 1:
                        upload = _a.sent();
                        if (upload) {
                            this.attachmentAdd.emit(upload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onDeleteAttachment = function (attachment, requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var promptText, confirm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promptText = requiredAttachment ?
                            "Confermi l'eliminazione del file " + requiredAttachment.attachment.filename + "?" :
                            'Confermi l\'eliminazione del file?';
                        return [4 /*yield*/, this.dialogService.confirmDialog(promptText).toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.attachmentDelete.emit({
                                attachment: attachment,
                                requiredAttachment: requiredAttachment,
                                answer: this.answer
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onDeletePicture = function (picture) {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.confirmDialog("Confermi l'eliminazione del fotogramma?").toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.pictureDelete.emit({
                                picture: picture,
                                answer: this.answer
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onEditPicture = function (picture) {
        return __awaiter(this, void 0, void 0, function () {
            var changes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.pictureEditDialog({ picture: picture }).toPromise()];
                    case 1:
                        changes = _a.sent();
                        if (changes) {
                            this.pictureEdit.emit({
                                picture: __assign({}, picture, { caption: changes.caption }),
                                answer: this.answer
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onPictureOpen = function (picture) {
        this.pictureOpen.emit(picture);
    };
    QuestionCardComponent.prototype.onTogglePicturesCollapse = function () {
        this.picturesCollapsed = !this.picturesCollapsed;
    };
    QuestionCardComponent.prototype.onToggleRequiredAttachmentsCollapse = function () {
        this.requiredAttachmentsCollapsed = !this.requiredAttachmentsCollapsed;
    };
    QuestionCardComponent.prototype.onToggleAttachmentsCollapse = function () {
        this.attachmentsCollapsed = !this.attachmentsCollapsed;
    };
    QuestionCardComponent.prototype.onAddRequiredAttachment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var description;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.requiredAttachmentDialog().toPromise()];
                    case 1:
                        description = _a.sent();
                        if (description) {
                            this.requiredAttachmentAdd.emit(description);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onEditRequiredAttachment = function (requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var description;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.requiredAttachmentDialog(requiredAttachment).toPromise()];
                    case 1:
                        description = _a.sent();
                        if (description) {
                            this.requiredAttachmentEdit.emit({ requiredAttachment: requiredAttachment, description: description });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    QuestionCardComponent.prototype.onDeleteRequiredAttachment = function (requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.confirmDialog("Confermi l'eliminazione del documento da richiedere?").toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.requiredAttachmentDelete.emit({
                                requiredAttachment: requiredAttachment,
                                question: this.question
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Utility methods
    QuestionCardComponent.prototype.getSelectText = function () {
        var _this = this;
        return this.value == null ? '-' : this.question.answerOptions[this.question.answerOptions.findIndex(function (el) { return el.id === _this.value; })].text;
    };
    QuestionCardComponent.prototype.getCheckboxesText = function () {
        var _this = this;
        return this.value == null || this.value.length === 0 ?
            '-' :
            this.value
                .map(function (optionId) { return _this.question.answerOptions[_this.question.answerOptions.findIndex(function (el) { return el.id === optionId; })].text; })
                .join(', ');
    };
    QuestionCardComponent.prototype.updateLens = function () {
        var temp;
        if (this.question.questionType === 'descriptive' || this.question.questionType === 'numeric' || this.question.questionType === 'dropdown' || this.question.questionType === 'date') {
            temp = !this.value;
        }
        if (this.question.questionType === 'checkboxes') {
            temp = !this.value.length;
        }
        this.showLens = temp || this.question.expectsFreeNotes && !this.freeNotes;
    };
    QuestionCardComponent.prototype.computeShouldMenuShowFreeNotes = function () {
        return !this.showFreeNotes && !this.viewMode;
    };
    QuestionCardComponent.prototype.computeShouldMenuShowInspectionValue = function () {
        return !this.showInspectionValue && !!(this.answer && this.answer.inspectionValue) && this.assignment.status !== 'inspection';
    };
    QuestionCardComponent.prototype.computeShouldMenuShowAddRequiredAttachment = function () {
        return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status !== 'completed' && !this.question.parentQuestionId);
    };
    QuestionCardComponent.prototype.computeShouldMenuShowAddPicture = function () {
        return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status === 'processing' || this.assignment.status === 'under_revision');
    };
    QuestionCardComponent.prototype.computeShouldMenuShowAddAttachment = function () {
        return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status === 'processing' || this.assignment.status === 'under_revision');
    };
    QuestionCardComponent.prototype.computeShouldShowMenuTrigger = function () {
        return this.shouldMenuShowFreeNotes ||
            this.shouldMenuShowInspectionValue ||
            this.shouldMenuShowAddRequiredAttachment ||
            this.shouldMenuShowAddPicture ||
            this.shouldMenuShowAddAttachment;
    };
    QuestionCardComponent.prototype.answerOptionTrackBy = function (index, item) {
        return item.id;
    };
    // Lifecycle hooks
    QuestionCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.answer && this.answer.value) {
            this.value = this.answer.value;
        }
        else {
            if (this.question.questionType === 'checkboxes') {
                this.value = [];
            }
        }
        this.freeNotes = this.answer && this.answer.freeNotes || null;
        this.showFreeNotes = this.question.expectsFreeNotes || (!!this.answer && !!this.answer.freeNotes);
        this.updateLens();
        this.changesSubscription = this.changesSubject
            .pipe(debounceTime(250))
            .subscribe(function (changes) {
            _this.answerChange.emit({
                question: _this.question,
                changes: changes
            });
        });
    };
    QuestionCardComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.shouldFocus) {
            // @ts-ignore
            setTimeout(function () {
                var el = _this.wrapper.nativeElement.querySelector('.focusable');
                if (el) {
                    // @ts-ignore
                    el.focus({ preventScroll: true });
                }
            });
        }
    };
    QuestionCardComponent.prototype.ngOnChanges = function () {
        this.shouldMenuShowFreeNotes = this.computeShouldMenuShowFreeNotes();
        this.shouldMenuShowInspectionValue = this.computeShouldMenuShowInspectionValue();
        this.shouldMenuShowAddRequiredAttachment = this.computeShouldMenuShowAddRequiredAttachment();
        this.shouldMenuShowAddPicture = this.computeShouldMenuShowAddPicture();
        this.shouldMenuShowAddAttachment = this.computeShouldMenuShowAddAttachment();
        this.shouldShowMenuTrigger = this.computeShouldShowMenuTrigger();
    };
    QuestionCardComponent.prototype.ngOnDestroy = function () {
        this.changesSubscription.unsubscribe();
    };
    return QuestionCardComponent;
}());
export { QuestionCardComponent };
