
<div class="login">
  <form class="login__form">
    <h4 class="login__form__header">Cambio password</h4>
    <div class="login__form__input sebastian-input">
      <mat-form-field floatLabel="never">
        <input matInput type="password" placeholder="Nuova password" name="new-password" [(ngModel)]="newPassword">
      </mat-form-field>
    </div>
    <div class="login__form__input sebastian-input">
      <mat-form-field floatLabel="never">
        <input matInput type="password" placeholder="Conferma password" name="confirm-password" [(ngModel)]="newPasswordConfirm">
        <mat-hint>{{error}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="login__form__submit">
      <div class="spinner" *ngIf="submitted"></div>
      <button mat-button type="submit" class="sebastian-button sebastian-button--primary" *ngIf="!submitted" (click)="onPasswordChange()">Conferma</button>
    </div>
  </form>
</div>
