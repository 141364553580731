/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./core/side-bar/side-bar.component.ngfactory";
import * as i4 from "./core/side-bar/side-bar.component";
import * as i5 from "ngx-konami";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
import * as i8 from "./core/net-status/net-status.service";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./core/dialog/dialog.service";
import * as i12 from "./core/spinner/spinner.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "spinner-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinnerMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "spinner-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinnerMessage; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "side-bar", [], null, null, null, i3.View_SideBarComponent_0, i3.RenderType_SideBarComponent)), i1.ɵdid(1, 114688, null, 0, i4.SideBarComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["id", "app-content"]], null, [[null, "konami"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("konami" === en)) {
        var pd_1 = (_co.onKonami() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i5.KonamiDirective, [], null, { konami: "konami" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "router-wrapper"], ["id", "router-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 6, 0); var currVal_0 = _co.showSpinner; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i7.AppComponent, [i1.Renderer2, i8.NetStatusService, i9.MatIconRegistry, i10.DomSanitizer, i11.DialogService, i12.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
