/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-bar/user-bar.component.ngfactory";
import * as i3 from "./user-bar/user-bar.component";
import * as i4 from "../user/user.service";
import * as i5 from "../net-status/net-status.service";
import * as i6 from "./side-menu/side-menu.component.ngfactory";
import * as i7 from "./side-menu/side-menu.component";
import * as i8 from "./side-menu/side-menu.service";
import * as i9 from "../idb-wrapper/idb-wrapper.service";
import * as i10 from "../sync/sync.service";
import * as i11 from "../toaster/toaster.service";
import * as i12 from "../models/assignment/assignments.service";
import * as i13 from "../../assignments/audit/audit-refresh.service";
import * as i14 from "../dialog/dialog.service";
import * as i15 from "../utils/utils.service";
import * as i16 from "./side-bar.component";
var styles_SideBarComponent = [i0.styles];
var RenderType_SideBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideBarComponent, data: {} });
export { RenderType_SideBarComponent as RenderType_SideBarComponent };
export function View_SideBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-bar", [], null, null, null, i2.View_UserBarComponent_0, i2.RenderType_UserBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.UserBarComponent, [i4.UserService, i5.NetStatusService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "side-menu", [], null, null, null, i6.View_SideMenuComponent_0, i6.RenderType_SideMenuComponent)), i1.ɵdid(3, 245760, null, 0, i7.SideMenuComponent, [i8.SideMenuService, i9.IdbWrapperService, i5.NetStatusService, i10.SyncService, i11.ToasterService, i12.AssignmentsService, i13.AuditRefreshService, i14.DialogService, i15.UtilsService, i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_SideBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "side-bar", [], null, null, null, View_SideBarComponent_0, RenderType_SideBarComponent)), i1.ɵdid(1, 114688, null, 0, i16.SideBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideBarComponentNgFactory = i1.ɵccf("side-bar", i16.SideBarComponent, View_SideBarComponent_Host_0, {}, {}, []);
export { SideBarComponentNgFactory as SideBarComponentNgFactory };
