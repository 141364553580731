import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { FullscreenImageComponent } from './fullscreen-image.component';
import { MatIconModule }            from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [FullscreenImageComponent],
  exports: [FullscreenImageComponent]
})
export class FullscreenImageModule { }
