export class AssignmentField {
  id: number;
  name: string;
  mnemonicLabel: string;
  wildcard: string;

  constructor(obj) {
    this.id =               obj && obj.id;
    this.name =             obj && obj.name             || null;
    this.mnemonicLabel =    obj && obj.mnemonic_label   || null;
    this.wildcard =         obj && obj.wildcard         || null;
  }
}
