var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { SideMenuService } from '../core/side-bar/side-menu/side-menu.service';
import { IdbWrapperService } from '../core/idb-wrapper/idb-wrapper.service';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NetStatusService } from '../core/net-status/net-status.service';
import { Router } from '@angular/router';
import { AssignmentsService } from '../core/models/assignment/assignments.service';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(dashboardService, assignmentsService, sideMenuService, idbWrapper, netStatus, router) {
        this.dashboardService = dashboardService;
        this.assignmentsService = assignmentsService;
        this.sideMenuService = sideMenuService;
        this.idbWrapper = idbWrapper;
        this.netStatus = netStatus;
        this.router = router;
        this.columnHeaders = {
            'practiceNumber': 'Numero pratica',
            'insured': 'Assicurato',
            'principal': 'Mandante',
            'factoryType': 'Tipo di stabilimento',
            'compilerName': 'Compilatore',
            'reviserName': 'Revisore',
            'status': 'Stato',
            'download': ''
        };
        this.columns = ['practiceNumber', 'insured', 'principal', 'factoryType', 'compilerName', 'reviserName', 'status', 'download'];
        this.dashboardData = {
            inspection: [],
            processing: [],
            underRevision: []
        };
    }
    DashboardComponent.prototype.toggleOffline = function (assignment) {
        if (assignment.offline) {
            this.idbWrapper.delete('assignments', assignment.id).subscribe(function (res) {
                assignment.offline = false;
            }, function (error) {
                console.error(error.message);
            });
        }
        else {
            this.assignmentsService.downloadAssignment(assignment).subscribe(function () {
                assignment.offline = true;
            }, function (error) {
                console.error(error.message);
            });
        }
    };
    DashboardComponent.prototype.getDashboardData = function (isOnline) {
        if (isOnline === void 0) { isOnline = true; }
        if (isOnline) {
            return combineLatest([
                this.dashboardService.getDashboardData(),
                this.idbWrapper.all('assignments')
            ]).pipe(map(function (_a) {
                var dashboardData = _a[0], offlineAssignments = _a[1];
                return ({
                    inspection: dashboardData.inspection.map(function (assignment) { return (__assign({}, assignment, { offline: !!(offlineAssignments.find(function (item) { return item.id === assignment.id; })) })); }),
                    processing: dashboardData.processing,
                    underRevision: dashboardData.underRevision
                });
            }));
        }
        else {
            return this.idbWrapper.all('assignments').pipe(map(function (assignments) { return assignments.map(function (assignment) { return (__assign({}, assignment, { offline: true })); }); })).pipe(map(function (offlineAssignments) { return ({
                inspection: offlineAssignments,
                processing: [],
                underRevision: []
            }); }));
        }
    };
    DashboardComponent.prototype.openAssignment = function (assignment) {
        this.router.navigate(['assignments', assignment.id]);
    };
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.netStatus.onlineStatus.pipe(tap(function () { return _this.dashboardLoading = true; }), switchMap(function (isOnline) { return _this.getDashboardData(isOnline); })).subscribe(function (dashboardData) {
            _this.dashboardData = dashboardData;
            _this.dashboardLoading = false;
        });
        this.sideMenuService.sideMenuState = { state: 'root' };
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return DashboardComponent;
}());
export { DashboardComponent };
