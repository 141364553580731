import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent }                      from './app.component';
import { CoreModule }                        from './core/core.module';
import { AppRoutingModule }                  from './app.routing.module';
import { DashboardModule }                   from './dashboard/dashboard.module';
import { LoginModule }                       from './login/login.module';
import { ServiceWorkerModule }               from '@angular/service-worker';
import { environment }                       from '../environments/environment';
import { AssignmentsModule }                 from './assignments/assignments.module';
import { PasswordChangeModule }              from './password-change/password-change.module';

import { registerLocaleData }   from '@angular/common';
import localeIt                 from '@angular/common/locales/it';
import { AngularTokenModule }   from 'angular-token';
import { HttpClientModule }     from '@angular/common/http';
import 'hammerjs';
import { AirbrakeErrorHandler } from './core/airbrake/airbrake.error-handler';
import { KonamiModule }         from 'ngx-konami';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    KonamiModule,
    AppRoutingModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiPath: environment.apiUrl,
      signInRedirect: 'login',
      signOutFailedValidate: true,
    }),
    DashboardModule,
    AssignmentsModule,
    LoginModule,
    PasswordChangeModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    AngularTokenModule,
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: ErrorHandler, useClass: AirbrakeErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
