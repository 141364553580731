
<div class="new-assignment">
  <div class="sebastian-back">
    <mat-icon svgIcon="keyboard_backspace" (click)="!busy && onDiscard()"></mat-icon>
  </div>
  <div class="new-assignment__body">
    <h4 class="new-assignment__body__title">
      {{ !!id ? 'Modifica incarico' : 'Crea un nuovo incarico' }}
    </h4>
    <div class="new-assignment__body__form">
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.practice_number.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.practice_number.name" [(ngModel)]="assignment.practiceNumber">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.insured.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.insured.name" [(ngModel)]="assignment.insured">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.location.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.location.name" [(ngModel)]="assignment.location">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.accident_number.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.accident_number.name" [(ngModel)]="assignment.accidentNumber">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.policy_number.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.policy_number.name" [(ngModel)]="assignment.policyNumber">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.principal.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.principal.name" [(ngModel)]="assignment.principal">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.agency.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.agency.name" [(ngModel)]="assignment.agency">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.agency_code.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.agency_code.name" [(ngModel)]="assignment.agencyCode">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input">
        <label>&#8203;{{assignmentFields?.surveyor.name}}</label>
        <mat-form-field floatLabel="never">
          <input matInput [placeholder]="assignmentFields?.surveyor.name" [(ngModel)]="assignment.surveyor">
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-select sebastian-select--border">
        <label>&#8203;Tipo di stabilimento</label>
        <mat-form-field floatLabel="never">
          <mat-select placeholder="Tipi"
                      [disabled]="!!id"
                      [(ngModel)]="assignment.factoryTypeId">
            <mat-option *ngFor="let factoryType of factoryTypes" [value]="factoryType.id">
              {{factoryType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="new-assignment__body__form__field sebastian-input" *ngIf="!id">
        <label>&#8203;Compilatore</label>
        <user-autocomplete-form-field [user]="['manager', 'compiler'].includes(userService.user.role) ? userService.user : {}" [placeholder]="'Compilatore'" (changed)="onUserChanged($event)"></user-autocomplete-form-field>
      </div>
      <div class="new-assignment__body__form__field" style="visibility: hidden"></div>
    </div>
    <div class="new-assignment__body__buttons">
      <button mat-button class="sebastian-button sebastian-button--primary" *ngIf="!busy" (click)="onSubmit()">{{!!id ? 'Modifica' : 'Crea'}}</button>
      <button mat-button class="sebastian-button sebastian-button--secondary" *ngIf="!busy" (click)="onDiscard()">Annulla</button>
      <div class="new-assignment__body__buttons__loading" *ngIf="busy">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</div>
