import { map } from 'rxjs/operators';
import { AssignmentStatus } from './assignment-status';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var AssignmentStatusesService = /** @class */ (function () {
    function AssignmentStatusesService(http) {
        this.http = http;
    }
    AssignmentStatusesService.prototype.getAssignmentStatuses = function () {
        return this.http.get('assignment_statuses').pipe(map(function (res) { return res.assignment_statuses.map(function (assignmentStatus) { return new AssignmentStatus(assignmentStatus); }); }));
    };
    AssignmentStatusesService.ngInjectableDef = i0.defineInjectable({ factory: function AssignmentStatusesService_Factory() { return new AssignmentStatusesService(i0.inject(i1.ApiHttpClientService)); }, token: AssignmentStatusesService, providedIn: "root" });
    return AssignmentStatusesService;
}());
export { AssignmentStatusesService };
