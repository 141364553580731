var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '../../core/dialog/dialog.service';
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AssignmentsService } from '../../core/models/assignment/assignments.service';
import { SideMenuService } from '../../core/side-bar/side-menu/side-menu.service';
import { combineLatest, of } from 'rxjs';
import { RequiredAttachmentService } from '../../core/models/required-attachment/required-attachment.service';
import { AttachmentsService } from '../../core/models/attachment/attachments.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { UtilsService } from '../../core/utils/utils.service';
import { AnswersService } from '../../core/models/answer/answers.service';
import { NetStatusService } from '../../core/net-status/net-status.service';
import { UserService } from '../../core/user/user.service';
var RequiredAttachmentsComponent = /** @class */ (function () {
    function RequiredAttachmentsComponent(dialog, activatedRoute, assignmentsService, sideMenuService, requiredAttachmentsService, attachmentsService, idbWrapper, utils, answersService, netStatus, userService) {
        this.dialog = dialog;
        this.activatedRoute = activatedRoute;
        this.assignmentsService = assignmentsService;
        this.sideMenuService = sideMenuService;
        this.requiredAttachmentsService = requiredAttachmentsService;
        this.attachmentsService = attachmentsService;
        this.idbWrapper = idbWrapper;
        this.utils = utils;
        this.answersService = answersService;
        this.netStatus = netStatus;
        this.userService = userService;
        this.loading = false;
    }
    RequiredAttachmentsComponent.prototype.onAttachmentDownload = function (attachment) {
        var _this = this;
        attachment.busy = true;
        this.attachmentsService.getAttachment(attachment.id).subscribe(function (res) {
            var _a = _this.utils.blobAndFilenameFromRes(res), blob = _a.blob, filename = _a.filename;
            _this.utils.downloadFile(blob, filename);
            attachment.busy = false;
        });
    };
    RequiredAttachmentsComponent.prototype.onAttachmentDelete = function (requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.confirmDialog("Confermi l'eliminazione del file " + requiredAttachment.attachment.filename + "?").toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            requiredAttachment.busy = true;
                            this.attachmentsService.deleteAttachment(requiredAttachment.attachment.id).subscribe(function () {
                                requiredAttachment.attachment = null;
                                requiredAttachment.busy = false;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RequiredAttachmentsComponent.prototype.onAttachmentAdd = function (requiredAttachment) {
        return __awaiter(this, void 0, void 0, function () {
            var options, upload;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = {
                            resource: 'pdf',
                            requiredAttachmentId: requiredAttachment.id
                        };
                        return [4 /*yield*/, this.dialog.uploadDialog(options).toPromise()];
                    case 1:
                        upload = _a.sent();
                        if (upload) {
                            requiredAttachment.busy = true;
                            this.answersService.getAnswers(this.assignmentId).pipe(switchMap(function (answers) {
                                var questionAnswer = answers.find(function (answer) { return answer.questionId === requiredAttachment.questionId; });
                                if (questionAnswer) {
                                    return of(questionAnswer);
                                }
                                else {
                                    return _this.answersService.createAnswer(_this.assignmentId, { questionId: requiredAttachment.questionId });
                                }
                            }), switchMap(function (answer) { return _this.attachmentsService.createAttachment(__assign({}, upload, { answerId: answer.id })); })).subscribe(function (attachment) {
                                requiredAttachment.attachment = attachment;
                                requiredAttachment.busy = false;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RequiredAttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.activatedRouteSubscription = this.activatedRoute.params.pipe(switchMap(function () { return _this.activatedRoute.params; }), tap(function (res) { return _this.assignmentId = +res.id; }), switchMap(function () { return _this.netStatus.onlineStatus; }), switchMap(function (isOnline) { return _this.assignmentsService.getAssignment(isOnline, _this.assignmentId); }), tap(function (assignment) { return _this.assignment = assignment; }), switchMap(function () { return combineLatest([
            _this.assignment.status === 'inspection' ? _this.idbWrapper.all('requiredAttachments') : _this.requiredAttachmentsService.getRequiredAttachments(_this.assignmentId),
            _this.idbWrapper.all('questions')
        ]); })).subscribe(function (_a) {
            var requiredAttachments = _a[0], questions = _a[1];
            _this.sideMenuService.sideMenuState = { state: 'attachments', assignment: _this.assignment, currentSubRoute: 'required-attachments' };
            if (_this.assignment.status === 'inspection') {
                requiredAttachments = requiredAttachments.concat(_this.assignment.requiredAttachments);
            }
            requiredAttachments.forEach(function (requiredAttachment) {
                var question = questions.find(function (q) { return q.id === requiredAttachment.questionId; });
                if (question) {
                    requiredAttachment.questionText = (question.questionTexts.find(function (text) { return text.factoryTypeId === _this.assignment.factoryTypeId; }) ||
                        question.questionTexts.find(function (text) { return text.factoryTypeId === null; })).text;
                }
            });
            _this.requiredAttachments = requiredAttachments;
            _this.loading = false;
        });
    };
    RequiredAttachmentsComponent.prototype.ngOnDestroy = function () {
        if (this.activatedRouteSubscription)
            this.activatedRouteSubscription.unsubscribe();
    };
    return RequiredAttachmentsComponent;
}());
export { RequiredAttachmentsComponent };
