<div class="risk-score-row__cell target">{{scoreMapping.target.name}}</div>
<div class="risk-score-row__cell score sebastian-input" [class.error]="probabilityScoreError">
  <mat-form-field floatLabel="never" *ngIf="!viewMode">
    <input matInput
           placeholder=""
           [(ngModel)]="scoreMapping.riskScore.probabilityScore"
           (ngModelChange)="onChange()"
           type="number" max="5" min="0"/>
  </mat-form-field>
  <p *ngIf="viewMode">{{ scoreMapping.riskScore.probabilityScore != null ? scoreMapping.riskScore.probabilityScore : '-' }}</p>
</div>
<div class="risk-score-row__cell score sebastian-input" [class.error]="magnitudeScoreError">
  <mat-form-field floatLabel="never" *ngIf="!viewMode">
    <input matInput
           placeholder=""
           [(ngModel)]="scoreMapping.riskScore.magnitudeScore"
           (ngModelChange)="onChange()"
           type="number"/>
  </mat-form-field>
  <p *ngIf="viewMode">{{ scoreMapping.riskScore.magnitudeScore != null ? scoreMapping.riskScore.magnitudeScore : '-' }}</p>
</div>
<div class="risk-score-row__cell notes">
  <textarea placeholder=""
            rows="4"
            *ngIf="!viewMode"
            [(ngModel)]="scoreMapping.riskScore.notes"
            (ngModelChange)="onChange()"></textarea>
  <p *ngIf="viewMode" [innerHtml]="(scoreMapping.riskScore.notes != null ? scoreMapping.riskScore.notes : '-' )"></p>
</div>
