/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/common";
import * as i9 from "../shared/custom-table/custom-table.component.ngfactory";
import * as i10 from "../shared/custom-table/custom-table.component";
import * as i11 from "../core/user/user.service";
import * as i12 from "./dashboard.component";
import * as i13 from "./dashboard.service";
import * as i14 from "../core/models/assignment/assignments.service";
import * as i15 from "../core/side-bar/side-menu/side-menu.service";
import * as i16 from "../core/idb-wrapper/idb-wrapper.service";
import * as i17 from "../core/net-status/net-status.service";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "dashboard__header__button sebastian-button sebastian-button--primary"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { back_page: 0 }), i1.ɵpad(3, 2), i1.ɵdid(4, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Nuovo incarico "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "dashboard"); var currVal_3 = _ck(_v, 3, 0, "/assignments", "new"); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "dashboard__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "dashboard__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard incarichi"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "dashboard__inspection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "dashboard__inspection__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incarichi in sopralluogo"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "custom-table", [], null, [[null, "download"], [null, "rowClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("download" === en)) {
        var pd_0 = (_co.toggleOffline($event) !== false);
        ad = (pd_0 && ad);
    } if (("rowClick" === en)) {
        var pd_1 = (_co.openAssignment($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_CustomTableComponent_0, i9.RenderType_CustomTableComponent)), i1.ɵdid(10, 638976, null, 0, i10.CustomTableComponent, [i11.UserService], { displayedColumns: [0, "displayedColumns"], columnHeaders: [1, "columnHeaders"], data: [2, "data"], offline: [3, "offline"] }, { download: "download", rowClick: "rowClick" }), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "dashboard__processing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "dashboard__processing__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incarichi in elaborazione"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "custom-table", [], null, [[null, "rowClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClick" === en)) {
        var pd_0 = (_co.openAssignment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_CustomTableComponent_0, i9.RenderType_CustomTableComponent)), i1.ɵdid(16, 638976, null, 0, i10.CustomTableComponent, [i11.UserService], { displayedColumns: [0, "displayedColumns"], columnHeaders: [1, "columnHeaders"], data: [2, "data"] }, { rowClick: "rowClick" }), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "dashboard__under-revision"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "dashboard__under-revision__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incarichi in revisione"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "custom-table", [], null, [[null, "rowClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClick" === en)) {
        var pd_0 = (_co.openAssignment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_CustomTableComponent_0, i9.RenderType_CustomTableComponent)), i1.ɵdid(21, 638976, null, 0, i10.CustomTableComponent, [i11.UserService], { displayedColumns: [0, "displayedColumns"], columnHeaders: [1, "columnHeaders"], data: [2, "data"] }, { rowClick: "rowClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.netStatus.isOnline(); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.columns; var currVal_2 = _co.columnHeaders; var currVal_3 = ((_co.dashboardData == null) ? null : _co.dashboardData.inspection); var currVal_4 = !i1.ɵunv(_v, 10, 3, i1.ɵnov(_v, 11).transform(_co.netStatus.onlineStatus)); _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.columns; var currVal_6 = _co.columnHeaders; var currVal_7 = ((_co.dashboardData == null) ? null : _co.dashboardData.processing); _ck(_v, 16, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.columns; var currVal_9 = _co.columnHeaders; var currVal_10 = ((_co.dashboardData == null) ? null : _co.dashboardData.underRevision); _ck(_v, 21, 0, currVal_8, currVal_9, currVal_10); }, null); }
function View_DashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dashboard__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dashboardLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dashboardLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 245760, null, 0, i12.DashboardComponent, [i13.DashboardService, i14.AssignmentsService, i15.SideMenuService, i16.IdbWrapperService, i17.NetStatusService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i12.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
