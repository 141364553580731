import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { AssignmentField }      from './assignment-field';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AssignmentFieldsService {

  constructor(private http: ApiHttpClientService) { }

  getAssignmentFields(): Observable<AssignmentField[]> {
    return this.http.get('assignment_fields').pipe(
      map((res: any) => res.assignment_fields.map(assignmentField => new AssignmentField(assignmentField)))
    );
  }
}
