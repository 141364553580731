import { NgModule } from '@angular/core';
import { RouterModule, Routes }    from '@angular/router';
import { AssignmentComponent }     from './assignment/assignment.component';
import { NewAssignmentComponent }       from './new-assignment/new-assignment.component';
import { AssignmentListComponent }      from './assignment-list/assignment-list.component';
import { AssignmentsComponent }         from './assignments.component';
import { UserService }                  from '../core/user/user.service';
import { SyncService }                  from '../core/sync/sync.service';
import { AuditComponent }               from './audit/audit.component';
import { RiskScoresComponent }          from './risk-scores/risk-scores.component';
import { RequiredAttachmentsComponent } from './required-attachments/required-attachments.component';
import { PicturesComponent }            from './pictures/pictures.component';
import { AttachmentsComponent }         from './attachments/attachments.component';

const assignmentsRoutes: Routes = [
  {
    path: 'assignments',
    component: AssignmentsComponent,
    canActivate: [UserService, SyncService],
    children: [
      {
        path: 'new',
        component: NewAssignmentComponent
      },
      {
        path: ':id',
        component: AssignmentComponent,
      },
      {
        path: ':id/edit',
        component: NewAssignmentComponent
      },
      {
        path: ':id/audit/:sectionId',
        component: AuditComponent
      },
      {
        path: ':id/risk-scores/:sourceId',
        component: RiskScoresComponent
      },
      {
        path: ':id/required-attachments',
        component: RequiredAttachmentsComponent
      },
      {
        path: ':id/pictures',
        component: PicturesComponent
      },
      {
        path: ':id/attachments',
        component: AttachmentsComponent
      },
      {
        path: '',
        component: AssignmentListComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(assignmentsRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AssignmentsRoutingModule {
}
