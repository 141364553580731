import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { fade }                                                         from '../animations/fade.animation';

@Component({
  selector: 'fullscreen-image',
  templateUrl: './fullscreen-image.component.html',
  styleUrls: ['./fullscreen-image.component.scss'],
  animations: [fade]
})
export class FullscreenImageComponent implements OnInit {

  @Input() image;
  @Output() close = new EventEmitter();

  @HostListener('document:keyup', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  constructor() {
  }

  onClose() {
    this.image = null;
    this.close.emit();
  }

  ngOnInit() {
  }

}
