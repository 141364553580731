import { map } from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Source } from './source';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var SourcesService = /** @class */ (function () {
    function SourcesService(http) {
        this.http = http;
    }
    SourcesService.prototype.getSources = function () {
        return this.http.get('sources').pipe(map(function (res) { return res.sources.map(function (source) { return new Source(source); }); }));
    };
    SourcesService.ngInjectableDef = i0.defineInjectable({ factory: function SourcesService_Factory() { return new SourcesService(i0.inject(i1.ApiHttpClientService)); }, token: SourcesService, providedIn: "root" });
    return SourcesService;
}());
export { SourcesService };
