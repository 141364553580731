/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./notes.component";
import * as i10 from "@angular/material/bottom-sheet";
import * as i11 from "../../core/models/assignment/assignments.service";
import * as i12 from "../../core/idb-wrapper/idb-wrapper.service";
var styles_NotesComponent = [i0.styles];
var RenderType_NotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesComponent, data: {} });
export { RenderType_NotesComponent as RenderType_NotesComponent };
export function View_NotesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "notes-button"], ["color", "primary"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "notes"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); var currVal_5 = "notes"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_NotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notes", [], null, null, null, View_NotesComponent_0, RenderType_NotesComponent)), i1.ɵdid(1, 114688, null, 0, i9.NotesComponent, [i10.MatBottomSheet, i11.AssignmentsService, i12.IdbWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesComponentNgFactory = i1.ɵccf("notes", i9.NotesComponent, View_NotesComponent_Host_0, { assignment: "assignment", viewMode: "viewMode" }, { changed: "changed" }, []);
export { NotesComponentNgFactory as NotesComponentNgFactory };
