import { NgModule }                              from '@angular/core';
import { CommonModule }                          from '@angular/common';
import { UserAutocompleteFormFieldComponent }    from './user-autocomplete-form-field.component';
import { UserAutocompleteFormFieldService }      from './user-autocomplete-form-field.service';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule }      from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [UserAutocompleteFormFieldService],
  declarations: [UserAutocompleteFormFieldComponent],
  exports: [UserAutocompleteFormFieldComponent]
})
export class UserAutocompleteFormFieldModule { }
