import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../core/user/user.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean> {
    if (this.userService.userSignedIn()) {
      this.router.navigate(['dashboard']);
    }

    return of(!(this.userService.userSignedIn()));
  }
}
