/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/attachment-chip/attachment-chip.component.ngfactory";
import * as i3 from "../../shared/attachment-chip/attachment-chip.component";
import * as i4 from "@angular/common";
import * as i5 from "./attachments.component";
import * as i6 from "@angular/router";
import * as i7 from "../../core/models/assignment/assignments.service";
import * as i8 from "../../core/side-bar/side-menu/side-menu.service";
import * as i9 from "../../core/models/attachment/attachments.service";
import * as i10 from "../../core/utils/utils.service";
import * as i11 from "../../core/dialog/dialog.service";
import * as i12 from "../../core/idb-wrapper/idb-wrapper.service";
import * as i13 from "../../core/user/user.service";
var styles_AttachmentsComponent = [i0.styles];
var RenderType_AttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentsComponent, data: { "animation": [{ type: 7, name: "zoomLeave", definitions: [{ type: 1, expr: ":leave", animation: { type: 10, animation: { type: 8, animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, offset: 0 }, offset: null }, { type: 6, styles: { opacity: 0, transform: "scale3d(.3, .3, .3)", offset: 0.5 }, offset: null }, { type: 6, styles: { opacity: 0, offset: 1 }, offset: null }] }, timings: "{{ timing }}s {{ delay }}s" }], options: { params: { timing: 1, delay: 0 } } }, options: { params: { timing: 0.25 } } }, options: null }], options: {} }] } });
export { RenderType_AttachmentsComponent as RenderType_AttachmentsComponent };
function View_AttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "attachments__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_AttachmentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "attachment-chip", [], null, [[null, "download"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("download" === en)) {
        var pd_0 = (_co.onAttachmentDownload(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.onAttachmentDelete(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AttachmentChipComponent_0, i2.RenderType_AttachmentChipComponent)), i1.ɵdid(1, 49152, null, 0, i3.AttachmentChipComponent, [], { attachment: [0, "attachment"], canDelete: [1, "canDelete"] }, { download: "download", delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = ((_co.assignment.status !== "completed") && (_co.userService.user.role !== "clerk")); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AttachmentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_AttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "attachments__table__body__row"]], [[24, "@zoomLeave", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "attachments__table__body__row__document"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.busy; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.busy; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.questionText; _ck(_v, 2, 0, currVal_1); }); }
function View_AttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "attachments__table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "attachments__table__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Domanda"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome file"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "attachments__table__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_3)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachments; _ck(_v, 8, 0, currVal_0); }, null); }
function View_AttachmentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "attachments__table"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nessun documento "]))], null, null); }
export function View_AttachmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "attachments"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "attachments__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "attachments__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Altri documenti"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_6)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); var currVal_1 = (!_co.loading && (((_co.attachments == null) ? null : _co.attachments.length) > 0)); _ck(_v, 7, 0, currVal_1); var currVal_2 = (!_co.loading && (((_co.attachments == null) ? null : _co.attachments.length) === 0)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_AttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "attachments", [], null, null, null, View_AttachmentsComponent_0, RenderType_AttachmentsComponent)), i1.ɵdid(1, 245760, null, 0, i5.AttachmentsComponent, [i6.ActivatedRoute, i7.AssignmentsService, i8.SideMenuService, i9.AttachmentsService, i10.UtilsService, i11.DialogService, i12.IdbWrapperService, i13.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentsComponentNgFactory = i1.ɵccf("attachments", i5.AttachmentsComponent, View_AttachmentsComponent_Host_0, {}, {}, []);
export { AttachmentsComponentNgFactory as AttachmentsComponentNgFactory };
