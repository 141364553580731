<button mat-button class="sebastian-button sebastian-button" *ngIf="!viewMode" (click)="addParentAnswer()">
  Aggiungi gruppo
</button>
<button mat-button class="sebastian-button sebastian-button" *ngIf="!viewMode && question.groupAnswers.length"
        (click)="deleteParentAnswer()">Rimuovi gruppo
</button>
<mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
  <mat-tab *ngFor="let answer of question.groupAnswers; let groupIndex = index" label="Gruppo n.{{groupIndex+1}}">
    <ng-template matTabContent>
      <div class="child-questions">
        <ng-container *ngFor="let childQuestion of question.childQuestions; trackBy: questionTrackBy; let questionIndex = index">
          <div [@collapse] *ngIf="!(assignment.status === 'inspection' && childQuestion.processingOnly) && (!childQuestion.unlockedByParentAnswerIds || childQuestion.unlockedByParentAnswerIds && childQuestion.unlockedByParentAnswerIds.includes(answer.id))">
            <question-card [question]="childQuestion"
                           [answer]="getChildAnswer(childQuestion, answer)"
                           [assignment]="assignment"
                           [viewMode]="viewMode"
                           [user]="user"
                           (answerChange)="onAnswerChange($event, answer)"
                           (pictureAdd)="onPictureAdd($event, childQuestion, answer)"
                           (pictureOpen)="onPictureOpen($event)"
                           (pictureDelete)="onPictureDelete($event)"
                           (pictureEdit)="onPictureEdit($event)"
                           [shouldFocus]="questionIndex === 0"></question-card>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<ng-container *ngIf="question.groupAnswers.length > 0">
  <button mat-button class="sebastian-button sebastian-button" *ngIf="!viewMode" (click)="addParentAnswer()">
    Aggiungi gruppo
  </button>
  <button mat-button class="sebastian-button sebastian-button" *ngIf="!viewMode && question.groupAnswers.length"
          (click)="deleteParentAnswer()">Rimuovi gruppo
  </button>
</ng-container>
