import { map } from 'rxjs/operators';
import { Question } from './question';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var QuestionsService = /** @class */ (function () {
    function QuestionsService(http) {
        this.http = http;
    }
    QuestionsService.prototype.getQuestions = function () {
        return this.http.get('questions').pipe(map(function (res) { return res.questions.map(function (question) { return new Question(question); }); }));
    };
    QuestionsService.ngInjectableDef = i0.defineInjectable({ factory: function QuestionsService_Factory() { return new QuestionsService(i0.inject(i1.ApiHttpClientService)); }, token: QuestionsService, providedIn: "root" });
    return QuestionsService;
}());
export { QuestionsService };
