import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime }          from 'rxjs/operators';
import { MatCheckboxChange }     from '@angular/material';
import { Question }              from '../../../core/models/question/question';
import { Answer }                from '../../../core/models/answer/answer';
import { collapse }              from '../../../shared/animations/collapse.animation';
import { Assignment }         from '../../../core/models/assignment/assignment';
import { DialogService }      from '../../../core/dialog/dialog.service';
import { RequiredAttachment } from '../../../core/models/required-attachment/required-attachment';
import { Picture }            from '../../../core/models/picture/picture';
import { zoomEnter }          from '../../../shared/animations/zoom-enter.animation';
import { Attachment }         from '../../../core/models/attachment/attachment';
import { zoomLeave }          from '../../../shared/animations/zoom-leave.animation';

@Component({
  selector: 'question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
  animations: [
    collapse,
    zoomEnter,
    zoomLeave
  ]
})
export class QuestionCardComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() question: Question;
  @Input() answer: Answer;
  @Input() assignment: Assignment;
  @Input() viewMode;
  @Input() shouldFocus;
  @Input() user;
  @Output() answerChange = new EventEmitter();
  @Output() pictureAdd = new EventEmitter();
  @Output() attachmentAdd = new EventEmitter();
  @Output() attachmentDelete = new EventEmitter();
  @Output() attachmentDownload = new EventEmitter();
  @Output() pictureOpen = new EventEmitter();
  @Output() pictureEdit = new EventEmitter();
  @Output() pictureDelete = new EventEmitter();
  @Output() requiredAttachmentAdd = new EventEmitter();
  @Output() requiredAttachmentEdit = new EventEmitter();
  @Output() requiredAttachmentDelete = new EventEmitter();

  value: number | string | number[];
  freeNotes: string;
  showFreeNotes: boolean;
  showInspectionValue: boolean;
  showLens = true;
  picturesCollapsed = false;
  requiredAttachmentsCollapsed = false;
  attachmentsCollapsed = false;

  shouldMenuShowFreeNotes: boolean;
  shouldMenuShowInspectionValue: boolean;
  shouldMenuShowAddRequiredAttachment: boolean;
  shouldMenuShowAddPicture: boolean;
  shouldMenuShowAddAttachment: boolean;
  shouldShowMenuTrigger: boolean

  @ViewChild('wrapper') wrapper;

  changesSubject = new Subject<QuestionChanges>();
  changesSubscription: Subscription;

  constructor(private dialogService: DialogService) {
  }

  valueChanged() {
    this.updateLens();
    this.changesSubject.next({
      questionId: this.question.id,
      value: this.value,
      freeNotes: this.freeNotes
    });
  }

  // Event handlers

  onInputChange(value) {
    this.value = value;
    this.valueChanged();
  }

  onNumericInputKeydown(event) {
    if (event.key === '-' || (this.value && (this.value.toString().includes(',') || this.value.toString().includes('.')) && (event.key === ',' || event.key === '.'))) event.preventDefault();
  }

  onSelectChange(optionId) {
    this.value = optionId;
    this.valueChanged();
  }

  onCheckboxChange(event: MatCheckboxChange, option) {
    if (event.checked) {
      (this.value as number[]).push(option.id);
    } else {
      (this.value as number[]).splice((this.value as number[]).indexOf(option.id), 1);
    }
    this.showLens = !(this.value as number[]).length;
    this.valueChanged();
  }

  onFreeNotesChange(event) {
    this.freeNotes = event;
    if (this.question.expectsFreeNotes) this.showLens = !this.freeNotes;
    this.valueChanged();
  }

  onAttachmentDownload(attachment: Attachment) {
    this.attachmentDownload.emit(attachment);
  }

  onDateDelete() {
    this.onInputChange(undefined);
  }

  async onAddPicture() {
    const upload = await this.dialogService.uploadDialog({
      resource: 'picture'
    }).toPromise();

    if (upload) {
      this.pictureAdd.emit(upload);
    }
  }

  async onAddAttachment(requiredAttachment: RequiredAttachment) {
    const options = {
      resource: 'pdf'
    };
    if (requiredAttachment) {
      options['requiredAttachmentId'] = requiredAttachment.id;
    }
    const upload = await this.dialogService.uploadDialog(options).toPromise();

    if (upload) {
      this.attachmentAdd.emit(upload);
    }
  }

  async onDeleteAttachment(attachment: Attachment, requiredAttachment?: RequiredAttachment) {
    const promptText = requiredAttachment ?
      `Confermi l'eliminazione del file ${requiredAttachment.attachment.filename}?` :
      'Confermi l\'eliminazione del file?';
    const confirm = await this.dialogService.confirmDialog(promptText).toPromise();
    if (confirm) {
      this.attachmentDelete.emit({
        attachment,
        requiredAttachment,
        answer: this.answer
      });
    }
  }

  async onDeletePicture(picture: Picture) {
    const confirm = await this.dialogService.confirmDialog(`Confermi l\'eliminazione del fotogramma?`).toPromise();
    if (confirm) {
      this.pictureDelete.emit({
        picture,
        answer: this.answer
      });
    }
  }

  async onEditPicture(picture: Picture) {
    const changes = await this.dialogService.pictureEditDialog({picture}).toPromise();

    if (changes) {
      this.pictureEdit.emit({
        picture: {...picture, caption: changes.caption},
        answer: this.answer
      });
    }
  }

  onPictureOpen(picture: Picture) {
    this.pictureOpen.emit(picture);
  }

  onTogglePicturesCollapse() {
    this.picturesCollapsed = !this.picturesCollapsed;
  }

  onToggleRequiredAttachmentsCollapse() {
    this.requiredAttachmentsCollapsed = !this.requiredAttachmentsCollapsed;
  }

  onToggleAttachmentsCollapse() {
    this.attachmentsCollapsed = !this.attachmentsCollapsed;
  }

  async onAddRequiredAttachment() {
    const description = await this.dialogService.requiredAttachmentDialog().toPromise();

    if(description) {
      this.requiredAttachmentAdd.emit(description);
    }
  }

  async onEditRequiredAttachment(requiredAttachment: RequiredAttachment) {
    const description = await this.dialogService.requiredAttachmentDialog(requiredAttachment).toPromise();

    if (description) {
      this.requiredAttachmentEdit.emit({requiredAttachment, description});
    }
  }

  async onDeleteRequiredAttachment(requiredAttachment: RequiredAttachment) {
    const confirm = await this.dialogService.confirmDialog(`Confermi l\'eliminazione del documento da richiedere?`).toPromise();
    if (confirm) {
      this.requiredAttachmentDelete.emit({
        requiredAttachment,
        question: this.question
      });
    }
  }

  // Utility methods

  getSelectText() {
    return this.value == null ? '-' : this.question.answerOptions[this.question.answerOptions.findIndex(el => el.id === this.value)].text;
  }

  getCheckboxesText() {
    return this.value == null || (this.value as number[]).length === 0 ?
      '-' :
      (this.value as number[])
        .map(optionId => this.question.answerOptions[this.question.answerOptions.findIndex(el => el.id === optionId)].text)
        .join(', ');
  }

  updateLens() {
    let temp;
    if (this.question.questionType === 'descriptive' || this.question.questionType === 'numeric' || this.question.questionType === 'dropdown' || this.question.questionType === 'date') {
      temp = !this.value;
    }
    if (this.question.questionType === 'checkboxes') {
      temp = !(this.value as number[]).length;
    }
    this.showLens = temp || this.question.expectsFreeNotes && !this.freeNotes;
  }

  computeShouldMenuShowFreeNotes() {
    return !this.showFreeNotes && !this.viewMode;
  }

  computeShouldMenuShowInspectionValue() {
    return !this.showInspectionValue && !!(this.answer && this.answer.inspectionValue) && this.assignment.status !== 'inspection';
  }

  computeShouldMenuShowAddRequiredAttachment() {
    return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status !== 'completed' && !this.question.parentQuestionId);
  }

  computeShouldMenuShowAddPicture() {
    return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status === 'processing' || this.assignment.status === 'under_revision');
  }

  computeShouldMenuShowAddAttachment() {
    return this.user && this.user.role !== 'clerk' && !this.viewMode && (this.assignment.status === 'processing' || this.assignment.status === 'under_revision');
  }

  computeShouldShowMenuTrigger() {
    return this.shouldMenuShowFreeNotes ||
      this.shouldMenuShowInspectionValue ||
      this.shouldMenuShowAddRequiredAttachment ||
      this.shouldMenuShowAddPicture ||
      this.shouldMenuShowAddAttachment;
  }

  answerOptionTrackBy(index, item) {
    return item.id;
  }

  // Lifecycle hooks

  ngOnInit() {
    if (this.answer && this.answer.value) {
      this.value = this.answer.value;
    } else {
      if (this.question.questionType === 'checkboxes') {
        this.value = [];
      }
    }
    this.freeNotes = this.answer && this.answer.freeNotes || null;
    this.showFreeNotes = this.question.expectsFreeNotes || (!!this.answer && !!this.answer.freeNotes);
    this.updateLens();

    this.changesSubscription = this.changesSubject
      .pipe(debounceTime(250))
      .subscribe(changes => {
        this.answerChange.emit({
          question: this.question,
          changes: changes
        });
      });
  }

  ngAfterViewInit() {
    if (this.shouldFocus) {
      // @ts-ignore
      setTimeout(() => {
        const el = (this.wrapper.nativeElement.querySelector('.focusable') as HTMLElement);
        if (el) {
          // @ts-ignore
          el.focus({preventScroll: true});
        }
      });
    }
  }

  ngOnChanges() {
    this.shouldMenuShowFreeNotes = this.computeShouldMenuShowFreeNotes();
    this.shouldMenuShowInspectionValue = this.computeShouldMenuShowInspectionValue();
    this.shouldMenuShowAddRequiredAttachment = this.computeShouldMenuShowAddRequiredAttachment();
    this.shouldMenuShowAddPicture = this.computeShouldMenuShowAddPicture();
    this.shouldMenuShowAddAttachment = this.computeShouldMenuShowAddAttachment();
    this.shouldShowMenuTrigger = this.computeShouldShowMenuTrigger();
  }

  ngOnDestroy() {
    this.changesSubscription.unsubscribe();
  }
}

export interface QuestionChanges {
  assignmentId?: number;
  questionId: number;
  value: string | number | number[];
  freeNotes: string;
  parentAnswerId?: number;
}
