import { Injectable }                from '@angular/core';
import { IdbWrapperService }         from '../idb-wrapper/idb-wrapper.service';
import { combineLatest, Observable } from 'rxjs';
import { Source }                    from '../models/source/source';
import { map }                       from 'rxjs/operators';
import { ScoreMapping }              from '../models/score-mapping/score-mapping';
import { Section }                   from '../models/section/section';
import { Subsection }                from '../models/subsection/subsection';
import { Question }                  from '../models/question/question';
import { ToasterService }            from '../toaster/toaster.service';
import { FactoryType }               from '../models/factory-type/factory-type';
import { HttpResponse }              from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private idbWrapper: IdbWrapperService, private toaster: ToasterService) { }

  matchByRegex(text: string, regex_text: string) {
    const regex = new RegExp(regex_text.toLowerCase().split(' ').join('.*'));
    return regex.test(text.toLowerCase());
  }

  getFilteredSources(factoryTypeId: number): Observable<Source[]> {
    return combineLatest(
      this.getFilteredScoreMappings(factoryTypeId),
      this.idbWrapper.all('sources')
    ).pipe(map(([scoreMappings, sources]: [ScoreMapping[], Source[]]) => {
      return sources.filter(source =>
        !!scoreMappings.find(scoreMapping => scoreMapping.sourceId === source.id)
      );
    }));
  }

  getFilteredScoreMappings(factoryTypeId: number): Observable<ScoreMapping[]> {
    return combineLatest(
      this.idbWrapper.all('scoreMappings'),
      this.idbWrapper.all('factoryTypes')
    ).pipe(
      map(([scoreMappings, factoryTypes]: [ScoreMapping[], FactoryType[]]) => {
        const factoryType: FactoryType = factoryTypes.find((ft: FactoryType) => ft.id === factoryTypeId);
        return scoreMappings.filter((scoreMapping: ScoreMapping) =>
          !scoreMapping.factoryKindId || scoreMapping.factoryKindId === factoryType.factoryKindId
        );
      })
    );
  }

  getFilteredSectionsTree(factoryTypeId: number, status: string): Observable<[Section[], Subsection[]]> {
    return combineLatest(
      this.idbWrapper.all('sections', 'sort'),
      this.idbWrapper.all('subsections', 'sort'),
      this.idbWrapper.all('questions', 'sort')
    ).pipe(map(([sections, subsections, questions]: [Section[], Subsection[], Question[]]) => {
      // Filter the sections by factory type id
      let filteredSections: Section[] = sections.filter(
        (section: Section) => section.factoryTypeIds.includes(factoryTypeId)
      );
      /*
      Filter the subsections, excluding those that don't belong to the current section and to the assignment factory type,
      as well as those without any question
      */
      const filteredSubsections: Subsection[] = subsections
        .filter((subsection: Subsection) =>
          subsection.factoryTypeIds.includes(factoryTypeId) &&
          filteredSections.map((section: Section) => section.id).includes(subsection.sectionId) &&
          questions
            .filter(question => question.factoryTypeIds.includes(factoryTypeId) && !(status === 'inspection' && question.processingOnly))
            .map(question => question.subsectionId)
            .includes(subsection.id)
        );

      // Remove the sections without any subsection
      filteredSections = filteredSections.filter((section: Section) =>
        filteredSubsections
          .map(subsection => subsection.sectionId)
          .includes(section.id)
      );

      // If this factoryTypeId has no sections
      if (filteredSections.length === 0) {
        this.toaster.displayError('Nessuna sezione configurata per questo tipo di stabilimento', 10000);
      }

      return [filteredSections, filteredSubsections] as ([Section[], Subsection[]]);
    }));
  }

  blobAndFilenameFromRes(res: HttpResponse<any>) {
    const filenameRegex = new RegExp('filename\\*?=[\'"]?(?:UTF-\\d[\'"]*)?([^;\\r\\n"\']*)[\'"]?;?');
    return {
      filename: filenameRegex.exec(res.headers.get('Content-Disposition'))[1],
      blob: new Blob([res.body], {type: 'application/pdf'})
    };
  }

  downloadFile(blob: Blob, filename: string) {
    const link: any = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    link.remove();
    URL.revokeObjectURL(link.href);
  }

  openFile(blob: Blob) {
    const url = URL.createObjectURL(blob);
    window.open(url);
  }
}
