
<div class="login">
  <form class="login__form">
    <h4 class="login__form__header">Login</h4>
    <div class="login__form__input sebastian-input">
      <mat-form-field floatLabel="never">
        <input matInput placeholder="Email" name="email" [(ngModel)]="email">
        <mat-hint>{{errors.email}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="login__form__input sebastian-input">
      <mat-form-field floatLabel="never">
        <input matInput type="password" placeholder="Password" name="password" [(ngModel)]="password">
        <mat-hint>{{errors.password}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="login__form__submit">
      <div class="spinner" *ngIf="submitted"></div>
      <button mat-button type="submit" class="sebastian-button sebastian-button--primary" *ngIf="!submitted" (click)="onSubmit()">Login</button>
    </div>
  </form>
</div>
