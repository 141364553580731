import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet }                                                          from '@angular/material';
import { NotesSheetComponent }                                                     from './notes-sheet/notes-sheet';
import { IdbWrapperService }                                                       from '../../core/idb-wrapper/idb-wrapper.service';
import { AssignmentsService }                                                      from '../../core/models/assignment/assignments.service';
import { debounceTime }                                                            from 'rxjs/operators';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit {

  @Input() assignment;
  @Input() viewMode;
  @Output() changed = new EventEmitter();

  constructor(private bottomSheet: MatBottomSheet,
              private assignmentsService: AssignmentsService,
              private idbWrapperService: IdbWrapperService) { }

  onClick() {
    const bottomSheetRef = this.bottomSheet.open(NotesSheetComponent, {
      data: {
        value: this.assignment.assignmentNotes,
        viewMode: this.viewMode
      },
    });
    bottomSheetRef.instance.changedEmitter.pipe(debounceTime(250)).subscribe(res => this.updateAssignment(res));
  }

  updateAssignment(notes) {
    if (this.assignment.status === 'inspection') this.idbWrapperService.update('assignments', this.assignment.id, { assignmentNotes: notes }).subscribe(_ => this.changed.emit(notes));
    else this.assignmentsService.updateAssignment({ id: this.assignment.id, assignmentNotes: notes }).subscribe(_ => this.changed.emit(notes));
  }

  ngOnInit() {
  }

}
