import { map } from 'rxjs/operators';
import { Section } from './section';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var SectionsService = /** @class */ (function () {
    function SectionsService(http) {
        this.http = http;
    }
    SectionsService.prototype.getSections = function () {
        return this.http.get('sections').pipe(map(function (res) { return res.sections.map(function (section) { return new Section(section); }); }));
    };
    SectionsService.ngInjectableDef = i0.defineInjectable({ factory: function SectionsService_Factory() { return new SectionsService(i0.inject(i1.ApiHttpClientService)); }, token: SectionsService, providedIn: "root" });
    return SectionsService;
}());
export { SectionsService };
