import { Component, OnDestroy, OnInit }    from '@angular/core';
import { DialogService }                   from '../../core/dialog/dialog.service';
import { switchMap, tap }                  from 'rxjs/operators';
import { ActivatedRoute }                  from '@angular/router';
import { AssignmentsService }              from '../../core/models/assignment/assignments.service';
import { SideMenuService }                 from '../../core/side-bar/side-menu/side-menu.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { RequiredAttachmentService }       from '../../core/models/required-attachment/required-attachment.service';
import { Assignment }                      from '../../core/models/assignment/assignment';
import { RequiredAttachment }              from '../../core/models/required-attachment/required-attachment';
import { Attachment }         from '../../core/models/attachment/attachment';
import { AttachmentsService } from '../../core/models/attachment/attachments.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { Question }          from '../../core/models/question/question';
import { UtilsService }      from '../../core/utils/utils.service';
import { AnswersService }    from '../../core/models/answer/answers.service';
import { Answer }            from '../../core/models/answer/answer';
import { NetStatusService }  from '../../core/net-status/net-status.service';
import { UserService }       from '../../core/user/user.service';
import { HttpResponse }      from '@angular/common/http';

@Component({
  selector: 'required-attachments',
  templateUrl: './required-attachments.component.html',
  styleUrls: ['./required-attachments.component.scss']
})
export class RequiredAttachmentsComponent implements OnInit, OnDestroy {
  assignment: Assignment;
  requiredAttachments: RequiredAttachment[];
  private activatedRouteSubscription: Subscription;
  assignmentId: number;
  loading = false;

  constructor(private dialog: DialogService,
              private activatedRoute: ActivatedRoute,
              private assignmentsService: AssignmentsService,
              private sideMenuService: SideMenuService,
              private requiredAttachmentsService: RequiredAttachmentService,
              private attachmentsService: AttachmentsService,
              private idbWrapper: IdbWrapperService,
              private utils: UtilsService,
              private answersService: AnswersService,
              private netStatus: NetStatusService,
              public userService: UserService) {
  }

  onAttachmentDownload(attachment: Attachment) {
    attachment.busy = true;
    this.attachmentsService.getAttachment(attachment.id).subscribe((res: HttpResponse<any>) => {
      const {blob, filename} = this.utils.blobAndFilenameFromRes(res);
      this.utils.downloadFile(blob, filename);
      attachment.busy = false;
    });
  }

  async onAttachmentDelete(requiredAttachment: RequiredAttachment) {
    const confirm = await this.dialog.confirmDialog(`Confermi l\'eliminazione del file ${requiredAttachment.attachment.filename}?`).toPromise();
    if (confirm) {
      requiredAttachment.busy = true;
      this.attachmentsService.deleteAttachment(requiredAttachment.attachment.id).subscribe(() => {
        requiredAttachment.attachment = null;
        requiredAttachment.busy = false;
      });
    }
  }

  async onAttachmentAdd(requiredAttachment: RequiredAttachment) {
    const options = {
      resource: 'pdf',
      requiredAttachmentId: requiredAttachment.id
    };
    const upload: {file: any, requiredAttachmentId: number} = await this.dialog.uploadDialog(options).toPromise();

    if (upload) {
      requiredAttachment.busy = true;
      this.answersService.getAnswers(this.assignmentId).pipe(
        switchMap((answers: Answer[]) => {
          const questionAnswer = answers.find((answer: Answer) => answer.questionId === requiredAttachment.questionId);
          if (questionAnswer) {
            return of(questionAnswer);
          } else {
            return this.answersService.createAnswer(this.assignmentId, {questionId: requiredAttachment.questionId});
          }
        }),
        switchMap((answer: Answer) => this.attachmentsService.createAttachment({
          ...upload,
          answerId: answer.id
        }))
      ).subscribe((attachment: Attachment) => {
        requiredAttachment.attachment = attachment;
        requiredAttachment.busy = false;
      });
    }
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRouteSubscription = this.activatedRoute.params.pipe(
      switchMap(() => this.activatedRoute.params),
      tap(res => this.assignmentId = +res.id),
      switchMap(() => this.netStatus.onlineStatus),
      switchMap((isOnline: boolean) => this.assignmentsService.getAssignment(isOnline, this.assignmentId)),
      tap((assignment: Assignment) => this.assignment = assignment),
      switchMap(() => combineLatest([
        this.assignment.status === 'inspection' ? this.idbWrapper.all('requiredAttachments') : this.requiredAttachmentsService.getRequiredAttachments(this.assignmentId),
        this.idbWrapper.all('questions')
      ]))
    ).subscribe(
      ([requiredAttachments, questions]: [RequiredAttachment[], Question[]]) => {
        this.sideMenuService.sideMenuState = {state: 'attachments', assignment: this.assignment, currentSubRoute: 'required-attachments'};
        if (this.assignment.status === 'inspection') {
          requiredAttachments = [...requiredAttachments, ...this.assignment.requiredAttachments];
        }
        requiredAttachments.forEach((requiredAttachment: RequiredAttachment) => {
            const question = questions.find((q: Question) => q.id === requiredAttachment.questionId);
            if (question) {
              requiredAttachment.questionText = (
                question.questionTexts.find(text => text.factoryTypeId === this.assignment.factoryTypeId) ||
                question.questionTexts.find(text => text.factoryTypeId === null)
              ).text;
            }
          }
        );
        this.requiredAttachments = requiredAttachments;
        this.loading = false;
      });
  }

  ngOnDestroy() {
    if (this.activatedRouteSubscription) this.activatedRouteSubscription.unsubscribe();
  }

}
