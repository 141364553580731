import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';
import { UserService } from '../core/user/user.service';

@Component({
  selector: 'password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss', '../login/login.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  newPassword;
  newPasswordConfirm;
  error = '';
  submitted = false;

  constructor(private router: Router, private userService: UserService) { }

  onPasswordChange() {
    this.submitted = true;
    if (this.newPassword !== this.newPasswordConfirm) {
      this.error = 'Le password devono essere uguali';
      this.submitted = false;
    } else if (this.newPassword.length < 8) {
      this.error = 'La password deve essere di almeno 8 caratteri';
      this.submitted = false;
    } else {
      this.userService.updatePassword({password: this.newPassword, password_confirmation: this.newPasswordConfirm}).subscribe(
        res => {
          this.router.navigate(['/dashboard']);
        },
        error => {
          this.submitted = false;
        });
    }
  }

  ngOnInit() {
  }

}
