export class FactoryType {
  id: number;
  name: string;
  factoryKindId: number;

  constructor(obj) {
    this.id =               obj && obj.id;
    this.name =             obj && obj.name                 || null;
    this.factoryKindId =    obj && obj.factory_kind_id;
  }
}
