var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Dexie from 'dexie';
import { Assignment } from '../models/assignment/assignment';
import { FactoryType } from '../models/factory-type/factory-type';
import { AssignmentStatus } from '../models/assignment-status/assignment-status';
import { Section } from '../models/section/section';
import { Subsection } from '../models/subsection/subsection';
import { Question } from '../models/question/question';
import { Source } from '../models/source/source';
import { Target } from '../models/target/target';
import { ScoreMapping } from '../models/score-mapping/score-mapping';
import { AssignmentField } from '../models/assignment-field/assignment-field';
import { RequiredAttachment } from '../models/required-attachment/required-attachment';
var SebastianDb = /** @class */ (function (_super) {
    __extends(SebastianDb, _super);
    function SebastianDb() {
        var _this = _super.call(this, 'SebastianDb') || this;
        // If you make any change to the DB structure, make sure you create a new version of it
        _this.version(3).stores({
            assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes, requiredAttachments',
            subsections: '++id, name, sort, sectionId',
            sections: '++id, name, sort',
            factoryTypes: '++id, name, factoryKindId',
            assignmentStatuses: '++id, description',
            assignmentFields: '++id, name, mnemonicLabel, wildcard',
            questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
            answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
            sources: '++id, name',
            targets: '++id, name',
            scoreMappings: '++id, sourceId, targetId, factoryKindId',
            requiredAttachments: '++id, label, questionId, description'
        });
        _this.version(2).stores({
            assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes, requiredAttachments',
            subsections: '++id, name, sort, sectionId',
            sections: '++id, name, sort',
            factoryTypes: '++id, name',
            assignmentStatuses: '++id, description',
            assignmentFields: '++id, name, mnemonicLabel, wildcard',
            questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
            answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
            sources: '++id, name',
            targets: '++id, name',
            scoreMappings: '++id, sourceId, targetId, factoryTypeId',
            requiredAttachments: '++id, label, questionId, description'
        });
        _this.version(1).stores({
            assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes',
            subsections: '++id, name, sort, sectionId',
            sections: '++id, name, sort',
            factoryTypes: '++id, name',
            assignmentStatuses: '++id, description',
            assignmentFields: '++id, name, mnemonicLabel, wildcard',
            questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
            answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
            sources: '++id, name',
            targets: '++id, name',
            scoreMappings: '++id, sourceId, targetId, factoryTypeId'
        });
        _this.assignments.mapToClass(Assignment);
        _this.factoryTypes.mapToClass(FactoryType);
        _this.assignmentStatuses.mapToClass(AssignmentStatus);
        _this.assignmentFields.mapToClass(AssignmentField);
        _this.sections.mapToClass(Section);
        _this.subsections.mapToClass(Subsection);
        _this.questions.mapToClass(Question);
        _this.sources.mapToClass(Source);
        _this.targets.mapToClass(Target);
        _this.scoreMappings.mapToClass(ScoreMapping);
        _this.requiredAttachments.mapToClass(RequiredAttachment);
        return _this;
    }
    return SebastianDb;
}(Dexie));
export { SebastianDb };
