import { Component, Inject, OnInit }     from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'choice-dialog',
  templateUrl: './choice-dialog.html',
  styleUrls: ['./choice-dialog.scss']
})
export class ChoiceDialogComponent implements OnInit {

  user = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  onUserChanged(event) {
    this.user = event;
  }

  ngOnInit() {
  }


}
