import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
var SideMenuService = /** @class */ (function () {
    function SideMenuService() {
        this.sideMenuStateSource$ = new Subject();
        this.sideMenuState$ = this.sideMenuStateSource$
            .asObservable()
            .pipe(distinctUntilChanged(function (previous, next) {
            return previous.state === next.state &&
                (previous.assignment == null || next.assignment == null || (previous.assignment.id === next.assignment.id)) &&
                previous.currentSubRoute === next.currentSubRoute;
        }));
        this.links$ = new BehaviorSubject([
            {
                routerLink: '/dashboard',
                label: 'Dashboard incarichi',
                complete: true
            },
            {
                routerLink: '/assignments',
                label: 'Archivio incarichi',
                complete: true
            }
        ]);
    }
    Object.defineProperty(SideMenuService.prototype, "sideMenuState", {
        set: function (newState) {
            this.sideMenuStateSource$.next(newState);
        },
        enumerable: true,
        configurable: true
    });
    SideMenuService.ngInjectableDef = i0.defineInjectable({ factory: function SideMenuService_Factory() { return new SideMenuService(); }, token: SideMenuService, providedIn: "root" });
    return SideMenuService;
}());
export { SideMenuService };
