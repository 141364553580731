import { NgModule }                         from '@angular/core';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule, MatSelectModule
}                                           from '@angular/material';
import { DialogService }                    from './dialog.service';
import { ConfirmDialogComponent }           from './confirm-dialog/confirm-dialog';
import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule }  from '@angular/forms';
import { ChoiceDialogComponent }             from './choice-dialog/choice-dialog';
import { ChoiceDialogService }               from './choice-dialog/choice-dialog.service';
import { UploadDialogComponent }             from './upload-dialog/upload-dialog';
import { UploadDialogService }               from './upload-dialog/upload-dialog.service';
import { PictureEditDialogComponent }        from './picture-edit-dialog/picture-edit-dialog';
import { RequiredAttachmentDialogComponent } from './required-attachment-dialog/required-attachment-dialog';
import { UserAutocompleteFormFieldModule }   from '../../shared/user-autocomplete-form-field/user-autocomplete-form-field.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    UserAutocompleteFormFieldModule
  ],
  declarations: [
    ConfirmDialogComponent,
    ChoiceDialogComponent,
    UploadDialogComponent,
    PictureEditDialogComponent,
    RequiredAttachmentDialogComponent
  ],
  providers: [
    DialogService,
    ChoiceDialogService,
    UploadDialogService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ChoiceDialogComponent,
    UploadDialogComponent,
    PictureEditDialogComponent,
    RequiredAttachmentDialogComponent
  ]
})
export class DialogModule {}
