export class Picture {
  id: number;
  answerId: number;
  caption: string;
  sort: number;
  thumbnail: any;
  questionId: number;

  questionText: string;
  updating: boolean;

  constructor(obj: any) {
    this.id = obj && obj.id;
    this.answerId = obj && obj.answer_id;
    this.caption = obj && obj.caption;
    this.sort = obj && obj.sort;
    this.thumbnail = obj && obj.thumbnail && this.base64Prefix(obj.thumbnail);
    this.questionId = obj && obj.question_id;
  }

  private base64Prefix(data: string): string {
    return `data:image/jpeg;base64,${data}`;
  }
}
