import { Component, OnDestroy, OnInit }            from '@angular/core';
import { DashboardService }                        from './dashboard.service';
import { SideMenuService }                         from '../core/side-bar/side-menu/side-menu.service';
import { DashboardData }                           from './dashboard-data';
import { IdbWrapperService }                       from '../core/idb-wrapper/idb-wrapper.service';
import { Assignment }                              from '../core/models/assignment/assignment';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap }                     from 'rxjs/operators';
import { NetStatusService }                        from '../core/net-status/net-status.service';
import { Router }                                  from '@angular/router';
import { AssignmentsService }                      from '../core/models/assignment/assignments.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  columnHeaders = {
    'practiceNumber': 'Numero pratica',
    'insured': 'Assicurato',
    'principal': 'Mandante',
    'factoryType': 'Tipo di stabilimento',
    'compilerName': 'Compilatore',
    'reviserName': 'Revisore',
    'status': 'Stato',
    'download': ''
  };
  columns = ['practiceNumber', 'insured', 'principal', 'factoryType', 'compilerName', 'reviserName', 'status', 'download'];
  dashboardData: DashboardData = {
    inspection: [],
    processing: [],
    underRevision: []
  };
  dashboardLoading: boolean;
  subscription: Subscription;

  constructor(private dashboardService: DashboardService,
              private assignmentsService: AssignmentsService,
              private sideMenuService: SideMenuService,
              private idbWrapper: IdbWrapperService,
              public netStatus: NetStatusService,
              private router: Router) {
  }

  toggleOffline(assignment: Assignment) {
    if (assignment.offline) {
      this.idbWrapper.delete('assignments', assignment.id).subscribe(
        res => {
          assignment.offline = false;
        }, error => {
          console.error(error.message);
        });
    } else {
      this.assignmentsService.downloadAssignment(assignment).subscribe(
        () => {
          assignment.offline = true;
        }, error => {
          console.error(error.message);
        });
    }
  }

  getDashboardData(isOnline = true): Observable<DashboardData> {
    if (isOnline) {
      return combineLatest([
        this.dashboardService.getDashboardData(),
        this.idbWrapper.all('assignments')
      ]).pipe(
        map(([dashboardData, offlineAssignments]) => ({
          inspection: dashboardData.inspection.map(assignment => ({
            ...assignment,
            offline: !!((offlineAssignments as any[]).find(item => item.id === assignment.id))
          })),
          processing: dashboardData.processing,
          underRevision: dashboardData.underRevision
        }))
      );
    } else {
      return this.idbWrapper.all('assignments').pipe(
        map(assignments => assignments.map(assignment => ({
          ...assignment,
          offline: true
        })))
      ).pipe(
        map((offlineAssignments) => ({
          inspection: offlineAssignments,
          processing: [],
          underRevision: []
        }))
      );
    }
  }

  openAssignment(assignment: Assignment) {
    this.router.navigate(['assignments', assignment.id]);
  }

  ngOnInit() {
    this.subscription = this.netStatus.onlineStatus.pipe(
      tap(() => this.dashboardLoading = true),
      switchMap((isOnline: boolean) => this.getDashboardData(isOnline))
    ).subscribe(dashboardData => {
      this.dashboardData = dashboardData;
      this.dashboardLoading = false;
    });

    this.sideMenuService.sideMenuState = {state: 'root'};
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
