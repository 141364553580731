<div class="attachment-chip">
  <mat-chip-list>
    <mat-chip (click)="onDownload()" color="primary">
      <mat-icon svgIcon="picture_as_pdf"></mat-icon>
      {{attachment.filename}}

      <mat-icon matChipRemove *ngIf="canDelete" (click)="onDelete()" svgIcon="close"></mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>
