<div class="question-card" [class.question-card--lens]="showLens && !viewMode" [class.question-card--dashed]="question.unlockedByIds.length" #wrapper>
  <div class="question-card__header">
    <div class="question-card__header__text">
      <p>{{question.questionText}}</p>
      <mat-icon svgIcon="library_books"
                *ngIf="question.templateMappingRule && !question.autoMapNotes"
                [matTooltip]="'Risposta in relazione (' + question.templateMappingRule.wildcard + ')'"
                matTooltipPosition="right"
                matTooltipClass="question-card__header__actions__tooltip"></mat-icon>
    </div>
    <div class="question-card__header__actions">
      <mat-icon svgIcon="memory"
                *ngIf="question.questionCode"></mat-icon>
      <button
        [hidden]="!shouldShowMenuTrigger"
        mat-icon-button [matMenuTriggerFor]="menu" tabindex="-1">
        <mat-icon svgIcon="more_vert"></mat-icon>
      </button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
        <button mat-menu-item (click)="showFreeNotes = true" *ngIf="shouldMenuShowFreeNotes">Aggiungi note</button>
        <button mat-menu-item
                (click)="showInspectionValue = true"
                *ngIf="shouldMenuShowInspectionValue">
          Mostra risposta sopralluogo
        </button>
        <button mat-menu-item
                (click)="onAddRequiredAttachment()"
                *ngIf="shouldMenuShowAddRequiredAttachment">
          Aggiungi allegato
        </button>
        <button mat-menu-item
                (click)="onAddPicture()"
                *ngIf="shouldMenuShowAddPicture">
          Aggiungi fotogramma
        </button>
        <button mat-menu-item
                (click)="onAddAttachment()"
                *ngIf="shouldMenuShowAddAttachment">
          Aggiungi altro documento
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="question-card__body">
    <div class="question-card__body__input sebastian-input"
         *ngIf="question.questionType === 'descriptive' || question.questionType === 'numeric'">
      <textarea class="focusable"
                placeholder=""
                [ngModel]="value"
                (ngModelChange)="onInputChange($event);"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                *ngIf="question.questionType === 'descriptive' && !viewMode"></textarea>
      <mat-form-field floatLabel="never" class="extra-small" *ngIf="!viewMode && question.questionType === 'numeric'">
        <input matInput
               class="focusable"
               placeholder=""
               [ngModel]="value"
               (ngModelChange)="onInputChange($event);"
               (keydown)="onNumericInputKeydown($event)"
               type="number"/>
        <span matSuffix>{{question.placeholder}}</span>
      </mat-form-field>
      <p *ngIf="viewMode">{{value == null ? '-' : [value, question.placeholder].join(' ')}}</p>
    </div>

    <div class="question-card__body__input sebastian-input sebastian-datepicker"
         *ngIf="question.questionType === 'date'">
      <mat-form-field floatLabel="never" class="small" *ngIf="!viewMode">
        <input matInput
               class="focusable"
               [matDatepicker]="picker"
               [ngModel]="value"
               (ngModelChange)="onInputChange($event)"
               (keydown)="$event.preventDefault()">
        <button class="mat-datepicker-toggle" mat-icon-button matSuffix *ngIf="value" (click)="onDateDelete();">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #picker></mat-datepicker>
      </mat-form-field>
      <p *ngIf="viewMode">{{value ? (value | date: 'shortDate') : '-'}}</p>
    </div>

    <div class="sebastian-select sebastian-select--border" *ngIf="question.questionType === 'dropdown'">
      <mat-form-field floatLabel="never" *ngIf="!viewMode">
        <mat-select placeholder="Seleziona la risposta"
                    [ngModel]="value"
                    (ngModelChange)="onSelectChange($event)"
                    class="focusable">
          <mat-option [value]="null">
          </mat-option>
          <mat-option [value]="option.id"
                      *ngFor="let option of question.answerOptions; trackBy: answerOptionTrackBy">
            {{option.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="viewMode">{{getSelectText()}}</p>
    </div>
    <div class="question-card__body__checkboxes" *ngIf="question.questionType === 'checkboxes' && !viewMode">
      <mat-checkbox [value]="option.id"
                    [checked]="value.includes(option.id)"
                    (change)="onCheckboxChange($event, option)"
                    class="focusable"
                    *ngFor="let option of question.answerOptions; trackBy: answerOptionTrackBy"
                    color="primary">{{option.text}}
      </mat-checkbox>
    </div>

    <div class="question-card__body__checkboxes question-card__body__checkboxes--answer"
         *ngIf="question.questionType === 'checkboxes' && viewMode">
      <p>{{getCheckboxesText()}}</p>
    </div>

    <div *ngIf="showFreeNotes" [@collapse] class="question-card__body__free-notes">
      <div class="question-card__body__label">
        Note
        <mat-icon svgIcon="library_books"
                  *ngIf="question.templateMappingRule && question.autoMapNotes"
                  [matTooltip]="'Note in relazione (' + question.templateMappingRule.wildcard + ')'"
                  matTooltipPosition="right"
                  matTooltipClass="question-card__header__actions__tooltip"></mat-icon>
      </div>
      <textarea [ngModel]="freeNotes"
                (ngModelChange)="onFreeNotesChange($event);"
                class="focusable"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="3"
                *ngIf="!viewMode"></textarea>
      <p *ngIf="viewMode">{{freeNotes}}</p>
    </div>

    <div *ngIf="showInspectionValue" [@collapse] class="question-card__body__inspection-value">
      <div class="question-card__body__label">Risposta data in sopralluogo</div>
      <p [innerHtml]="answer?.inspectionValue"></p>
    </div>

    <div class="question-card__body__pictures" *ngIf="answer?.pictures?.length > 0"
         [@collapse]>
      <div class="question-card__body__label--collapsible"
           (click)="onTogglePicturesCollapse()" [ngClass]="{'collapsed': picturesCollapsed}">
        <p>Fotogrammi</p>
        <mat-icon svgIcon="expand_less"></mat-icon>
      </div>
      <div class="question-card__body__pictures__images" *ngIf="!picturesCollapsed" [@collapse]>
        <ng-container *ngFor="let picture of answer.pictures">
          <div class="question-card__body__pictures__images__image-container"
               *ngIf="!picture.updating"
               [@zoomEnter] [@zoomLeave]>
            <div class="question-card__body__pictures__images__image"
                 [style]="'background-image: url(\'' + picture.thumbnail + '\')' | safe: {type: 'style'}"
                 (click)="onPictureOpen(picture)">
            </div>
            <div class="question-card__body__pictures__images__description">
              <p>{{picture.caption}}</p>
              <button mat-icon-button [matMenuTriggerFor]="pictureMenu" *ngIf="!viewMode">
                <mat-icon svgIcon="more_vert"></mat-icon>
              </button>
              <mat-menu #pictureMenu="matMenu" yPosition="below" xPosition="before">
                <button mat-menu-item (click)="onEditPicture(picture)">Modifica</button>
                <button mat-menu-item (click)="onDeletePicture(picture)">Elimina</button>
              </mat-menu>
            </div>
          </div>

          <div *ngIf="picture.updating" class="question-card__body__pictures__images__image-container uploading"
               [@zoomEnter]>
            <div class="question-card__body__pictures__images__image">
              <div class="spinner"></div>
            </div>
            <div class="question-card__body__pictures__images__description">
              <p>{{picture.caption}}</p>
            </div>
          </div>
        </ng-container>
        <div class="question-card__body__pictures__images__image-container"
             *ngIf="!viewMode && (assignment.status === 'processing' || assignment.status === 'under_revision')">
          <div class="question-card__body__pictures__images__add"
               (click)="onAddPicture()">
            Aggiungi fotogramma
          </div>
        </div>
      </div>
    </div>

    <div class="question-card__body__required-attachments" *ngIf="question.requiredAttachments.length > 0" [@collapse]>
      <div class="question-card__body__label--collapsible"
           (click)="onToggleRequiredAttachmentsCollapse()" [ngClass]="{'collapsed': requiredAttachmentsCollapsed}">
        <p>Documenti da richiedere</p>
        <mat-icon svgIcon="expand_less"></mat-icon>
      </div>
      <div class="question-card__body__required-attachments__attachment-list" *ngIf="!requiredAttachmentsCollapsed" [@collapse]>
        <div class="question-card__body__required-attachments__attachment"
             *ngFor="let requiredAttachment of question.requiredAttachments" [@zoomEnter] [@zoomLeave]>
          <div>{{ requiredAttachment.description }}</div>
          <button *ngIf="!!requiredAttachment.assignmentId && !viewMode"
                  mat-icon-button [matMenuTriggerFor]="requiredAttachmentMenu" tabindex="-1">
            <mat-icon svgIcon="more_vert"></mat-icon>
          </button>
          <mat-menu #requiredAttachmentMenu="matMenu" yPosition="below" xPosition="before">
            <button mat-menu-item (click)="onEditRequiredAttachment(requiredAttachment)">Modifica</button>
            <button mat-menu-item (click)="onDeleteRequiredAttachment(requiredAttachment)">Elimina</button>
          </mat-menu>
          <ng-container *ngIf="assignment.status !== 'inspection'">
            <div *ngIf="!requiredAttachment.attachment && !viewMode">
              <button mat-button
                      class="icon-button"
                      *ngIf="!requiredAttachment.busy && !requiredAttachment.attachment?.busy"
                      (click)="onAddAttachment(requiredAttachment)">
                <mat-icon svgIcon="attach_file"></mat-icon>
                Aggiungi allegato
              </button>
            </div>
            <attachment-chip *ngIf="requiredAttachment.attachment && !requiredAttachment.busy && !requiredAttachment.attachment?.busy"
                             [attachment]="requiredAttachment.attachment"
                             [canDelete]="!viewMode"
                             (download)="onAttachmentDownload(requiredAttachment.attachment)"
                             (delete)="onDeleteAttachment(requiredAttachment.attachment, requiredAttachment)">
            </attachment-chip>
            <div class="spinner" *ngIf="requiredAttachment.busy || requiredAttachment.attachment?.busy"></div>
          </ng-container>

        </div>
      </div>
    </div>
    <div class="question-card__body__attachments" *ngIf="answer?.attachments?.length > 0" [@collapse]>
      <div class="question-card__body__label--collapsible"
           (click)="onToggleAttachmentsCollapse()" [ngClass]="{'collapsed': attachmentsCollapsed}">
        <p>Altri documenti</p>
        <mat-icon svgIcon="expand_less"></mat-icon>
      </div>
      <div class="question-card__body__attachments__attachments-list" *ngIf="!attachmentsCollapsed" [@collapse]>
        <attachment-chip *ngFor="let attachment of answer.attachments"
                         [attachment]="attachment"
                         [@zoomEnter] [@zoomLeave]
                         [canDelete]="!viewMode"
                         (download)="onAttachmentDownload(attachment)"
                         (delete)="onDeleteAttachment(attachment)"></attachment-chip>
        <button mat-button
                *ngIf="!viewMode"
                class="icon-button"
                (click)="onAddAttachment()">
          <mat-icon svgIcon="attach_file"></mat-icon>
          Aggiungi documento
        </button>
      </div>
    </div>
  </div>
</div>
