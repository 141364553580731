import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { ScoreMapping }         from './score-mapping';

@Injectable({
  providedIn: 'root'
})
export class ScoreMappingsService {

  constructor(private http: ApiHttpClientService) { }

  getScoreMappings(): Observable<ScoreMapping[]> {
    return this.http.get('score_mappings').pipe(
      map((res: any) => res.score_mappings.map(scoreMapping => new ScoreMapping(scoreMapping)))
    );
  }
}
