export class RiskScore {
  id: number;
  assignmentId: number;
  scoreMappingId: number;
  magnitudeScore: number;
  probabilityScore: number;
  notes: number;

  constructor(obj) {
    this.id =                 obj && obj.id;
    this.assignmentId =       obj && obj.assignment_id;
    this.scoreMappingId =     obj && obj.score_mapping_id;
    this.magnitudeScore =     obj && obj.magnitude_score;
    this.probabilityScore =   obj && obj.probability_score;
    this.notes =              obj && obj.notes;
  }
}
