import * as AirbrakeClient   from 'airbrake-js';
import { ErrorHandler } from '@angular/core';
import { environment }  from '../../../environments/environment';

export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: AirbrakeClient;

  constructor() {
    if (environment.production) this.airbrake = new AirbrakeClient({
      host: environment.errorsUrl,
      projectId: 1,
      projectKey: environment.airbrakeProjectKey
    });
  }

  handleError(error: any): void {
    console.error(error);
    if (environment.production) this.airbrake.notify(error);
  }
}
