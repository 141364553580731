import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { Answer }               from './answer';
import { map }                  from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnswersService {
  private createAnswerUrl(assignmentId: number) {
    return `assignments/${assignmentId}/answers`;
  }

  private updateAnswerUrl(answerId: number) {
    return `answers/${answerId}`;
  }

  private deleteAnswerUrl(answerId: number) {
    return `answers/${answerId}`;
  }

  constructor(private http: ApiHttpClientService) {
  }

  getAnswers(assignmentId: number): Observable<Answer[]> {
    return this.http.get(`assignments/${assignmentId}/answers`).pipe(
      map((res: any) => res.answers.map(answer => new Answer(answer)))
    );
  }

  createAnswer(assignmentId: number, answer: any): Observable<Answer> {
    const bodyString = JSON.stringify({
      answer: {
        question_id: answer.questionId,
        value: answer.value,
        free_notes: answer.freeNotes,
        parent_answer_id: answer.parentAnswerId
      }
    });
    return this.http.post(this.createAnswerUrl(assignmentId), bodyString).pipe(
      map((res: any) => new Answer(res))
    );
  }

  updateAnswer(answerId: number, answer: any): Observable<Answer> {
    const bodyString = JSON.stringify({
      answer: {
        value: answer.value,
        free_notes: answer.freeNotes
      }
    });
    return this.http.put(this.updateAnswerUrl(answerId), bodyString).pipe(
      map((res: any) => new Answer(res))
    );
  }

  deleteAnswer(answerId: number): Observable<Answer> {
    return this.http.delete(this.deleteAnswerUrl(answerId)).pipe(
      map((res: any) => new Answer(res))
    );
  }
}
