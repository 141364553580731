import { User }               from '../../user/user';
import { RequiredAttachment } from '../required-attachment/required-attachment';

export class Assignment {
  id: number;
  practiceNumber: string;
  policyNumber: string;
  accidentNumber: string;
  factoryType: number;
  factoryTypeId: number;
  customerName: string;
  agency: string;
  agencyCode: string;
  insured: string;
  surveyor: string;
  principal: string;
  location: string;
  status: string;
  translatedStatus: string;
  updatedAt: Date;
  offline: boolean;
  assignmentNotes: string;
  compiler: User;
  reviser: User;
  compilerName: string;
  reviserName: string;

  // This fields holds the required attachments specific to this assignment while we're in inspection, for future syncs.
  requiredAttachments: RequiredAttachment[] = [];

  constructor(obj) {
    this.id =               obj && obj.id;
    this.practiceNumber =   obj && obj.practice_number        || null;
    this.policyNumber =     obj && obj.policy_number          || null;
    this.accidentNumber =   obj && obj.accident_number        || null;
    this.factoryType =      obj && obj.type                   || null;
    this.factoryTypeId =    obj && obj.factory_type_id        || null;
    this.customerName =     obj && obj.customer_name          || '';
    this.insured =          obj && obj.insured                || '';
    this.surveyor =         obj && obj.surveyor               || '';
    this.principal =        obj && obj.principal              || '';
    this.agency =           obj && obj.agency                 || '';
    this.agencyCode =       obj && obj.agency_code            || '';
    this.location =         obj && obj.location               || '';
    this.status =           obj && obj.status                 || '';
    this.translatedStatus = obj && obj.translated_status && obj.translated_status.toLowerCase()       || '';
    this.updatedAt =        obj && obj.updated_at             || null;
    this.offline =          obj && obj.offline !== undefined ? obj.offline : false;
    this.assignmentNotes =  obj && obj.assignment_notes       || '';
    this.compiler =         obj && obj.compiler && new User(obj.compiler) || null;
    this.compilerName =     this.compiler && this.compiler.name;
    this.reviser =          obj && obj.reviser && new User(obj.reviser) || null;
    this.reviserName =     this.reviser && this.reviser.name;
  }
}
