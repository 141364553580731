import { Component, Inject, OnInit }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fade }                          from '../../../shared/animations/fade.animation';
import * as EXIF from 'exif-js/exif';

@Component({
  selector: 'upload-dialog',
  templateUrl: './upload-dialog.html',
  styleUrls: ['./upload-dialog.scss'],
  animations: [
    fade
  ]
})
export class UploadDialogComponent implements OnInit {

  dragOver = false;
  file;
  imgPreview;
  caption: string;
  text: string;
  accept: string;
  private dragEnterCounter = 0;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      resource: 'picture' | 'pdf',
      requiredAttachmentId?: number
    }) {}

  onSubmit() {
    if (this.data.resource === 'picture') {
      this.dialogRef.close({
        file: this.file,
        caption: this.caption
      });
    } else {
      if (this.data.requiredAttachmentId) {
        this.dialogRef.close({
          file: this.file,
          requiredAttachmentId: this.data.requiredAttachmentId
        });
      } else {
        this.dialogRef.close({
          file: this.file
        });
      }
    }
  }

  onFileUpload(file) {
    loadImage(file, img => {
      EXIF.getData(file, () => {
        const orientation = EXIF.getTag(file, 'Orientation');
        const rotated = loadImage.scale(img, {orientation: orientation || 0});
        this.imgPreview = rotated.toDataURL();
      });
    });
    this.imgPreview = null;
    this.file = file;
  }

  onDrop(event) {
    this.dragOver = false;
    this.dragEnterCounter = 0;
    this.onFileUpload(event.dataTransfer.files[0]);
  }

  onDragOver(value) {
    if (value) this.dragEnterCounter++;
    else this.dragEnterCounter--;
    this.dragOver = !!this.dragEnterCounter;
  }

  onFileDelete() {
    this.file = undefined;
  }

  ngOnInit() {
    if (this.data.resource === 'picture') {
      this.text = 'un fotogramma';
      this.accept = 'image/*';
    } else {
      this.text = 'un documento PDF';
      this.accept = 'application/pdf';
    }
    // Prevent browser from loading a drag-and-dropped file
    window.addEventListener('dragover', (e) => {
      // @ts-ignore
      e = e || event;
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e) => {
      // @ts-ignore
      e = e || event;
      e.preventDefault();
    }, false);
  }

}
