import { Injectable }                                                             from '@angular/core';
import { IdbWrapperService }                                                      from '../idb-wrapper/idb-wrapper.service';
import { combineLatest, concat, merge, Observable, of }                           from 'rxjs';
import { FactoryTypesService }                                                    from '../models/factory-type/factory-types.service';
import { AssignmentStatusesService }                                              from '../models/assignment-status/assignment-statuses.service';
import { CanActivate }                                                            from '@angular/router';
import { catchError, flatMap, map, mapTo, scan, startWith, switchMap, take, tap } from 'rxjs/operators';
import { SectionsService }                                                        from '../models/section/sections.service';
import { SubsectionsService }                                                     from '../models/subsection/subsections.service';
import { NetStatusService }                                                       from '../net-status/net-status.service';
import { ToasterService }                                                         from '../toaster/toaster.service';
import { QuestionsService }        from '../models/question/questions.service';
import { Assignment }                from '../models/assignment/assignment';
import { Answer }                    from '../models/answer/answer';
import { AnswersService }            from '../models/answer/answers.service';
import { SourcesService }            from '../models/source/sources.service';
import { TargetsService }            from '../models/target/targets.service';
import { ScoreMappingsService }      from '../models/score-mapping/score-mappings.service';
import { AssignmentFieldsService }   from '../models/assignment-field/assignment-fields.service';
import { RequiredAttachmentService } from '../models/required-attachment/required-attachment.service';
import { AssignmentsService }        from '../models/assignment/assignments.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService implements CanActivate {
  syncDone = false;

  constructor(private netStatus: NetStatusService,
              private idbWrapper: IdbWrapperService,
              private factoryTypesService: FactoryTypesService,
              private assignmentStatusesService: AssignmentStatusesService,
              private assignmentFieldsService: AssignmentFieldsService,
              private sectionsService: SectionsService,
              private subsectionsService: SubsectionsService,
              private questionsService: QuestionsService,
              private sourcesService: SourcesService,
              private targetsService: TargetsService,
              private scoreMappingsService: ScoreMappingsService,
              private toaster: ToasterService,
              private answersService: AnswersService,
              private requiredAttachmentsService: RequiredAttachmentService,
              private assignmentsService: AssignmentsService) {
  }

  syncResources(): Observable<boolean> {
    return this.netStatus.onlineStatus.pipe(
      take(1),
      flatMap(isOnline => {
        if (isOnline) {
          this.toaster.displayNotice('Sincronizzazione in corso', 999999);
          // Perform the resource request to the server
          return combineLatest([
            this.factoryTypesService.getFactoryTypes(),
            this.assignmentStatusesService.getAssignmentStatuses(),
            this.assignmentFieldsService.getAssignmentFields(),
            this.sectionsService.getSections(),
            this.subsectionsService.getSubsections(),
            this.questionsService.getQuestions(),
            this.sourcesService.getSources(),
            this.targetsService.getTargets(),
            this.scoreMappingsService.getScoreMappings(),
            this.requiredAttachmentsService.getRequiredAttachments()
          ]).pipe(
            // If it succeeds, clear the resources stores
            flatMap(resources => combineLatest([
              this.idbWrapper.clear('factoryTypes'),
              this.idbWrapper.clear('assignmentStatuses'),
              this.idbWrapper.clear('assignmentFields'),
              this.idbWrapper.clear('sections'),
              this.idbWrapper.clear('subsections'),
              this.idbWrapper.clear('questions'),
              this.idbWrapper.clear('sources'),
              this.idbWrapper.clear('targets'),
              this.idbWrapper.clear('scoreMappings'),
              this.idbWrapper.clear('requiredAttachments')
              // Propagate forward the retrieved resource after clearing the stores
            ]).pipe(mapTo(resources))),
            // Perform the insertion of the resources into the stores
            flatMap(([factoryTypes, assignmentStatuses, assignmentFields, sections, subsections, questions, sources, targets, scoreMappings, requiredAttachments]) => combineLatest([
              this.idbWrapper.bulkAdd('factoryTypes', factoryTypes),
              this.idbWrapper.bulkAdd('assignmentStatuses', assignmentStatuses),
              this.idbWrapper.bulkAdd('assignmentFields', assignmentFields),
              this.idbWrapper.bulkAdd('sections', sections),
              this.idbWrapper.bulkAdd('subsections', subsections),
              this.idbWrapper.bulkAdd('questions', questions),
              this.idbWrapper.bulkAdd('sources', sources),
              this.idbWrapper.bulkAdd('targets', targets),
              this.idbWrapper.bulkAdd('scoreMappings', scoreMappings),
              this.idbWrapper.bulkAdd('requiredAttachments', requiredAttachments)
            ])),
            // cleanup indexedDB from assignments that don't belong there
            switchMap(() => combineLatest([
              this.assignmentsService.getAssignments(true),
              this.idbWrapper.all('assignments')
            ])),
            switchMap(([remoteAssignments, localAssignments]) => localAssignments.length ? combineLatest(localAssignments.map(localAssignment => this.checkLocalAssignment(localAssignment, remoteAssignments))) : of(null)),
            // Display a toaster and mark the sync as done
            tap(() => {
              this.toaster.displaySuccess('Sincronizzazione completata', 1500);
              this.syncDone = true;
            }),
            // Return true to the canActivate method
            mapTo(true),
            // In case of error, return false to the canActivate method and display an error toaster
            catchError((error: any) => {
              console.error(error);
              this.toaster.displayError('Errore nella sincronizzazione');
              return of(false);
            })
          );
          // If we're offline, we skip the sync, while not marking it as done
        } else {
          return of(true);
        }
      })
    );
  }

  // This method is pure observable extravaganza, but I'll try to explain it as clearly as I can
  syncAssignment(assignment: Assignment): Observable<any> {
    // Retrieve all the stored answers
    return this.idbWrapper.all('answers').pipe(
      // Filter the answers related to the current assignment
      map((answers: Answer[]) => answers.filter((answer: Answer) => answer.assignmentId === assignment.id)),
      switchMap((answers: Answer[]) => {
        /*
         Merge the emissions (one per each father/children group) into a single stream. Most answers won't have any children.
         Goes from this:
         [ [Q1, C1Q1, C2Q1], [Q2], [Q3], [Q4, C1Q4]]
         to this:
         [ Q1, C1Q1, C2Q1, Q2, Q3, Q4, C1Q4]
          */
        return merge(
          // Spread the array of observables
          ...answers
            // Filter the answers without a parent
            .filter(answer => !answer.parentAnswerId)
            // For each answer, produce the correspondent PUT request observable
            .map((answer: Answer) => this.answersService.createAnswer(assignment.id, answer).pipe(
              // Once the PUT terminates, submit its child answers, if there are any
              switchMap(createdAnswer => {
                /*
                 Merge the emissions (one per observable) into a single stream
                 Goes from this:
                 [ [Father], [Child1], [Child2], [Child3]]
                 to this:
                 [Father, Child1, Child2, Child3]
                  */
                return merge(
                  // Include the parent answer, which we've just submitted, in the stream
                  of(createdAnswer),
                  // Spread the array of observables
                  ...answers
                    // Filter the child answers for the current answer
                    .filter(ans => ans.parentAnswerId === answer.id)
                    /*
                     Perform a PUT request for each child answer, replacing the parentAnswerId with the
                     one we've just obtained from the remote DB
                      */
                    .map(childAnswer => this.answersService.createAnswer(assignment.id, {
                      ...childAnswer,
                      parentAnswerId: createdAnswer.id
                    }))
                );
              })
            ))
          // Here I convert a stream of observable emissions, one per successful PUT request, into a counter
        ).pipe(
          // Start with 0
          startWith(0),
          // Add 1 to the accumulator for each terminated request
          scan((total: number) => total + 1),
          // Convert the number into a percentage
          map((total: number) => (total / answers.length) * 100)
        );
      })
    );
  }

  checkLocalAssignment(localAssignment, remoteAssignments): Observable<any> {
    const index = remoteAssignments.findIndex(remoteAssignment => remoteAssignment.id === localAssignment.id);
    if (index !== -1 && remoteAssignments[index].status === 'inspection') {
      return of(true);
    } else {
      return this.idbWrapper.delete('assignments', localAssignment.id);
    }
  }

  canActivate(): Observable<boolean> {
    if (this.syncDone) {
      return of(true);
    } else {
      return this.syncResources();
    }
  }
}
