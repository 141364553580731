/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fullscreen-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./fullscreen-image.component";
var styles_FullscreenImageComponent = [i0.styles];
var RenderType_FullscreenImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullscreenImageComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { "opacity": 1 }, offset: null }, timings: "150ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_FullscreenImageComponent as RenderType_FullscreenImageComponent };
function View_FullscreenImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_FullscreenImageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [24, "@fade", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image.url; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FullscreenImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "fullscreen-image"]], [[24, "@fade", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "fullscreen-image__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "fullscreen-image__content__image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullscreenImageComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullscreenImageComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "fullscreen-image__content__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "sebastian-back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard_backspace"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.image.url; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.image.url; _ck(_v, 6, 0, currVal_2); var currVal_6 = "keyboard_backspace"; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.image.caption; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 11).inline; var currVal_5 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_FullscreenImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullscreenImageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FullscreenImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fullscreen-image", [], null, [["document", "keyup"]], function (_v, en, $event) { var ad = true; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FullscreenImageComponent_0, RenderType_FullscreenImageComponent)), i1.ɵdid(1, 114688, null, 0, i5.FullscreenImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullscreenImageComponentNgFactory = i1.ɵccf("fullscreen-image", i5.FullscreenImageComponent, View_FullscreenImageComponent_Host_0, { image: "image" }, { close: "close" }, []);
export { FullscreenImageComponentNgFactory as FullscreenImageComponentNgFactory };
