import { SebastianDb } from './sebastian-db';
import { fromPromise } from 'rxjs/internal-compatibility';
import * as i0 from "@angular/core";
var IdbWrapperService = /** @class */ (function () {
    function IdbWrapperService() {
        this.db = new SebastianDb();
    }
    IdbWrapperService.prototype.all = function (store, sortBy) {
        if (sortBy) {
            return fromPromise(this.db[store].toCollection().sortBy(sortBy));
        }
        else {
            return fromPromise(this.db[store].toArray());
        }
    };
    IdbWrapperService.prototype.get = function (store, id) {
        return fromPromise(this.db[store].get(id));
    };
    IdbWrapperService.prototype.add = function (store, item) {
        return fromPromise(this.db[store].add(item));
    };
    IdbWrapperService.prototype.bulkAdd = function (store, items) {
        return fromPromise(this.db[store].bulkAdd(items));
    };
    IdbWrapperService.prototype.update = function (store, id, changes) {
        return fromPromise(this.db[store].update(id, changes));
    };
    IdbWrapperService.prototype.delete = function (store, id) {
        return fromPromise(this.db[store].delete(id));
    };
    IdbWrapperService.prototype.clear = function (store) {
        return fromPromise(this.db[store].clear());
    };
    IdbWrapperService.prototype.where = function (store, condition) {
        return fromPromise(this.db[store].where(condition));
    };
    IdbWrapperService.ngInjectableDef = i0.defineInjectable({ factory: function IdbWrapperService_Factory() { return new IdbWrapperService(); }, token: IdbWrapperService, providedIn: "root" });
    return IdbWrapperService;
}());
export { IdbWrapperService };
