import { NgModule }                        from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { FormsModule }                     from '@angular/forms';
import { RouterModule }                    from '@angular/router';
import { CustomTableModule }               from './custom-table/custom-table.module';
import { NotesModule }                     from './notes/notes.module';
import { FullscreenImageModule }           from './fullscreen-image/fullscreen-image.module';
import { SafeModule }                      from './safe/safe.module';
import { DragDropModule }                  from '@angular/cdk/drag-drop';
import { AttachmentChipModule }            from './attachment-chip/attachment-chip.module';
import { UserAutocompleteFormFieldModule } from './user-autocomplete-form-field/user-autocomplete-form-field.module';
import { LastSavedModule }                 from './last-saved/last-saved.module';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CustomTableModule,
    NotesModule,
    FullscreenImageModule,
    SafeModule,
    DragDropModule,
    AttachmentChipModule,
    UserAutocompleteFormFieldModule,
    LastSavedModule
  ]
})
export class SharedModule {
}
