var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NetStatusService } from './core/net-status/net-status.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from './core/spinner/spinner.service';
import { DialogService } from './core/dialog/dialog.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(renderer, netStatus, matIconRegistry, sanitizer, dialog, spinnerService) {
        this.renderer = renderer;
        this.netStatus = netStatus;
        this.matIconRegistry = matIconRegistry;
        this.sanitizer = sanitizer;
        this.dialog = dialog;
        this.spinnerService = spinnerService;
        this.konamis = 0;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onlineListener = this.renderer.listen('window', 'online', function () { return _this.netStatus.setOnline(); });
        this.offlineListener = this.renderer.listen('window', 'offline', function () { return _this.netStatus.setOffline(); });
        this.matIconRegistry
            .addSvgIcon('offline_bolt', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/offline_bolt.svg'))
            .addSvgIcon('close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/close.svg'))
            .addSvgIcon('more_vert', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/more_vert.svg'))
            .addSvgIcon('search', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/search.svg'))
            .addSvgIcon('arrow_forward', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow_forward.svg'))
            .addSvgIcon('arrow_back', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow_back.svg'))
            .addSvgIcon('lens', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/lens.svg'))
            .addSvgIcon('keyboard_backspace', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/keyboard_backspace.svg'))
            .addSvgIcon('save_alt', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/save_alt.svg'))
            .addSvgIcon('done', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/done.svg'))
            .addSvgIcon('notes', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/notes.svg'))
            .addSvgIcon('cloud_upload', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/cloud_upload.svg'))
            .addSvgIcon('library_books', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/library_books.svg'))
            .addSvgIcon('undo', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/undo.svg'))
            .addSvgIcon('edit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/edit.svg'))
            .addSvgIcon('attach_file', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/attach_file.svg'))
            .addSvgIcon('note_add', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/note_add.svg'))
            .addSvgIcon('picture_as_pdf', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/picture_as_pdf.svg'))
            .addSvgIcon('delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/delete.svg'))
            .addSvgIcon('open_with', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/open_with.svg'))
            .addSvgIcon('expand_less', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/expand_less.svg'))
            .addSvgIcon('memory', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/memory.svg'))
            .addSvgIcon('contact_support', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/contact_support.svg'));
        this.spinnerService.spinnerStatus.subscribe(function (val) {
            setTimeout(function () {
                _this.showSpinner = val.status;
                _this.spinnerMessage = val.message;
            }, 0);
        });
    };
    AppComponent.prototype.onKonami = function () {
        return __awaiter(this, void 0, void 0, function () {
            var confirm_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.konamis += 1;
                        if (!(this.konamis >= 3)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dialog.confirmDialog('U SURE MATE').toPromise()];
                    case 1:
                        confirm_1 = _a.sent();
                        if (!!confirm_1) {
                            document.body.classList.add('bollocks');
                            console.error('AYYYYY');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.onlineListener();
        this.offlineListener();
    };
    return AppComponent;
}());
export { AppComponent };
