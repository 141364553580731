<div class="assignments-list">
  <div class="assignments-list__header">
    <h4 class="assignments-list__header__title">Archivio incarichi</h4>
    <div class="assignments-list__header__input sebastian-input">
      <mat-form-field floatLabel="never">
        <mat-icon svgIcon="search" matPrefix></mat-icon>
        <input matInput
               placeholder=""
               [(ngModel)]="searchFieldValue"
               (ngModelChange)="searchChanged()">
      </mat-form-field>
    </div>
    <button mat-button
            *ngIf="netStatus.isOnline()"
            class="assignments-list__header__button sebastian-button sebastian-button--primary"
            [routerLink]="['/assignments', 'new']">
      Nuovo incarico
    </button>
  </div>
  <div class="assignments-list__filters" *ngIf="(netStatus.onlineStatus | async)">
    <div class="assignments-list__filters__type sebastian-select">
      <mat-form-field floatLabel="never">
        <mat-select placeholder="Tipi"
                    [(ngModel)]="factoryTypeValue"
                    (ngModelChange)="searchChanged()">
          <mat-option [value]="null">Tutti i tipi</mat-option>
          <mat-option *ngFor="let type of factoryTypes" [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="assignments-list__filters__status sebastian-select">
      <mat-form-field floatLabel="never">
        <mat-select placeholder="Stati"
                    [(ngModel)]="assignmentStatusValue"
                    (ngModelChange)="searchChanged()">
          <mat-option [value]="null">Tutti gli stati</mat-option>
          <mat-option *ngFor="let status of assignmentStatuses" [value]="status.id">
            {{status.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="assignments-list__filters__input sebastian-input">
      <user-autocomplete-form-field [placeholder]="'Compilatore'" (changed)="onUserChanged($event, 'compilerIdValue')"></user-autocomplete-form-field>
    </div>
    <div class="assignments-list__filters__input sebastian-input">
      <user-autocomplete-form-field [placeholder]="'Revisore'" (changed)="onUserChanged($event, 'reviserIdValue')"></user-autocomplete-form-field>
    </div>
  </div>
  <div class="assignments-list__table">
    <custom-table *ngIf="!assignmentsLoading"
                  [data]="assignments"
                  [displayedColumns]="columns"
                  [columnHeaders]="columnHeaders"
                  [offline]="!(netStatus.onlineStatus | async)"
                  (download)="toggleOffline($event)"
                  (rowClick)="openAssignment($event)">
    </custom-table>
  </div>
  <div class="assignments-list__loading" *ngIf="assignmentsLoading">
    <div class="spinner"></div>
  </div>
</div>


