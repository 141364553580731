import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { AssignmentsComponent }     from './assignments.component';
import {
  MatButtonModule, MatCheckboxModule, MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule, MatNativeDateModule,
  MatRadioModule,
  MatSelectModule, MatTabsModule, MatTooltipModule
} from '@angular/material';
import { SharedModule }             from '../shared/shared.module';
import { NewAssignmentComponent }   from './new-assignment/new-assignment.component';
import { AssignmentListComponent }  from './assignment-list/assignment-list.component';
import { AssignmentComponent }      from './assignment/assignment.component';
import { AssignmentsRoutingModule } from './assignments.routing.module';
import { AuditComponent }         from './audit/audit.component';
import { QuestionCardComponent }        from './audit/question-card/question-card.component';
import { AuditRefreshService }          from './audit/audit-refresh.service';
import { GroupQuestionComponent }       from './audit/group-question/group-question.component';
import { RiskScoresComponent }          from './risk-scores/risk-scores.component';
import { RiskScoreRowComponent }        from './risk-scores/risk-score-row/risk-score-row.component';
import { AttachmentsComponent }         from './attachments/attachments.component';
import { RequiredAttachmentsComponent } from './required-attachments/required-attachments.component';
import { PicturesComponent }            from './pictures/pictures.component';
import { TextFieldModule }              from '@angular/cdk/text-field';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    AssignmentsRoutingModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    TextFieldModule
  ],
  declarations: [
    AssignmentsComponent,
    NewAssignmentComponent,
    AssignmentListComponent,
    AssignmentComponent,
    AuditComponent,
    QuestionCardComponent,
    GroupQuestionComponent,
    RiskScoresComponent,
    RiskScoreRowComponent,
    AttachmentsComponent,
    RequiredAttachmentsComponent,
    PicturesComponent
  ],
  providers: [
    AuditRefreshService
  ]
})
export class AssignmentsModule {
}
