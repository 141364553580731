<div mat-dialog-title>
  Modifica fotogramma
</div>
<mat-dialog-content>
  <div class="preview-box">
    <div class="img-container">
      <img [src]="data.picture.thumbnail"/>
    </div>
  </div>
  <div class="caption">
    <div class="caption__title">Didascalia</div>
    <textarea class="caption__textarea" rows="3" [(ngModel)]="caption"></textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Annulla</button>
  <button mat-button (click)="onSubmit()" tabindex="1">Conferma</button>
</mat-dialog-actions>

