var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { Assignment } from './assignment';
import { combineLatest, of, throwError } from 'rxjs';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { HttpParams } from '@angular/common/http';
import { IdbWrapperService } from '../../idb-wrapper/idb-wrapper.service';
import { AnswersService } from '../answer/answers.service';
import { RequiredAttachmentService } from '../required-attachment/required-attachment.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
import * as i2 from "../../idb-wrapper/idb-wrapper.service";
import * as i3 from "../answer/answers.service";
import * as i4 from "../required-attachment/required-attachment.service";
var AssignmentsService = /** @class */ (function () {
    function AssignmentsService(http, idbWrapper, answersService, requiredAttachmentsService) {
        this.http = http;
        this.idbWrapper = idbWrapper;
        this.answersService = answersService;
        this.requiredAttachmentsService = requiredAttachmentsService;
    }
    AssignmentsService.prototype.createAssignment = function (assignmentData) {
        var bodyString = this.getBodyString(assignmentData);
        return this.http.post('assignments', bodyString).pipe(map(function (res) { return new Assignment(res); }));
    };
    AssignmentsService.prototype.updateAssignment = function (assignmentData) {
        var bodyString = this.getBodyString(assignmentData);
        return this.http.put("assignments/" + assignmentData.id, bodyString).pipe(map(function (res) { return new Assignment(res); }));
    };
    AssignmentsService.prototype.deleteAssignment = function (assignmentId) {
        var bodyString = { deleted: true };
        return combineLatest([
            this.idbWrapper.delete('assignments', assignmentId),
            this.http.put("assignments/" + assignmentId, bodyString).pipe(map(function (res) { return new Assignment(res); }))
        ]);
    };
    AssignmentsService.prototype.getAssignment = function (isOnline, id) {
        var _this = this;
        if (isOnline) {
            var httpRequest = this.http.get("assignments/" + id).pipe(map(function (res) { return new Assignment(res); }));
            return combineLatest([
                httpRequest,
                this.idbWrapper.get('assignments', id)
            ]).pipe(map(function (_a) {
                var assignment = _a[0], offlineAssignment = _a[1];
                return (__assign({}, assignment, { requiredAttachments: assignment.status === 'inspection' && offlineAssignment ? offlineAssignment.requiredAttachments : [], offline: !!offlineAssignment, assignmentNotes: assignment.status === 'inspection' ? (offlineAssignment ? offlineAssignment.assignmentNotes : assignment.assignmentNotes) : assignment.assignmentNotes }));
            }));
        }
        else {
            return this.idbWrapper.get('assignments', id).pipe(map(function (assignment) { return (__assign({}, assignment, { offline: true })); }), switchMap(function (assignment) {
                if (assignment.status !== 'inspection' && !isOnline) {
                    return _this.idbWrapper.delete('assignments', assignment.id).pipe(function () { return throwError(''); });
                }
                else {
                    return of(assignment);
                }
            }));
        }
    };
    AssignmentsService.prototype.downloadAssignment = function (assignment) {
        var _this = this;
        return this.idbWrapper.add('assignments', assignment).pipe(switchMap(function () { return _this.answersService.getAnswers(assignment.id); }), switchMap(function (answers) {
            if (answers && answers.length > 0) {
                var newAnswers = answers.map(function (answer) {
                    var removed = answer.id, newAnswer = __rest(answer, ["id"]);
                    return newAnswer;
                });
                return _this.idbWrapper.bulkAdd('answers', newAnswers);
            }
            else {
                return of(true);
            }
        }), mapTo(true));
    };
    AssignmentsService.prototype.getAssignments = function (isOnline, query) {
        if (isOnline) {
            var params_1 = new HttpParams();
            var _loop_1 = function (key) {
                if (query.hasOwnProperty(key) && query[key] !== null && query[key] !== undefined) {
                    var paramKey_1 = "q[" + key + "]";
                    if (Array.isArray(query[key])) {
                        paramKey_1 += '[]';
                        query[key].forEach(function (item) { return params_1 = params_1.append(paramKey_1, item); });
                    }
                    else {
                        params_1 = params_1.set(paramKey_1, query[key]);
                    }
                }
            };
            for (var key in query) {
                _loop_1(key);
            }
            var httpRequest = this.http.get('assignments', { params: params_1 }).pipe(map(function (res) { return res.assignments.map(function (assignment) { return new Assignment(assignment); }); }));
            return combineLatest([
                httpRequest,
                this.idbWrapper.all('assignments')
            ]).pipe(map(function (_a) {
                var assignments = _a[0], offlineAssignments = _a[1];
                return assignments.map(function (assignment) { return (__assign({}, assignment, { offline: !!(offlineAssignments.find(function (item) { return item.id === assignment.id; })) })); });
            }));
        }
        else {
            return this.idbWrapper.all('assignments').pipe(map(function (assignments) { return assignments.map(function (assignment) { return (__assign({}, assignment, { offline: true })); }); }));
        }
    };
    AssignmentsService.prototype.getAssignmentReport = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/download_report", {
            responseType: 'blob',
            observe: 'response'
        });
    };
    AssignmentsService.prototype.getPicturesReport = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/pictures_report", {
            responseType: 'blob',
            observe: 'response'
        });
    };
    AssignmentsService.prototype.closeInspection = function (assignmentId, reviserId) {
        var _this = this;
        return this.idbWrapper.get('assignments', assignmentId).pipe(switchMap(function (offlineAssignment) {
            var bodyString = { assignment: { status: 'processing', assignment_notes: offlineAssignment.assignmentNotes } };
            if (reviserId)
                bodyString.assignment['reviser_id'] = reviserId;
            return _this.http.put("assignments/" + assignmentId, bodyString).pipe(map(function (assignment) { return new Assignment(assignment); }), switchMap(function (assignment) {
                if (offlineAssignment.requiredAttachments.length > 0) {
                    return combineLatest(offlineAssignment.requiredAttachments.map(function (requiredAttachment) {
                        return _this.requiredAttachmentsService.createRequiredAttachment(requiredAttachment);
                    }).slice()).pipe(mapTo(assignment));
                }
                return of(assignment);
            }), switchMap(function (assignment) { return _this.idbWrapper.delete('assignments', assignmentId).pipe(mapTo(assignment), catchError(function () { return of(assignment); })); }));
        }));
    };
    AssignmentsService.prototype.setToUnderRevision = function (assignmentId) {
        var _this = this;
        var bodyString = { assignment: { status: 'under_revision' } };
        return this.http.put("assignments/" + assignmentId, bodyString).pipe(map(function (assignment) { return new Assignment(assignment); }), switchMap(function (assignment) { return _this.idbWrapper.delete('assignments', assignmentId).pipe(mapTo(assignment), catchError(function () { return of(assignment); })); }));
    };
    AssignmentsService.prototype.setAssignment = function (value) {
        // const data = this.assignment$.value;
        // // assignments$.offline = true;
        // this.assignment$.next(data);
    };
    AssignmentsService.prototype.finalizeAssignment = function (assignmentId, body, options) {
        return this.http.post("assignments/" + assignmentId + "/finalize", body, options);
    };
    AssignmentsService.prototype.getFinalReport = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/final_report", {
            responseType: 'blob',
            observe: 'response'
        });
    };
    AssignmentsService.prototype.updateUser = function (assignmentId, userId, resource) {
        var _a;
        return this.http.put("assignments/" + assignmentId, (_a = {},
            _a[resource + "_id"] = userId || null,
            _a)).pipe(map(function (res) { return new Assignment(res); }));
    };
    AssignmentsService.prototype.computeRiskScores = function (assignmentId) {
        return this.http.post("assignments/" + assignmentId + "/compute_risk_scores", {});
    };
    AssignmentsService.prototype.getBodyString = function (assignment) {
        return JSON.stringify({
            assignment: {
                accident_number: assignment.accidentNumber,
                agency: assignment.agency,
                agency_code: assignment.agencyCode,
                factory_type_id: assignment.factoryTypeId,
                insured: assignment.insured,
                location: assignment.location,
                policy_number: assignment.policyNumber,
                practice_number: assignment.practiceNumber,
                principal: assignment.principal,
                surveyor: assignment.surveyor,
                assignment_notes: assignment.assignmentNotes,
                compiler_id: assignment.compiler_id
            }
        });
    };
    AssignmentsService.ngInjectableDef = i0.defineInjectable({ factory: function AssignmentsService_Factory() { return new AssignmentsService(i0.inject(i1.ApiHttpClientService), i0.inject(i2.IdbWrapperService), i0.inject(i3.AnswersService), i0.inject(i4.RequiredAttachmentService)); }, token: AssignmentsService, providedIn: "root" });
    return AssignmentsService;
}());
export { AssignmentsService };
