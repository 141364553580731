import { Component, OnInit }   from '@angular/core';
import { UserService } from '../../user/user.service';
import { NetStatusService } from '../../net-status/net-status.service';

@Component({
  selector: 'user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.scss']
})
export class UserBarComponent implements OnInit {

  constructor(public userService: UserService, public netStatus: NetStatusService) { }

  onLogout() {
    this.userService.signOut();
  }

  ngOnInit() {
  }

  usernameInitials() {
    const name: string = this.userService.user && this.userService.user.name;

    if (name) {
      const nameTokens = name.toUpperCase().split(' ');
      return `${nameTokens[0].slice(0, 1)}${nameTokens.length > 1 ? nameTokens[nameTokens.length - 1].slice(0, 1) : ''}`;
    } else {
      return '';
    }
  }

}
