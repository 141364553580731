<div mat-dialog-title>
  {{ data.requiredAttachment ? 'Modifica' : 'Aggiungi' }} allegato
</div>
<mat-dialog-content>
  <input matInput placeholder="Nome documento" name="required-attachment" [(ngModel)]="description">
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Annulla</button>
  <button mat-button [mat-dialog-close]="description" tabindex="1">Conferma</button>
</mat-dialog-actions>

