import { Injectable }      from '@angular/core';
import { SpinnerData }     from './spinner-data';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
  spinnerStatus: BehaviorSubject<SpinnerData> = new BehaviorSubject<SpinnerData>({ status: false, message: null });

  constructor() { }

  toggleSpinner(value: boolean, message: string = null) {
    this.spinnerStatus.next({ status: value, message: message });
  }
}
