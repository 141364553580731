import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { map } from 'rxjs/operators';
import { ScoreMapping } from './score-mapping';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var ScoreMappingsService = /** @class */ (function () {
    function ScoreMappingsService(http) {
        this.http = http;
    }
    ScoreMappingsService.prototype.getScoreMappings = function () {
        return this.http.get('score_mappings').pipe(map(function (res) { return res.score_mappings.map(function (scoreMapping) { return new ScoreMapping(scoreMapping); }); }));
    };
    ScoreMappingsService.ngInjectableDef = i0.defineInjectable({ factory: function ScoreMappingsService_Factory() { return new ScoreMappingsService(i0.inject(i1.ApiHttpClientService)); }, token: ScoreMappingsService, providedIn: "root" });
    return ScoreMappingsService;
}());
export { ScoreMappingsService };
