import { Component, OnInit }   from '@angular/core';
import { Router }              from '@angular/router';
import { UserService }         from '../core/user/user.service';
import { ToasterService } from '../core/toaster/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email;
  password;
  errors = {
    email: '',
    password: ''
  };
  submitted = false;

  constructor(private router: Router, private userService: UserService, private toaster: ToasterService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    if (!this.password) {
      this.errors.password = 'Password obbligatoria';
      this.submitted = false;
    }
    if (!this.email) {
      this.errors.email = 'Email obbligatoria';
      this.submitted = false;
    }
    if (this.email && this.password) {
      this.userService.signIn({login: this.email, password: this.password}).subscribe(
        res => this.router.navigate(['dashboard']),
        error => {
          this.toaster.displayError('Errore nel login.');
          this.submitted = false;
        });
    }
  }

}
