import { of } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NetStatusService } from '../net-status/net-status.service';
import { ApiHttpClientService } from '../api-http-client/api-http-client.service';
import { ToasterService } from '../toaster/toaster.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "angular-token";
import * as i3 from "../api-http-client/api-http-client.service";
import * as i4 from "../net-status/net-status.service";
import * as i5 from "../toaster/toaster.service";
var UserService = /** @class */ (function () {
    function UserService(router, tokenService, http, netStatus, toaster) {
        this.router = router;
        this.tokenService = tokenService;
        this.http = http;
        this.netStatus = netStatus;
        this.toaster = toaster;
        this.tokenValidated = false;
        this.translatedRoles = {
            compiler: 'Compilatore',
            manager: 'Manager',
            clerk: 'Segreteria'
        };
    }
    UserService.prototype.userSignedIn = function () {
        return this.tokenService.userSignedIn();
    };
    Object.defineProperty(UserService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (val) {
            this._user = val;
            if (!!val) {
                localStorage.setItem('userData', JSON.stringify(this.user));
            }
            else {
                localStorage.removeItem('userData');
            }
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.signIn = function (data) {
        var _this = this;
        return this.tokenService.signIn(data).pipe(tap(function (res) {
            _this.tokenValidated = true;
            _this.user = res && res.body && res.body.data || null;
        }));
    };
    UserService.prototype.signOut = function () {
        var _this = this;
        if (this.netStatus.isOnline()) {
            this.user = null;
            this.tokenService.signOut().subscribe(function (_) { return _this.router.navigate(['/login']); });
        }
    };
    UserService.prototype.updatePassword = function (obj) {
        var _this = this;
        var bodyString = JSON.stringify({ user: obj });
        return this.http.put("users/" + this.user.id, bodyString).pipe(tap(function (res) {
            _this.user = res && res['user'] || null;
        }));
    };
    UserService.prototype.getTranslatedRole = function () {
        return this._user ? this.translatedRoles[this._user.role] : '';
    };
    UserService.prototype.canActivate = function () {
        var _this = this;
        var obs;
        if (this.tokenValidated) {
            obs = of(true);
        }
        else {
            if (this.netStatus.isOnline()) {
                obs = this.tokenService.validateToken().pipe(map(function (res) { return res['data']; }), tap(function (user) {
                    _this.user = user;
                    _this.tokenValidated = true;
                }), catchError(function (error) {
                    _this.tokenValidated = true;
                    _this.user = null;
                    return of(null);
                }));
            }
            else {
                obs = of(true);
            }
        }
        return obs.pipe(switchMap(function () {
            if (!_this.userSignedIn()) {
                _this.router.navigate(['login']);
                _this.toaster.dismissAll();
            }
            else {
                _this.user = _this.user || JSON.parse(localStorage.getItem('userData'));
                if (!_this.user) {
                    _this.signOut();
                }
                else {
                    if (!_this.user.first_password_set) {
                        _this.router.navigate(['password-change']);
                    }
                }
            }
            return of(_this.userSignedIn());
        }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.Router), i0.inject(i2.AngularTokenService), i0.inject(i3.ApiHttpClientService), i0.inject(i4.NetStatusService), i0.inject(i5.ToasterService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
