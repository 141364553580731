import { Component, Inject, OnInit }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RequiredAttachment }            from '../../models/required-attachment/required-attachment';

@Component({
  selector: 'required-attachment-dialog',
  templateUrl: './required-attachment-dialog.html',
  styleUrls: ['./required-attachment-dialog.scss']
})
export class RequiredAttachmentDialogComponent implements OnInit {

  description: string;

  constructor(
    public dialogRef: MatDialogRef<RequiredAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {requiredAttachment?: RequiredAttachment}) {}

  ngOnInit() {
    if (this.data.requiredAttachment) {
      this.description = this.data.requiredAttachment.description;
    }
  }

}
