import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable()
export class ChoiceDialogService {

  constructor(private httpService: ApiHttpClientService) { }

  getUsers() {
    return this.httpService.get('users');
  }
}
