<div class="dashboard" *ngIf="!dashboardLoading">
  <div class="dashboard__header">
    <h4 class="dashboard__title">Dashboard incarichi</h4>
    <button mat-button
            *ngIf="netStatus.isOnline()"
            class="dashboard__header__button sebastian-button sebastian-button--primary"
            [routerLink]="['/assignments', 'new']" [queryParams]="{back_page: 'dashboard'}">
      Nuovo incarico
    </button>
  </div>
  <div class="dashboard__inspection">
    <div class="dashboard__inspection__title">Incarichi in sopralluogo</div>
    <custom-table [displayedColumns]="columns"
                  [columnHeaders]="columnHeaders"
                  [data]="dashboardData?.inspection"
                  [offline]="!(netStatus.onlineStatus | async)"
                  (download)="toggleOffline($event)"
                  (rowClick)="openAssignment($event)">
    </custom-table>
  </div>
  <div class="dashboard__processing">
    <div class="dashboard__processing__title">Incarichi in elaborazione</div>
    <custom-table [displayedColumns]="columns"
                  [columnHeaders]="columnHeaders"
                  [data]="dashboardData?.processing"
                  (rowClick)="openAssignment($event)"></custom-table>
  </div>
  <div class="dashboard__under-revision">
    <div class="dashboard__under-revision__title">Incarichi in revisione</div>
    <custom-table [displayedColumns]="columns"
                  [columnHeaders]="columnHeaders"
                  [data]="dashboardData?.underRevision"
                  (rowClick)="openAssignment($event)"></custom-table>
  </div>
</div>
<div class="dashboard__loading" *ngIf="dashboardLoading">
  <div class="spinner"></div>
</div>
