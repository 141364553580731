import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Source }               from './source';

@Injectable({
  providedIn: 'root'
})
export class SourcesService {

  constructor(private http: ApiHttpClientService) { }

  getSources(): Observable<Source[]> {
    return this.http.get('sources').pipe(
      map((res: any) => res.sources.map(source => new Source(source)))
    );
  }
}
