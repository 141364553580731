import { IdbWrapperService } from '../idb-wrapper/idb-wrapper.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToasterService } from '../toaster/toaster.service';
import * as i0 from "@angular/core";
import * as i1 from "../idb-wrapper/idb-wrapper.service";
import * as i2 from "../toaster/toaster.service";
var UtilsService = /** @class */ (function () {
    function UtilsService(idbWrapper, toaster) {
        this.idbWrapper = idbWrapper;
        this.toaster = toaster;
    }
    UtilsService.prototype.matchByRegex = function (text, regex_text) {
        var regex = new RegExp(regex_text.toLowerCase().split(' ').join('.*'));
        return regex.test(text.toLowerCase());
    };
    UtilsService.prototype.getFilteredSources = function (factoryTypeId) {
        return combineLatest(this.getFilteredScoreMappings(factoryTypeId), this.idbWrapper.all('sources')).pipe(map(function (_a) {
            var scoreMappings = _a[0], sources = _a[1];
            return sources.filter(function (source) {
                return !!scoreMappings.find(function (scoreMapping) { return scoreMapping.sourceId === source.id; });
            });
        }));
    };
    UtilsService.prototype.getFilteredScoreMappings = function (factoryTypeId) {
        return combineLatest(this.idbWrapper.all('scoreMappings'), this.idbWrapper.all('factoryTypes')).pipe(map(function (_a) {
            var scoreMappings = _a[0], factoryTypes = _a[1];
            var factoryType = factoryTypes.find(function (ft) { return ft.id === factoryTypeId; });
            return scoreMappings.filter(function (scoreMapping) {
                return !scoreMapping.factoryKindId || scoreMapping.factoryKindId === factoryType.factoryKindId;
            });
        }));
    };
    UtilsService.prototype.getFilteredSectionsTree = function (factoryTypeId, status) {
        var _this = this;
        return combineLatest(this.idbWrapper.all('sections', 'sort'), this.idbWrapper.all('subsections', 'sort'), this.idbWrapper.all('questions', 'sort')).pipe(map(function (_a) {
            var sections = _a[0], subsections = _a[1], questions = _a[2];
            // Filter the sections by factory type id
            var filteredSections = sections.filter(function (section) { return section.factoryTypeIds.includes(factoryTypeId); });
            /*
            Filter the subsections, excluding those that don't belong to the current section and to the assignment factory type,
            as well as those without any question
            */
            var filteredSubsections = subsections
                .filter(function (subsection) {
                return subsection.factoryTypeIds.includes(factoryTypeId) &&
                    filteredSections.map(function (section) { return section.id; }).includes(subsection.sectionId) &&
                    questions
                        .filter(function (question) { return question.factoryTypeIds.includes(factoryTypeId) && !(status === 'inspection' && question.processingOnly); })
                        .map(function (question) { return question.subsectionId; })
                        .includes(subsection.id);
            });
            // Remove the sections without any subsection
            filteredSections = filteredSections.filter(function (section) {
                return filteredSubsections
                    .map(function (subsection) { return subsection.sectionId; })
                    .includes(section.id);
            });
            // If this factoryTypeId has no sections
            if (filteredSections.length === 0) {
                _this.toaster.displayError('Nessuna sezione configurata per questo tipo di stabilimento', 10000);
            }
            return [filteredSections, filteredSubsections];
        }));
    };
    UtilsService.prototype.blobAndFilenameFromRes = function (res) {
        var filenameRegex = new RegExp('filename\\*?=[\'"]?(?:UTF-\\d[\'"]*)?([^;\\r\\n"\']*)[\'"]?;?');
        return {
            filename: filenameRegex.exec(res.headers.get('Content-Disposition'))[1],
            blob: new Blob([res.body], { type: 'application/pdf' })
        };
    };
    UtilsService.prototype.downloadFile = function (blob, filename) {
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        URL.revokeObjectURL(link.href);
    };
    UtilsService.prototype.openFile = function (blob) {
        var url = URL.createObjectURL(blob);
        window.open(url);
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.inject(i1.IdbWrapperService), i0.inject(i2.ToasterService)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
