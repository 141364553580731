export class User {
  id: number;
  name: string;
  email: string;
  role: 'compiler' | 'manager' | 'clerk';

  constructor(obj: any) {
    this.id =                 obj && obj.id                   || null;
    this.name =               obj && obj.name                 || '';
    this.email =              obj && obj.email                || '';
    this.role =               obj && obj.role                 || null;
  }
}
