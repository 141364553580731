import { Injectable }        from '@angular/core';
import { NativeDateAdapter } from '@angular/material';
import { Platform }          from '@angular/cdk/platform';

@Injectable()
export class ItDateAdapter extends NativeDateAdapter {

  constructor() {
    super('it-IT', new Platform());
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
