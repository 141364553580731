import Dexie                from 'dexie';
import { Assignment }       from '../models/assignment/assignment';
import { FactoryType }      from '../models/factory-type/factory-type';
import { AssignmentStatus }   from '../models/assignment-status/assignment-status';
import { Section }            from '../models/section/section';
import { Subsection }         from '../models/subsection/subsection';
import { Question }           from '../models/question/question';
import { Source }             from '../models/source/source';
import { Target }             from '../models/target/target';
import { ScoreMapping }       from '../models/score-mapping/score-mapping';
import { AssignmentField }    from '../models/assignment-field/assignment-field';
import { RequiredAttachment } from '../models/required-attachment/required-attachment';

export class SebastianDb extends Dexie {
  public assignments!: Dexie.Table<Assignment, number>;
  public factoryTypes!: Dexie.Table<FactoryType, number>;
  public assignmentStatuses!: Dexie.Table<AssignmentStatus, number>;
  public assignmentFields!: Dexie.Table<AssignmentField, number>;
  public sections!: Dexie.Table<Section, number>;
  public subsections!: Dexie.Table<Subsection, number>;
  public questions!: Dexie.Table<Question, number>;
  public sources!: Dexie.Table<Source, number>;
  public targets!: Dexie.Table<Target, number>;
  public scoreMappings!: Dexie.Table<ScoreMapping, number>;
  public requiredAttachments!: Dexie.Table<RequiredAttachment, number>;

  public constructor() {
    super('SebastianDb');

    // If you make any change to the DB structure, make sure you create a new version of it
    this.version(3).stores({
      assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes, requiredAttachments',
      subsections: '++id, name, sort, sectionId',
      sections: '++id, name, sort',
      factoryTypes: '++id, name, factoryKindId',
      assignmentStatuses: '++id, description',
      assignmentFields: '++id, name, mnemonicLabel, wildcard',
      questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
      answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
      sources: '++id, name',
      targets: '++id, name',
      scoreMappings: '++id, sourceId, targetId, factoryKindId',
      requiredAttachments: '++id, label, questionId, description'
    });
    this.version(2).stores({
      assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes, requiredAttachments',
      subsections: '++id, name, sort, sectionId',
      sections: '++id, name, sort',
      factoryTypes: '++id, name',
      assignmentStatuses: '++id, description',
      assignmentFields: '++id, name, mnemonicLabel, wildcard',
      questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
      answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
      sources: '++id, name',
      targets: '++id, name',
      scoreMappings: '++id, sourceId, targetId, factoryTypeId',
      requiredAttachments: '++id, label, questionId, description'
    });
    this.version(1).stores({
      assignments: '++id, practiceNumber, policyNumber, accidentNumber, factoryType, factoryTypeId, agency, agencyCode, insured, surveyor, location, status, translatedStatus, assignmentNotes',
      subsections: '++id, name, sort, sectionId',
      sections: '++id, name, sort',
      factoryTypes: '++id, name',
      assignmentStatuses: '++id, description',
      assignmentFields: '++id, name, mnemonicLabel, wildcard',
      questions: '++id, answerOptions, questionTexts, questionType, subsectionId, sort, processingOnly, expectsFreeNotes, placeholder, parentQuestionId, factoryTypeIds, unlockedByIds, autoMapNotes, templateMappingRules',
      answers: '++id, questionId, assignmentId, value, freeNotes, parentAnswerId, answerOptionIds',
      sources: '++id, name',
      targets: '++id, name',
      scoreMappings: '++id, sourceId, targetId, factoryTypeId'
    });

    this.assignments.mapToClass(Assignment);
    this.factoryTypes.mapToClass(FactoryType);
    this.assignmentStatuses.mapToClass(AssignmentStatus);
    this.assignmentFields.mapToClass(AssignmentField);
    this.sections.mapToClass(Section);
    this.subsections.mapToClass(Subsection);
    this.questions.mapToClass(Question);
    this.sources.mapToClass(Source);
    this.targets.mapToClass(Target);
    this.scoreMappings.mapToClass(ScoreMapping);
    this.requiredAttachments.mapToClass(RequiredAttachment);
  }
}
