var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { ToasterService } from '../toaster/toaster.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../toaster/toaster.service";
import * as i3 from "@angular/router";
var ApiHttpClientService = /** @class */ (function () {
    function ApiHttpClientService(http, toaster, router) {
        this.http = http;
        this.toaster = toaster;
        this.router = router;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        });
    }
    ApiHttpClientService.prototype.get = function (url, options) {
        var _this = this;
        return this.http.get(this.composeUrl(url), __assign({ headers: this.headers }, options)).pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    ApiHttpClientService.prototype.post = function (url, body, options) {
        var _this = this;
        return this.http.post(this.composeUrl(url), body, __assign({ headers: this.headers }, options)).pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    ApiHttpClientService.prototype.put = function (url, body, options) {
        var _this = this;
        return this.http.put(this.composeUrl(url), body, __assign({ headers: this.headers }, options)).pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    ApiHttpClientService.prototype.delete = function (url, options) {
        var _this = this;
        return this.http.delete(this.composeUrl(url), __assign({ headers: this.headers }, options)).pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    ApiHttpClientService.prototype.composeUrl = function (url) {
        return environment.apiUrl + "/" + url;
    };
    ApiHttpClientService.prototype.handleError = function (error) {
        console.error(error);
        var errorMessage;
        if (error.status === 403) {
            errorMessage = 'Utente non autorizzato';
            this.toaster.displayError(errorMessage);
            this.router.navigate(['/dashboard']);
        }
        else if (error.status === 401) {
            errorMessage = 'Effettuare il login';
            this.toaster.displayError(errorMessage);
            this.router.navigate(['/login']);
        }
        else if (error.status === 0) {
            errorMessage = 'Connessione ad Internet assente';
            this.toaster.displayError(errorMessage);
        }
        else {
            errorMessage = 'Errore nel server';
            this.toaster.displayError(errorMessage);
        }
        return throwError(errorMessage);
    };
    ApiHttpClientService.ngInjectableDef = i0.defineInjectable({ factory: function ApiHttpClientService_Factory() { return new ApiHttpClientService(i0.inject(i1.HttpClient), i0.inject(i2.ToasterService), i0.inject(i3.Router)); }, token: ApiHttpClientService, providedIn: "root" });
    return ApiHttpClientService;
}());
export { ApiHttpClientService };
