import { NgModule }          from '@angular/core';
import { UserBarComponent }  from './user-bar/user-bar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SideBarComponent }  from './side-bar.component';
import { RouterModule }      from '@angular/router';
import { CommonModule }      from '@angular/common';
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule, MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatTooltipModule
} from '@angular/material';
import { ScrollToModule }    from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSlideToggleModule,
    ScrollToModule.forRoot(),
    MatProgressSpinnerModule
  ],
  declarations: [
    SideBarComponent,
    UserBarComponent,
    SideMenuComponent
  ],
  exports: [SideBarComponent]
})
export class SideBarModule {
}
