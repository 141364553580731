import { DashboardData } from './dashboard-data';
import { map } from 'rxjs/operators';
import { ApiHttpClientService } from '../core/api-http-client/api-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/api-http-client/api-http-client.service";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.dashboardUrl = 'dashboard';
    }
    DashboardService.prototype.getDashboardData = function () {
        return this.http.get(this.dashboardUrl).pipe(map(function (res) { return new DashboardData(res); }));
    };
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.ApiHttpClientService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
