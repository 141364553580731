import { Injectable }           from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Observable }           from 'rxjs';
import { RiskScore }            from './risk-score';
import { map }                  from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RiskScoresService {
  constructor(private http: ApiHttpClientService) { }

  getRiskScores(assignmentId: number): Observable<RiskScore[]> {
    return this.http.get(`assignments/${assignmentId}/risk_scores`).pipe(
      map((res: any) => res.risk_scores.map(riskScore => new RiskScore(riskScore)))
    );
  }

  updateRiskScore(riskScore: RiskScore): Observable<RiskScore> {
    const bodyString = JSON.stringify({
      risk_score: {
        magnitude_score: riskScore.magnitudeScore,
        probability_score: riskScore.probabilityScore,
        notes: riskScore.notes,
      }
    });
    return this.http.put(`risk_scores/${riskScore.id}`, bodyString).pipe(
      map((res: any) => new RiskScore(res))
    );
  }
}
