import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';
import { Answer } from './answer';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../api-http-client/api-http-client.service";
var AnswersService = /** @class */ (function () {
    function AnswersService(http) {
        this.http = http;
    }
    AnswersService.prototype.createAnswerUrl = function (assignmentId) {
        return "assignments/" + assignmentId + "/answers";
    };
    AnswersService.prototype.updateAnswerUrl = function (answerId) {
        return "answers/" + answerId;
    };
    AnswersService.prototype.deleteAnswerUrl = function (answerId) {
        return "answers/" + answerId;
    };
    AnswersService.prototype.getAnswers = function (assignmentId) {
        return this.http.get("assignments/" + assignmentId + "/answers").pipe(map(function (res) { return res.answers.map(function (answer) { return new Answer(answer); }); }));
    };
    AnswersService.prototype.createAnswer = function (assignmentId, answer) {
        var bodyString = JSON.stringify({
            answer: {
                question_id: answer.questionId,
                value: answer.value,
                free_notes: answer.freeNotes,
                parent_answer_id: answer.parentAnswerId
            }
        });
        return this.http.post(this.createAnswerUrl(assignmentId), bodyString).pipe(map(function (res) { return new Answer(res); }));
    };
    AnswersService.prototype.updateAnswer = function (answerId, answer) {
        var bodyString = JSON.stringify({
            answer: {
                value: answer.value,
                free_notes: answer.freeNotes
            }
        });
        return this.http.put(this.updateAnswerUrl(answerId), bodyString).pipe(map(function (res) { return new Answer(res); }));
    };
    AnswersService.prototype.deleteAnswer = function (answerId) {
        return this.http.delete(this.deleteAnswerUrl(answerId)).pipe(map(function (res) { return new Answer(res); }));
    };
    AnswersService.ngInjectableDef = i0.defineInjectable({ factory: function AnswersService_Factory() { return new AnswersService(i0.inject(i1.ApiHttpClientService)); }, token: AnswersService, providedIn: "root" });
    return AnswersService;
}());
export { AnswersService };
