/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./last-saved.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-moment";
import * as i3 from "@angular/common";
import * as i4 from "./last-saved.component";
var styles_LastSavedComponent = [i0.styles];
var RenderType_LastSavedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LastSavedComponent, data: {} });
export { RenderType_LastSavedComponent as RenderType_LastSavedComponent };
function View_LastSavedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Salvataggio in corso..."]))], null, null); }
function View_LastSavedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Salvato ", ""])), i1.ɵppd(2, 2), i1.ɵpid(131072, i2.TimeAgoPipe, [i1.ChangeDetectorRef, i1.NgZone])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.saveState.lastSaved, "it")))); _ck(_v, 1, 0, currVal_0); }); }
export function View_LastSavedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LocalePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LastSavedComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LastSavedComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveState.saving; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.saveState.saving && _co.saveState.lastSaved); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LastSavedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "last-saved", [], null, null, null, View_LastSavedComponent_0, RenderType_LastSavedComponent)), i1.ɵdid(1, 114688, null, 0, i4.LastSavedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LastSavedComponentNgFactory = i1.ɵccf("last-saved", i4.LastSavedComponent, View_LastSavedComponent_Host_0, { saveState: "saveState" }, {}, []);
export { LastSavedComponentNgFactory as LastSavedComponentNgFactory };
