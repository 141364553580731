import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { map }                  from 'rxjs/operators';
import { Section }              from './section';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  constructor(private http: ApiHttpClientService) { }

  getSections(): Observable<Section[]> {
    return this.http.get('sections').pipe(
      map((res: any) => res.sections.map(section => new Section(section)))
    );
  }
}
