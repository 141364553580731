import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../core/user/user.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class PasswordChangeGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean> {
    if (!this.userService.userSignedIn()) {
      this.router.navigate(['login']);
    } else {
      this.userService.user = this.userService.user || JSON.parse(localStorage.getItem('userData'));
      if (!this.userService.user) {
        this.userService.signOut();
      } else {
        if (this.userService.user.first_password_set) {
          this.router.navigate(['dashboard']);
        }
      }
    }

    return of(this.userService.userSignedIn());
  }
}
