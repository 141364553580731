import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FactoryType } from './factory-type';
import { map } from 'rxjs/operators';
import { ApiHttpClientService } from '../../api-http-client/api-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class FactoryTypesService {

  constructor(private http: ApiHttpClientService) { }

  getFactoryTypes(): Observable<FactoryType[]> {
    return this.http.get('factory_types').pipe(
      map((res: any) => res.factory_types.map(factoryType => new FactoryType(factoryType)))
    );
  }
}
