import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditRefreshService {
  private auditRefreshSource$ = new BehaviorSubject(null);
  auditRefresh$ = this.auditRefreshSource$.asObservable();

  constructor() { }

  requestAuditRefresh() {
    this.auditRefreshSource$.next(null);
  }
}
