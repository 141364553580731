<div mat-dialog-title>
  Carica {{text}}
</div>
<mat-dialog-content>
  <div class="upload-box"
       *ngIf="!file"
       [class.upload-box--drag-over]="dragOver"
       (drop)="onDrop($event)"
       (dragenter)="onDragOver(true)"
       (dragleave)="onDragOver(false)">
    <mat-icon svgIcon="note_add"></mat-icon>
    <div>Trascina {{text}} qui</div>
    <div>oppure</div>
    <button mat-button class="sebastian-button sebastian-button--primary" (click)="input.click()">Scegli {{text}}
    </button>
  </div>
  <div class="file-container upload-box" *ngIf="file">
    <ng-container *ngIf="data.resource === 'picture'">
      <div class="img-container">
        <img [src]="imgPreview" *ngIf="imgPreview" [@fade] />
        <div class="spinner" *ngIf="!imgPreview"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.resource === 'pdf'">
      <mat-icon svgIcon="picture_as_pdf"></mat-icon>
    </ng-container>
    <p>
      {{file.name}}
      <button mat-icon-button (click)="onFileDelete()">
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
    </p>
  </div>
  <div class="caption" *ngIf="data.resource === 'picture'">
    <div class="caption__title">Didascalia</div>
    <textarea class="caption__textarea" rows="3" [(ngModel)]="caption"></textarea>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Annulla</button>
  <button mat-button (click)="onSubmit()" tabindex="1">Conferma</button>
</mat-dialog-actions>

<input
  #input
  type="file"
  name="file"
  [accept]="accept"
  class="inputfile"
  (change)="onFileUpload($event.target.files[0])">

