import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScoreMapping }                                   from '../../../core/models/score-mapping/score-mapping';
import { Subject }                                        from 'rxjs';
import { debounceTime }                                   from 'rxjs/operators';

@Component({
  selector: 'risk-score-row',
  templateUrl: './risk-score-row.component.html',
  styleUrls: ['./risk-score-row.component.scss']
})
export class RiskScoreRowComponent implements OnInit {
  @Input() scoreMapping: ScoreMapping;
  @Input() viewMode;
  @Output() scoreMappingChange = new EventEmitter();
  changeSubject = new Subject();

  magnitudeScoreError = false;
  probabilityScoreError = false;

  constructor() { }

  ngOnInit() {
    this.changeSubject
      .pipe(debounceTime(200))
      .subscribe(() => this.scoreMappingChange.emit(this.scoreMapping));
  }

  onChange() {
    this.magnitudeScoreError = this.scoreMapping.riskScore.magnitudeScore < 0 || this.scoreMapping.riskScore.magnitudeScore > 5;
    this.probabilityScoreError = this.scoreMapping.riskScore.probabilityScore < 0 || this.scoreMapping.riskScore.probabilityScore > 5;
    if (!this.magnitudeScoreError && !this.probabilityScoreError) this.changeSubject.next();
  }
}
