import { Attachment } from '../attachment/attachment';
import { Picture }    from '../picture/picture';

export class Answer {
  id: number;
  questionId: number;
  assignmentId: number;
  value: string | number | number[];
  freeNotes: string;
  inspectionValue: string;
  answerOptionIds: number[];
  parentAnswerId: number;
  attachments: Attachment[];
  pictures: Picture[];

  // Fields used by the audit component
  childAnswers: Answer[];

  constructor(obj: any) {
    this.id = obj && obj.id;
    this.questionId = obj && obj.question_id;
    this.assignmentId = obj && obj.assignment_id;
    this.parentAnswerId = obj && obj.parent_answer_id;
    this.value = obj && obj.value;
    this.freeNotes = obj && obj.free_notes;
    this.inspectionValue = obj && obj.inspection_value;
    this.childAnswers = [];
    this.attachments = obj
      && obj.attachments
      && obj.attachments
        .map( attachment => new Attachment(attachment))
        .filter((attachment: Attachment) => !attachment.requiredAttachmentId)
      || [];
    this.pictures = obj && obj.pictures && obj.pictures.map( picture => new Picture(picture)) || [];
  }
}
