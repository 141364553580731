import { Component, OnDestroy, OnInit }                 from '@angular/core';
import { AssignmentsService }                           from '../../core/models/assignment/assignments.service';
import { AssignmentStatus }                             from '../../core/models/assignment-status/assignment-status';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FactoryType }                                  from '../../core/models/factory-type/factory-type';
import { FactoryTypesService }                          from '../../core/models/factory-type/factory-types.service';
import { AssignmentStatusesService }                    from '../../core/models/assignment-status/assignment-statuses.service';
import { Assignment }                   from '../../core/models/assignment/assignment';
import { IdbWrapperService }            from '../../core/idb-wrapper/idb-wrapper.service';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { NetStatusService }             from '../../core/net-status/net-status.service';
import { Router }                       from '@angular/router';
import { SideMenuService }              from '../../core/side-bar/side-menu/side-menu.service';
import { UserService }                  from '../../core/user/user.service';

@Component({
  selector: 'assignment-list',
  templateUrl: './assignment-list.component.html',
  styleUrls: ['./assignment-list.component.scss']
})
export class AssignmentListComponent implements OnInit, OnDestroy {
  assignmentStatuses: AssignmentStatus[];
  factoryTypes: FactoryType[];
  assignments: Assignment[];
  assignmentsLoading;
  columns: string[];
  columnHeaders = {
    'practiceNumber': 'Numero pratica',
    'insured': 'Assicurato',
    'principal': 'Mandante',
    'factoryType': 'Tipo di stabilimento',
    'compilerName': 'Compilatore',
    'reviserName': 'Revisore',
    'updatedAt': 'Ultimo aggiornamento',
    'status': 'Stato',
    'download': 'Download'
  };

  factoryTypeValue: number;
  assignmentStatusValue: number;
  searchFieldValue: string;
  compilerIdValue: number;
  reviserIdValue: number;
  searchChanges = new BehaviorSubject({});

  subscription: Subscription;

  constructor(private assignmentsService: AssignmentsService,
              private assignmentStatusesService: AssignmentStatusesService,
              private factoryTypesService: FactoryTypesService,
              private idbWrapper: IdbWrapperService,
              public netStatus: NetStatusService,
              private router: Router,
              private sideMenuService: SideMenuService,
              public userService: UserService) {
  }

  toggleOffline(assignment: Assignment) {
    if (assignment.offline) {
      this.idbWrapper.delete('assignments', assignment.id).subscribe(
        res => {
          assignment.offline = false;
        }, error => {
          console.error(error.message);
        });
    } else {
      this.assignmentsService.downloadAssignment(assignment).subscribe(
        () => {
          assignment.offline = true;
        }, error => {
          console.error(error.message);
        });
    }
  }

  searchChanged() {
    this.searchChanges.next({
      assignment_field_values_value_cont: this.searchFieldValue,
      factory_type_id_eq: this.factoryTypeValue,
      status_eq: this.assignmentStatusValue,
      compiler_id_eq: this.compilerIdValue,
      reviser_id_eq: this.reviserIdValue
    });
  }

  openAssignment(assignment: Assignment) {
    this.router.navigate(['assignments', assignment.id]);
  }

  onUserChanged(value, field) {
    this[field] = value.id;
    this.searchChanged();
  }

  ngOnInit() {
    const columns = ['practiceNumber', 'insured', 'principal', 'factoryType', 'compilerName', 'reviserName', 'updatedAt', 'status'];
    if (this.userService.user.role !== 'clerk') {
      columns.push('download');
    }
    this.columns = columns;

    this.idbWrapper.all('assignmentStatuses').subscribe((assignmentStatuses: AssignmentStatus[]) => this.assignmentStatuses = [...assignmentStatuses]);
    this.idbWrapper.all('factoryTypes').subscribe((factoryTypes: FactoryType[]) => this.factoryTypes = [...factoryTypes]);

    this.subscription = combineLatest([
      this.netStatus.onlineStatus,
      this.searchChanges.pipe(debounceTime(500))
    ]).pipe(
      tap(() => this.assignmentsLoading = true),
      switchMap(([isOnline, query]: [boolean, any]) => this.assignmentsService.getAssignments(isOnline as boolean, query))
    ).subscribe(assignments => {
      this.assignments = assignments;
      this.assignmentsLoading = false;
    });

    this.sideMenuService.sideMenuState = { state: 'root' };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
