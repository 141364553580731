var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AssignmentsService } from '../../core/models/assignment/assignments.service';
import { RiskScoresService } from '../../core/models/risk-score/risk-scores.service';
import { UtilsService } from '../../core/utils/utils.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { SideMenuService } from '../../core/side-bar/side-menu/side-menu.service';
import { UserService } from '../../core/user/user.service';
import { ToasterService } from '../../core/toaster/toaster.service';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { DialogService } from '../../core/dialog/dialog.service';
var RiskScoresComponent = /** @class */ (function () {
    function RiskScoresComponent(activatedRoute, assignmentsService, riskScoresService, idWrapper, utils, sideMenuService, userService, toaster, spinnerService, dialogService) {
        this.activatedRoute = activatedRoute;
        this.assignmentsService = assignmentsService;
        this.riskScoresService = riskScoresService;
        this.idWrapper = idWrapper;
        this.utils = utils;
        this.sideMenuService = sideMenuService;
        this.userService = userService;
        this.toaster = toaster;
        this.spinnerService = spinnerService;
        this.dialogService = dialogService;
        this.reloadSubject = new BehaviorSubject(null);
        this.savingSubject$ = new BehaviorSubject({});
        this.saving$ = this.savingSubject$.asObservable();
    }
    RiskScoresComponent.prototype.setSaving = function () {
        this.savingSubject$.next({ saving: true });
    };
    RiskScoresComponent.prototype.setSaved = function () {
        this.savingSubject$.next({ lastSaved: new Date() });
    };
    RiskScoresComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id, sourceId;
        this.loading = true;
        this.activatedRouteSubscription = combineLatest(this.idWrapper.all('sources'), this.idWrapper.all('targets')).pipe(tap(function (_a) {
            var sources = _a[0], targets = _a[1];
            _this.sources = sources;
            _this.targets = targets;
        }), switchMap(function () { return _this.activatedRoute.params; }), tap(function (res) {
            id = +res.id;
            if (id !== +res.id) {
                _this.savingSubject$.next({});
            }
            sourceId = +res.sourceId;
        }), switchMap(function () { return _this.reloadSubject.asObservable(); }), tap(function () { return _this.loading = true; }), switchMap(function () { return combineLatest(_this.assignmentsService.getAssignment(true, id), _this.riskScoresService.getRiskScores(id)); }), switchMap(function (_a) {
            var assignment = _a[0], riskScores = _a[1];
            return _this.utils.getFilteredScoreMappings(assignment.factoryTypeId).pipe(map(function (scoreMappings) { return [assignment, riskScores, scoreMappings]; }));
        })).subscribe(function (_a) {
            var assignment = _a[0], riskScores = _a[1], scoreMappings = _a[2];
            _this.sideMenuService.sideMenuState = { state: 'riskScores', assignment: assignment, currentSubRoute: sourceId };
            _this.assignment = assignment;
            _this.scoreMappings = scoreMappings
                .filter(function (scoreMapping) { return scoreMapping.sourceId === sourceId; })
                .map(function (scoreMapping) { return (__assign({}, scoreMapping, { riskScore: riskScores.find(function (riskScore) { return riskScore.scoreMappingId === scoreMapping.id; }), target: _this.targets.find(function (target) { return target.id === scoreMapping.targetId; }) })); });
            _this.currentSource = _this.sources.find(function (source) { return source.id === sourceId; });
            _this.loading = false;
        });
    };
    RiskScoresComponent.prototype.onChange = function (scoreMapping) {
        var _this = this;
        this.setSaving();
        this.riskScoresService.updateRiskScore(scoreMapping.riskScore).subscribe(function () { return _this.setSaved(); });
    };
    RiskScoresComponent.prototype.onNotesChange = function (event) {
        this.assignment.assignmentNotes = event;
        this.setSaved();
    };
    RiskScoresComponent.prototype.onComputeScores = function () {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogService.confirmDialog('Vuoi lanciare il calcolo automatico dei punteggi?').toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.spinnerService.toggleSpinner(true, 'Calcolo punteggi in corso');
                            this.assignmentsService.computeRiskScores(this.assignment.id).subscribe(function () {
                                _this.reloadSubject.next(null);
                                _this.spinnerService.toggleSpinner(false);
                            }, function () {
                                _this.spinnerService.toggleSpinner(false);
                                _this.toaster.displayError('Errore nella generazione dei punteggi');
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RiskScoresComponent.prototype.getViewMode = function () {
        return this.userService.user.role === 'clerk' || this.assignment.status === 'completed';
    };
    RiskScoresComponent.prototype.ngOnDestroy = function () {
        if (this.activatedRouteSubscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    };
    return RiskScoresComponent;
}());
export { RiskScoresComponent };
