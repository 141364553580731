var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '../../core/dialog/dialog.service';
import { SideMenuService } from '../../core/side-bar/side-menu/side-menu.service';
import { AssignmentsService } from '../../core/models/assignment/assignments.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PicturesService } from '../../core/models/picture/pictures.service';
import { IdbWrapperService } from '../../core/idb-wrapper/idb-wrapper.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { UserService } from '../../core/user/user.service';
var PicturesComponent = /** @class */ (function () {
    function PicturesComponent(dialog, activatedRoute, assignmentsService, sideMenuService, picturesService, sanitizer, idbWrapper, userService) {
        this.dialog = dialog;
        this.activatedRoute = activatedRoute;
        this.assignmentsService = assignmentsService;
        this.sideMenuService = sideMenuService;
        this.picturesService = picturesService;
        this.sanitizer = sanitizer;
        this.idbWrapper = idbWrapper;
        this.userService = userService;
        this.animate = false;
    }
    PicturesComponent.prototype.onPictureEdit = function (picture) {
        return __awaiter(this, void 0, void 0, function () {
            var changes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.pictureEditDialog({ picture: picture }).toPromise()];
                    case 1:
                        changes = _a.sent();
                        if (changes) {
                            picture.updating = true;
                            this.picturesService.updatePicture(__assign({}, picture, { caption: changes.caption })).subscribe(function () {
                                picture.caption = changes.caption;
                                picture.updating = false;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PicturesComponent.prototype.onPictureDelete = function (picture) {
        return __awaiter(this, void 0, void 0, function () {
            var confirm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.confirmDialog("Confermi l'eliminazione del fotogramma?").toPromise()];
                    case 1:
                        confirm = _a.sent();
                        if (confirm) {
                            this.picturesService.deletePicture(picture.id).subscribe(function () {
                                _this.pictures.splice(_this.pictures.findIndex(function (pic) { return picture.id === pic.id; }), 1);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PicturesComponent.prototype.onPictureOpen = function (picture) {
        var _this = this;
        this.fullscreenImage = {
            url: null,
            caption: picture.caption
        };
        this.picturesService.getPicture(picture.id).subscribe(function (blob) {
            // The modal might've been closed meanwhile
            if (_this.fullscreenImage) {
                var url = URL.createObjectURL(blob);
                _this.fullscreenImage.url = _this.sanitizer.bypassSecurityTrustResourceUrl(url);
            }
        });
    };
    PicturesComponent.prototype.onPictureClose = function () {
        if (this.fullscreenImage) {
            URL.revokeObjectURL(this.fullscreenImage.url);
        }
        this.fullscreenImage = null;
    };
    PicturesComponent.prototype.onPictureReorder = function (event) {
        if (event.previousIndex === event.currentIndex)
            return;
        var min = Math.min(event.previousIndex, event.currentIndex), max = Math.max(event.previousIndex, event.currentIndex);
        moveItemInArray(this.pictures, event.previousIndex, event.currentIndex);
        var obs = [];
        for (var i = min; i <= max; i++) {
            obs.push(this.picturesService.updatePicture(__assign({}, this.pictures[i], { sort: i + 1 })));
        }
        combineLatest(obs).subscribe();
    };
    PicturesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id;
        this.loading = true;
        this.activatedRouteSubscription = this.activatedRoute.params.pipe(switchMap(function () { return _this.activatedRoute.params; }), tap(function (res) { return id = +res.id; }), switchMap(function () { return combineLatest(_this.assignmentsService.getAssignment(true, id), _this.picturesService.getPictures(id), _this.idbWrapper.all('questions')); })).subscribe(function (_a) {
            var assignment = _a[0], pictures = _a[1], questions = _a[2];
            _this.sideMenuService.sideMenuState = { state: 'attachments', assignment: assignment, currentSubRoute: 'pictures' };
            pictures.forEach(function (picture) {
                var question = questions.find(function (q) { return q.id === picture.questionId; });
                if (question) {
                    picture.questionText = (question.questionTexts.find(function (text) { return text.factoryTypeId === assignment.factoryTypeId; }) ||
                        question.questionTexts.find(function (text) { return text.factoryTypeId === null; })).text;
                }
            });
            _this.pictures = pictures;
            _this.loading = false;
            setTimeout(function () { return _this.animate = true; });
        });
    };
    PicturesComponent.prototype.ngOnDestroy = function () {
        if (this.activatedRouteSubscription)
            this.activatedRouteSubscription.unsubscribe();
    };
    return PicturesComponent;
}());
export { PicturesComponent };
